import { CalendarOptions, GoogleCalendar } from 'datebook';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { v4 as uuidV4 } from 'uuid';
import LogoButton from '../../components/Assets/LogoButton';
import CalendarButton from '../../components/calendarButton';

import { ReactComponent as DeleteGrey } from '../../images/garbage.svg';
import { dateFormat } from '../../utils/jsFunctions';
import { procedureEventsActions } from './actions';

interface IAlertTableRowProps {
  procedureEvent: ProcedureEvent;
}
const AlertTableRow = ({ procedureEvent }: IAlertTableRowProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = () => {
    history.push(
      `/creation-annonce-dossier/${procedureEvent.adStudyFolderId}/${procedureEvent.adStudyId}`
    );
  };

  const handleCalendarClick = () => {
    const config: CalendarOptions = {
      title: `${procedureEvent.type.text} - ${procedureEvent.contact}`,
      description: procedureEvent.comment,
      start: new Date(procedureEvent.date),
      end: new Date(procedureEvent.date),
    };

    const googleCalendar = new GoogleCalendar(config);

    window.open(googleCalendar.render(), '__blank,noreferrer, noopener');
  };

  const handleDelete = () => {
    dispatch(
      procedureEventsActions.deleteProcedureEvents.request(procedureEvent.idIri)
    );
  };

  return (
    <tr key={uuidV4()}>
      <td onClick={handleClick}>
        <div className="alerts-table-content">
          <LogoButton
            btnType="button"
            handleClick={() => handleClick()}
            btnLogo={procedureEvent.type.logo ?? ''}
            tooltip={''}
            disabled
          />
          <div className="alerts-table-content-wrapper">
            <span>{procedureEvent.owner.firstName}</span>
            <span className="alerts-table-mobile-date">{procedureEvent.date}</span>
          </div>
        </div>
      </td>
      <td className="alerts-table-desktop" onClick={() => handleClick()}>
        {dateFormat(procedureEvent.date)}
      </td>
      <td onClick={() => handleClick()}>{procedureEvent.contact}</td>
      <td className="alerts-table-desktop" onClick={() => handleClick()}>
        {procedureEvent.comment}
      </td>
      <td className="alerts-table-btns">
        <CalendarButton procedureEvent={procedureEvent} />

        <div className="alerts-table-btns-garbage" onClick={handleDelete}>
          <DeleteGrey />
        </div>
      </td>
    </tr>
  );
};

export default AlertTableRow;
