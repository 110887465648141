import { memo } from 'react';
import ActionNotification from '../components/Assets/ActionNotification';
import SectionTitle from '../components/Assets/sectionTitle';
import AdsInfos from '../components/Dashboard/AdsInfos';

import Announcements from '../components/Dashboard/Announcements';
import FolderBloc from '../components/Dashboard/FolderBloc';
import ActiveResearchesDashboardBloc from '../features/activeResearch/components/ActiveResearchesDashboardBloc';

import AlertsTable from '../features/procedureEvent';
import './styles/dashboard.scss';

const DashboardPage = () => {
  return (
    <main className="dashboard">
      <Announcements />

      <ActionNotification />

      <div className="dashboard-wrapper">
        <section className="dashboard-section">
          <SectionTitle
            mainTitle="Alertes & informations"
            subTitle="Vos derniers évènements et RDV..."
          />
          <AlertsTable />
        </section>

        <section className="dashboard-section">
          <SectionTitle
            mainTitle="Flux Pige Classique"
            subTitle="En cours de suivi et d'analyse..."
            noBar={true}
          />
          <AdsInfos />
        </section>
      </div>

      <ActiveResearchesDashboardBloc />

      <FolderBloc />
    </main>
  );
};

export default memo(DashboardPage);
