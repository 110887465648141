import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useAuth from '../App/hooks/useAuth';
import useFolders from '../App/hooks/useFolders';
import ActionNotification from '../components/Assets/ActionNotification';
import Button from '../components/Assets/Button';
import SectionTitle from '../components/Assets/sectionTitle';

import { adActions } from '../features/ad/adSlice';
import AdCardContainer from '../features/ad/components/adCardContainer/AdCardContainer';
import useAds from '../features/ad/useAds';
import AdStudyActionSelect from '../features/adStudy/components/AdStudyActionSelect';
import { ReactComponent as PictoPerson } from '../images/picto-person.svg';
import { foldersActions } from '../store/folders/actions';
import { modalsActions } from '../store/modals/actions';
import './styles/myFoldersContentPage.scss';

const collaboratorsDisplay = (collaborators: any[]) => {
  if (isEmpty(collaborators) || !collaborators) {
    return 'Aucuns';
  }
  return collaborators
    .map((elt: any) => `${elt.firstName} ${elt.lastName}`)
    .join(', ');
};

function MyFolderContentPage() {
  const { folderId }: { folderId: string } = useParams();
  const {
    ads,
    selectedAds,
    addItemToSelectedAd,
    loadActiveAd,
    adPagination,
    handlePaginationChange,
  } = useAds({});

  const { selectedFolder }: { selectedFolder: any } = useFolders(folderId);

  const { userIri } = useAuth();

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(foldersActions.setActiveFolder.reset());
      dispatch(adActions.reset());
    };
  }, []);

  return (
    <>
      {selectedFolder && (
        <main className="my-folders-details">
          <ActionNotification />
          <header className="my-folders-details-header">
            <SectionTitle
              mainTitle={`Dossier ${selectedFolder.name}`}
              subTitle="Dossiers de prospection"
              subTitleNav={`Gestion du dossier ${selectedFolder.name}`}
              link="/mes-dossiers"
            />
            <div className="my-folders-details-right-header">
              <PictoPerson />
              <p>
                {selectedFolder.collaborators &&
                  collaboratorsDisplay(selectedFolder.collaborators)}
              </p>
              {selectedFolder.owner.idIri === userIri && (
                <Button
                  btnType="button"
                  btnContent="MODIFIER LE DOSSIER"
                  handleClick={() => dispatch(modalsActions.modifyFolders.open())}
                  primary
                />
              )}
            </div>
          </header>

          <AdCardContainer
            ads={ads}
            type="adStudies"
            onPaginationChange={handlePaginationChange}
            onCheckAd={addItemToSelectedAd}
            onCardClick={loadActiveAd}
            checkedIds={selectedAds.map((m) => m.idIri)}
            adsPagination={adPagination}
            extendedDisplayParams={
              <AdStudyActionSelect count={selectedAds.length} />
            }
          />
        </main>
      )}
    </>
  );
}

export default MyFolderContentPage;
