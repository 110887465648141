import PropTypes from 'prop-types';
import useAdStudies from '../../App/hooks/useAdStudies';
import Button from '../Assets/Button';

const GlobalSaveButton = ({ handleSubmit }) => {
  const { formTouchStates } = useAdStudies({});

  const handleSubmitIntern = () => {
    handleSubmit();
  };

  return (
    <div className="my-folders-ad-creation-group-btn">
      <Button
        btnType="button"
        btnContent="ENREGISTRER"
        disabled={!Object.values(formTouchStates).includes(true)}
        handleClick={handleSubmitIntern}
        primary
      />
    </div>
  );
};

GlobalSaveButton.propTypes = {
  handleSubmit: PropTypes.func,
  disabled: PropTypes.bool,
};
export default GlobalSaveButton;
