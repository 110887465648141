import { isEmpty } from 'lodash-es';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../App/redux/store';
import { getProcedureEventsState } from '../../features/procedureEvent/reducer';
import { ReactComponent as Bell } from '../../images/bell.svg';
import { modalsActions } from '../../store/modals/actions';

const NotificationBell = () => {
  const { dashboardProcedureEvents } = useAppSelector(getProcedureEventsState);
  const dispatch = useDispatch();

  return (
    <div
      className="header-notif"
      onClick={() => dispatch(modalsActions.notificationBell.open())}
    >
      <Bell />
      {!isEmpty(dashboardProcedureEvents) && <div className="header-notif-on"></div>}
    </div>
  );
};

export default NotificationBell;
