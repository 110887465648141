import getIdFromIdIri from '../../shared/parsers/getIdFromIdIri';
import { changeApiKeyForStoreKey } from '../common/parserCommon';

const authUserMapping = {
  '@id': 'idIri',
  created_at: 'createdAt',
  deleted_at: 'deletedAt',
  email: 'email',
  enabled: 'enabled',
  first_name: 'firstName',
  full_name: 'fullName',
  has_password: 'hasPassword',
  last_name: 'lastName',
  phone_number: 'phoneNumber',
  picture_path: 'picturePath',
  roles: 'roles',
  second_phone_number: 'secondPhoneNumber',
  updated_at: 'updatedAt',
  username: 'username',
  enable_newsletter: 'enableNewsLetter',
};

export const companyMapping = {
  '@id': 'idIri',
  address: 'address',
  city: 'city',
  country: 'country',
  name: 'name',
  siret: 'siret',
  subscription: 'subscrption',
  users: 'users',
};

export const authUserApiToStore = (data: any) => {
  try {
    const mr = data.market_research;
    const c = data.company;

    const parsedData = changeApiKeyForStoreKey<UserDetail>(data, authUserMapping);
    parsedData.id = getIdFromIdIri(data['@id']);

    parsedData.fullNameWithInitial = `${
      parsedData.firstName
    } ${parsedData.lastName.charAt(0).toUpperCase()}`;
    parsedData.initials = `${parsedData.firstName.charAt(
      0
    )}${parsedData.lastName.charAt(0)}`.toUpperCase();

    parsedData.company = {
      idIri: c['@id'],
      id: getIdFromIdIri(c['@id']),
      name: c.name,
      subscriptionIdIri: c.subscription,
    };

    const marketResearch = {
      idIri: mr['@id'],
      totalAds: mr.total_ads ?? 0,
      totalNewAds: mr.total_ads_today ?? 0,
    };
    parsedData.marketResearchIdIri = mr['@id'];

    return { user: parsedData, marketResearch };
  } catch (error) {
    throw new Error('user parser error');
  }
};
