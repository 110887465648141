import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fieldAreaDisplay,
  statTypeFieldValues,
} from '../../components/MarketStudyResult/constant';
import { GoodTypeEnum } from '../../pages/MarketStudiesResultPage';
import { marketStudiesActions } from '../../store/marketStudies/actions';
import { getMarketStudiesState } from '../../store/marketStudies/reducer';
import { marketStudyCommentActions } from '../../store/marketStudyComment/actions';
import { SourceTypeEnum } from '../contexts/marketStudyContext';

const statMultiGraphCalculs = (type: string, valuesTab: string[][]) => {
  const calculatedTab = [];
  for (let i = 1; i <= 12; i += 1) {
    if (valuesTab.length === 2) {
      const isZero =
        parseInt(valuesTab[0][i], 10) === 0 || parseInt(valuesTab[1][i], 10) === 0;
      const tempVal = parseInt(valuesTab[0][i], 10) + parseInt(valuesTab[1][i]);
      calculatedTab.push(
        type === 'prices' ? (isZero ? tempVal : Math.floor(tempVal / 2)) : tempVal
      );
    } else {
      calculatedTab.push(parseInt(valuesTab[0][i], 10));
    }
  }

  const finalTab = [];
  for (let i = 0; i < 12 - new Date().getMonth(); i += 1) {
    finalTab.push(calculatedTab.pop());
  }

  return finalTab.reverse().concat(calculatedTab);
};

const useMarketStudies = (marketStudyId: string | null, resultId: string) => {
  const [selectedMarketStudy, setSelectedMarketStudy] = useState<any>();

  const {
    marketStudies,
    marketStudyResult,
    marketStudyActive,
    deleteModal,
    pdfDatas,
  } = useSelector(getMarketStudiesState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(marketStudies)) {
      dispatch(marketStudiesActions.fetchMarketStudies.request());
    }

    if (isEmpty(marketStudyResult) && resultId) {
      dispatch(marketStudiesActions.fetchMarketStudyResult.request(resultId));
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(marketStudies) && marketStudyId) {
      const temp = marketStudies.find((elmt: any) => elmt.id === marketStudyId);

      setSelectedMarketStudy(temp);
      dispatch(
        marketStudyCommentActions.marketStudyCommentInit.set({
          idIri: temp.idIri,
          comment: temp.comment,
        })
      );
    }
  }, [marketStudies, marketStudyId]);

  const statsPerQueries = (room: any, type: any, state: any) => {
    if (isEmpty(marketStudyResult)) {
      return [];
    } else {
      if (room) {
        return marketStudyResult.find(
          (e: any) =>
            e.totalRoom === room &&
            e.propertyCategory === type &&
            e.statCategory === state
        );
      } else {
        return marketStudyResult.find(
          (e: any) => e.propertyCategory === type && e.statCategory === state
        );
      }
    }
  };

  const statsMultigraph = (type: any) => {
    if (isEmpty(marketStudyResult)) {
      return [];
    } else {
      const baseData = marketStudyResult.filter(
        (e: any) => e.statCategory === 'sale'
      );
      const stats = [];

      if (baseData[0].totalRoom) {
        for (let i = 1; i <= 6; i += 1) {
          const value = baseData
            .filter((e: any) => e.totalRoom === i)
            .map((e: any) => e[type].variations);

          stats.push({
            label: `${
              selectedMarketStudy?.propertyTypologies
                ? selectedMarketStudy?.propertyTypologies.name
                : ''
            } F${i}`,
            data: statMultiGraphCalculs(type, value),
          });
        }
      } else {
        statTypeFieldValues.forEach((pc) => {
          const value = baseData
            .filter((f: any) => f.propertyCategory === pc)
            .map((m: any) => m[type].variations);

          stats.push({
            label: `${
              selectedMarketStudy?.propertyTypologies
                ? selectedMarketStudy?.propertyTypologies.name
                : ''
            } ${fieldAreaDisplay(pc)}`,
            data: statMultiGraphCalculs(type, value),
          });
        });
      }

      return stats;
    }
  };

  const statsForAvgGraph = (
    type: GoodTypeEnum,
    roomGround: string,
    dataType: SourceTypeEnum
  ) => {
    if (isEmpty(marketStudyResult)) {
      return [];
    }

    if (type === GoodTypeEnum.TERRAIN) {
      const temp = marketStudyResult.filter(
        (e: any) => e.propertyCategory === roomGround
      );

      const sale = temp.find((e: any) => e.statCategory === 'sale')?.prices;
      const sold = temp.find((e: any) => e.statCategory === 'sold')?.prices;

      const returnedData =
        dataType === SourceTypeEnum.AVERAGE
          ? [sale?.avgPrice, sold?.avgPrice]
          : [sale?.sqmAvgPrice, sold?.sqmAvgPrice];

      return returnedData;
    } else if (type === GoodTypeEnum.CONSTRUCTION) {
      const parsedRoom = parseInt(roomGround, 10);
      const temp = marketStudyResult.filter((e: any) => e.totalRoom === parsedRoom);

      let saleOld, saleNew, soldOld, soldNew;

      try {
        saleOld = temp.find(
          (e: any) =>
            e.statCategory === 'sale' && e.propertyCategory === 'old_property'
        ).prices;
        saleNew = temp.find(
          (e: any) =>
            e.statCategory === 'sale' && e.propertyCategory === 'new_property'
        ).prices;
      } catch (error) {
        saleOld = { avgPrice: 0, sqmAvgPrice: 0 };
        saleNew = { avgPrice: 0, sqmAvgPrice: 0 };
      }

      try {
        soldOld = temp.find(
          (e: any) =>
            e.statCategory === 'sold' && e.propertyCategory === 'old_property'
        ).prices;
        soldNew = temp.find(
          (e: any) =>
            e.statCategory === 'sold' && e.propertyCategory === 'new_property'
        ).prices;
      } catch (error) {
        soldOld = { avgPrice: 0, sqmAvgPrice: 0 };
        soldNew = { avgPrice: 0, sqmAvgPrice: 0 };
      }

      const returnedData =
        dataType === SourceTypeEnum.AVERAGE
          ? [saleOld.avgPrice, saleNew.avgPrice, soldOld.avgPrice, soldNew.avgPrice]
          : [
              saleOld.sqmAvgPrice,
              saleNew.sqmAvgPrice,
              soldOld.sqmAvgPrice,
              soldNew.sqmAvgPrice,
            ];

      return returnedData;
    }
  };

  return {
    marketStudies,
    marketStudyResult,
    marketStudyActive,
    selectedMarketStudy,
    deleteModal,
    oldGoodsForSale: !isEmpty(marketStudyResult)
      ? marketStudyResult.filter(
          (m: any) =>
            m.statCategory === 'sale' && m.propertyCategory === 'old_property'
        )
      : [],
    newGoodsForSale: !isEmpty(marketStudyResult)
      ? marketStudyResult.filter(
          (m: any) =>
            m.statCategory === 'sale' && m.propertyCategory === 'new_property'
        )
      : [],
    statsPerQueries,
    statsForAvgGraph,
    statsMultigraph,
    pdfDatas,
  };
};

export default useMarketStudies;
