import Chart from 'chart.js';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef } from 'react';

import { MarketStudyResultContext } from '../../pages/MarketStudiesResultPage';
import { spaceNumberFormat } from '../../utils/jsFunctions';
import { useViewport } from '../../utils/viewport';
import './Graph.scss';

const colorPrimary = '#5c2670';

function BarGraph({ labels, datasets, valueType }) {
  const { addSrcToContext } = useContext(MarketStudyResultContext);

  const { mobileView } = useViewport();
  const chartRef = useRef(null);

  useEffect(() => {
    const myChartRef = chartRef.current.getContext('2d');

    let graph = new Chart(myChartRef, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{ ...datasets, barThickness: 12 }],
      },
      options: {
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                padding: 10,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                padding: 10,
                maxTicksLimit: 5,
                min: 0,
                callback: function (value) {
                  return spaceNumberFormat({ value, withEuro: true });
                },
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        elements: {
          rectangle: {
            backgroundColor: colorPrimary,
          },
        },
        animation: false,
      },
    });

    addSrcToContext(graph.toBase64Image(), valueType);
  }, [mobileView]);

  return (
    <div className="graph-container">
      <canvas id="myChart" ref={chartRef} />
    </div>
  );
}

BarGraph.propTypes = {
  type: PropTypes.number,
  valueType: PropTypes.number.isRequired,
  labels: PropTypes.array.isRequired,
  datasets: PropTypes.object.isRequired,
};

export default BarGraph;
