import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../App/redux/store';
import { fetchDatas } from '../../../../services/axiosInstanceService';
import { modalsActions } from '../../../../store/modals/actions';
import { pieceworkActions } from '../../../piecework/pieceworkSlice';
import { adsApiToStoreParser } from '../../adParser';
import { PageType } from '../../useAds';

const fetchAdsThunk = createAsyncThunk(
  'ad/fetchAdsThunk',
  async (
    params: {
      itemsPerPage: number;
      page: number;
      sort: string;
      pageType: PageType | null;
      totalAds: number;
      folderId?: string | null;
    },
    { dispatch, getState }
  ) => {
    try {
      const { sort, itemsPerPage, page, pageType, folderId } = params;
      // if no calling page, can't fetch ads
      if (!pageType) throw new Error('no page type found');

      // state datas
      const state = getState() as RootState;
      const marketSearchIdIri = state.piecework.marketResearch.idIri;
      const { currentActiveResearch } = state.activeResearch;

      // variables
      let url: string | null = null;
      const requestParams = {
        page: page,
        itemsPerPage,
        [`orderBy[${sort}]`]: sort === 'createdAt' ? 'desc' : 'asc',
      };

      // construct url from incoming page name
      switch (pageType) {
        case 'pige-classique':
          if (marketSearchIdIri) {
            url = `${marketSearchIdIri}/ads`;
          }
          break;
        case 'recherche-active-resultat':
          if (currentActiveResearch.result) {
            url = `${currentActiveResearch.result.idIri}/ads`;
          }
          break;
        case 'details-dossier':
        default:
          if (folderId) {
            url = `${folderId}/ad-studies`;
          }
          break;
      }
      // if no url constructed, can't fetch ads
      if (!url) throw new Error('no url provided for request');

      // request ads with constructed url
      const result = await fetchDatas(url, requestParams);
      const totalAds = result['hydra:totalItems'] ?? result['hydra:member'] ?? 0;
      // parse datas
      const parsedAds = adsApiToStoreParser(
        result['hydra:member'],
        Boolean(folderId)
      );

      if (pageType === 'pige-classique') {
        dispatch(
          pieceworkActions.setTotalAds({
            totalAds: totalAds,
            totalNewAds: result['hydra:totalNewItems'] ?? 0,
          })
        );
      }

      // return to store
      return { ads: parsedAds, pagination: { ...params, totalAds } };
    } catch (error: any) {
      if (error?.status === 400) {
        if (error?.data?.detail === 'MARKET_RESEARCH_NOT_PROPERLY_CONFIGURED') {
          dispatch(modalsActions.errorPiecework.open());
          return Promise.reject('market research no roperly configured');
        }
      }
      return Promise.reject(error.message ?? { message: 'ads load error' });
    }
  }
);

export default fetchAdsThunk;
