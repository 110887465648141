import { useAppDispatch } from '../../../App/redux/store';
import Button from '../../../components/Assets/Button';
import fetchActiveResearchesThunk from '../services/thunks/fetchActiveResearchesThunk';

function RefreshActiveResearchesButton() {
  const dispatch = useAppDispatch();

  const handleRefreshActiveResearches = () => {
    dispatch(fetchActiveResearchesThunk());
  };

  return (
    <Button
      btnType="button"
      btnContent="RAFRAICHIR"
      handleClick={handleRefreshActiveResearches}
      primary
    />
  );
}

export default RefreshActiveResearchesButton;
