import { createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { RootState } from '../../../../App/redux/store';
import { appManagerActions } from '../../../../store/appManager/actions';
import { modalsActions } from '../../../../store/modals/actions';
import { adsNotifications } from '../../notifications';
import { PageType } from '../../useAds';
import deleteAd from '../deleteAd';
import fetchAdsThunk from './fetchAdsThunk';

const deleteAdsThunk = createAsyncThunk(
  'ad/deleteAdsThunk',
  async (
    params: { ids: string[]; pageOrigin: PageType | null },
    { dispatch, getState }
  ) => {
    try {
      const { ids, pageOrigin } = params;
      const state = getState() as RootState;
      const ad = state.ad;

      // if no ids array => error
      if (!ids) throw new Error('No array id found');

      // if no ids, no delete and no page reload
      if (isEmpty(ids)) return true;

      // delet ads requests
      await Promise.all(params.ids.map((m) => deleteAd(m)));

      dispatch(modalsActions.deleteAds.close());

      dispatch(
        fetchAdsThunk({
          ...ad.pagination,
          pageType: pageOrigin,
        })
      );
      dispatch(
        appManagerActions.actionNotificationDisplay.visible(
          adsNotifications.deleteAds.success()
        )
      );
    } catch (error) {
      dispatch(
        appManagerActions.actionNotificationDisplay.visible(
          adsNotifications.deleteAds.failure()
        )
      );
      return Promise.reject(error);
    }
  }
);

export default deleteAdsThunk;
