import { isEmpty } from 'lodash';

import IndividualCircularLoader from '../../../../components/Loader/IndividualCircularLoader';
import { dateFormat } from '../../../../utils/jsFunctions';
import { sellerTypes } from '../../constant';
import SummaryLine from './SummaryLine';

interface IActiveResearchResumeProps {
  currentActiveResearch: ActiveResearchDetail | null;
}
const ActiveResearchResume = ({
  currentActiveResearch,
}: IActiveResearchResumeProps) => {
  if (!currentActiveResearch) return null;

  const displaysellerTypes = (st: number[]) => {
    return st
      ? st
          .map((e: number) => sellerTypes.find((f) => f.value === e)?.name ?? '')
          .join(' ,')
      : '';
  };

  const displayCities = (cities: Cities) => {
    return cities.map((m) => m.name).join(', ');
  };

  const propTypos = currentActiveResearch.propertyTypologies
    .map((m) => m.name)
    .join(', ');

  return (
    <section className="active-search-result-search">
      {!currentActiveResearch ? (
        <IndividualCircularLoader size={100} />
      ) : (
        <>
          <div>
            <SummaryLine
              label="Nom"
              value={currentActiveResearch.name}
              isFontWeight
            />
            <SummaryLine
              label="Ville(s)"
              value={displayCities(currentActiveResearch.cities)}
            />
            <SummaryLine
              label="Rayon de recherche"
              value={
                currentActiveResearch.radius
                  ? `${currentActiveResearch.radius} kms`
                  : 'Aucun'
              }
            />
            <SummaryLine
              label="Publié après le"
              value={
                currentActiveResearch.limitFromDate
                  ? dateFormat(currentActiveResearch.limitFromDate)
                  : 'Non défini(e)'
              }
            />
            <SummaryLine
              label="Profils annonceurs"
              value={displaysellerTypes(currentActiveResearch.sellerTypes)}
            />{' '}
            <SummaryLine
              label="Uniquement les bons coups"
              value={currentActiveResearch.isGoodDealsOnly ? 'Oui' : 'Non'}
            />
          </div>

          <div>
            <SummaryLine label="Typologie(s) des biens" value={propTypos} />
            <SummaryLine
              label="Prix minimum"
              value={currentActiveResearch.price.min}
              unit="€"
            />
            <SummaryLine
              label="Prix maximum"
              value={currentActiveResearch.price.max}
              unit="€"
            />{' '}
            <SummaryLine
              label="Mot(s) clé(s) inclus(s)"
              value={
                isEmpty(currentActiveResearch.includedKeywords)
                  ? 'aucuns'
                  : currentActiveResearch.includedKeywords.join(', ')
              }
            />
            <SummaryLine
              label="Mot(s) clé(s) exclus(s)"
              value={
                isEmpty(currentActiveResearch.excludedKeywords)
                  ? 'aucuns'
                  : currentActiveResearch.excludedKeywords.join(', ')
              }
            />
          </div>

          <div>
            <SummaryLine
              label="Surface du bien minimum"
              value={currentActiveResearch.builtArea.min}
              unit="m²"
            />
            <SummaryLine
              label="Surface du bien maximum"
              value={currentActiveResearch.builtArea.max}
              unit="m²"
            />
            <SummaryLine
              label="Surface du terrain minimum"
              value={currentActiveResearch.landArea.min}
              unit="m²"
            />
            <SummaryLine
              label="Surface du terrain maximum"
              value={currentActiveResearch.landArea.max}
              unit="m²"
            />
          </div>
        </>
      )}
    </section>
  );
};

export default ActiveResearchResume;
