export const sortValues = [
  { value: 'createdAt', text: 'Date' },
  { value: 'price', text: 'Prix' },
  { value: 'sqmPrice', text: 'Prix au m²' },
  { value: 'sellerTypes', text: 'Profil vendeur' },
  { value: 'builtArea', text: 'Surface bâtie' },
  { value: 'landArea', text: 'Surface terrain' },
];

export const initialPagination: AdPaginationDatas = {
  itemsPerPage: 12,
  sort: 'createdAt',
  page: 1,
  totalAds: 0,
};

export const nbDisplay = [
  { value: 12, text: '12' },
  { value: 24, text: '24' },
  { value: 48, text: '48' },
  { value: 96, text: '96' },
];
