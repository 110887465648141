import { axiosTokenInstance } from '../../services/axiosInstanceService';

export const fetchSubsciption = (endpoint) => {
  return new Promise((resolve, reject) => {
    axiosTokenInstance()
      .get(endpoint)
      .then((response) => resolve(response.data))
      .catch(({ response, request, message, config }) => {
        return reject({ response, request, message, config });
      });
  });
};

export const askForSubscriptionUdate = (body) => {
  return new Promise((resolve, reject) => {
    axiosTokenInstance()
      .post(`/nowhere`, body)
      .then((response) => resolve(response))
      .catch(({ response, request, message, config }) => {
        return reject({ response, request, message, config });
      });
  });
};
