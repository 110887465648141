import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../store/auth/actions';
import { getAuthState } from '../../store/auth/reducer';
import useQuery from '../../utils/useQuery';

const useAuth = () => {
  const query = useQuery();
  const token = query.get('token');
  const { userId, companyId, isLogged, user, marketResearch } = useSelector(
    getAuthState
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      dispatch(authActions.logout.request());
    }
  }, [token]);

  useEffect(() => {
    if (isEmpty(user) && userId !== null && userId !== 'null') {
      dispatch(authActions.fetchAuthUser.request());
    }
  }, [user]);

  return !isEmpty(user)
    ? {
        isLogged,
        user,
        userId,
        userIri: user.idIri,
        companyId,
        companyIri: user.company.idIri,
        totalAds: marketResearch?.totalAds ?? 0,
        totalNewAds: marketResearch?.totalNewAds ?? 0,
        userFullName: user.fullName,
        userFullNameWithInitial: user.fullNameWithInitial,
        userInitials: user.initials,
        isManager: user.roles.includes('ROLE_MANAGER'),
        companyName: user.company.name,
        subscriptionIri: user.company.subscriptionIdIri,
        role: user.roles.includes('ROLE_ADMIN')
          ? 'Admin'
          : user.roles.includes('ROLE_MANAGER')
          ? 'Manager'
          : 'Prospecteur',
      }
    : {
        isLogged,
        user: {},
        userId,
        userIri: userId ? `/users/${userId}` : undefined,
        companyId,
        companyIri: companyId ? `/companies/${companyId}` : undefined,
        role: 'Inconnu',
        totalAds: 0,
        totalNewAds: 0,
      };
};

export default useAuth;
