import { FormEvent, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import fetchAdPlotLocationsThunk from '../../../features/ad/services/thunks/fetchAdPlotLocationsThunk';
import Button from '../../Assets/Button';
import RadioButtons from '../../Forms/RadioButtons';
import { geoRadioBtnsFormValues } from '../constant';

interface SimilarAreaAdFormProps {
  ad: Ad;
}
const SimilarAreaAdForm = ({ ad }: SimilarAreaAdFormProps) => {
  const [choice, setChoice] = useState('0');
  const dispatch = useDispatch();

  useEffect(() => {
    setChoice('0');
  }, [ad]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch(
      fetchAdPlotLocationsThunk({
        inseeCode: ad?.city.inseeCode,
        isLand: ad?.propertyTypology.name === 'Terrain',
        tolerance: choice,
        address: '',
        area: ad?.landArea,
      })
    );
  };

  return (
    <div className="ad-details-search-card">
      <h3 className="ad-details-section-title">
        Recherche automatique de l&#039;emplacement du bien
      </h3>
      <span className="ad-details-search-card-txt">
        Surface du terrain de la parcelle du bien recherché
      </span>
      {ad?.landArea === 0 || ad?.landArea === null ? (
        <p className="no-content">Surface terrain non définie</p>
      ) : (
        <form onSubmit={handleSubmit}>
          <RadioButtons
            name="surface"
            value={choice}
            handleChange={(e) => setChoice(e.target.value)}
            radioElts={geoRadioBtnsFormValues}
          />
          <Button btnType="submit" btnContent="RECHERCHER" primary />
        </form>
      )}
    </div>
  );
};

export default SimilarAreaAdForm;
