import { CircularProgress } from '@material-ui/core';

import './loader.scss';

interface IIndividualCircularLoaderProps {
  size: number;
  className?: string;
}

const IndividualCircularLoader = ({
  size,
  className,
}: IIndividualCircularLoaderProps) => {
  return (
    <div className={`loader-circular ${className}`}>
      <CircularProgress size={size} />
    </div>
  );
};

export default IndividualCircularLoader;
