import { isEmpty } from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';

import SelectInput from '../../../../components/Forms/SelectInput';
import { ReactComponent as CheckWhite } from '../../../../images/check-white.svg';
import useAds from '../../../ad/useAds';
import { pieceworksActions } from '../../constant';
import usePiecework from '../../usePiecework';

function InfoBox() {
  const [action, setAction] = useState('');
  const { totalAds, totalNewAds } = usePiecework({});
  const { selectedAds, createStudyFromAd, deleteAds } = useAds({ noLoadAds: true });

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setAction(e.target.value);
    switch (e.target.value) {
      case 'study':
        createStudyFromAd();
        break;
      case 'delete':
        deleteAds();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (selectedAds.length === 0) {
      setAction('');
    }
  }, [selectedAds]);

  return (
    <div className="piecework-info-box">
      <div className="piecework-info-box-elt">
        <span className="piecework-info-box-nb">{totalAds}</span>
        <span className="piecework-info-box-type">Annonces</span>
      </div>
      <div className="piecework-info-box-elt">
        <span className="piecework-info-box-nb">{totalNewAds}</span>
        <span className="piecework-info-box-type">{`Aujourd'hui`}</span>
      </div>
      <div className="piecework-info-box-elt">
        <span className="piecework-info-box-feedback">
          <CheckWhite />
          {` ${selectedAds.length} annonce(s) sélectionnée(s)`}
        </span>
        <form className="piecework-info-box-form">
          <SelectInput
            name="actions"
            value={action}
            label="ACTIONS"
            nameKey="text"
            handleChange={handleChange}
            disabled={isEmpty(selectedAds)}
            items={pieceworksActions(isEmpty(selectedAds))}
            colorDisplay="negative"
          />
        </form>
      </div>
    </div>
  );
}

export default InfoBox;
