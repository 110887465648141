import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import useAuth from '../App/hooks/useAuth';

import ActionNotification from '../components/Assets/ActionNotification';
import Button from '../components/Assets/Button';
import SectionTitle from '../components/Assets/sectionTitle';
import AutocompleteInputCities from '../components/Forms/AutocompleteInputCities';
import AutocompleteInputCollaborators from '../components/Forms/AutocompleteInputCollaborators';
import AutocompleteInputPropertyTypology from '../components/Forms/AutocompleteInputPropertyTypology';
import AutocompleteInputSellerType from '../components/Forms/AutocompleteInputSellerTypes';
import CheckBox from '../components/Forms/CheckBox';
import DateInput from '../components/Forms/DateInput';
import SelectInput from '../components/Forms/SelectInput';
import SwitchButton from '../components/Forms/SwitchButton';
import TextInput from '../components/Forms/TextInput';
import { activeResearchActions } from '../features/activeResearch/activeResearchSlice';
import KeywordsBloc from '../features/activeResearch/components/ActiveResearchForm/KeywordsBloc';
import SellerNameBloc from '../features/activeResearch/components/ActiveResearchForm/SellerNameBloc';
import {
  createInitialValues,
  dpeGes,
  radiusValues,
  schema,
} from '../features/activeResearch/constant';
import createActiveResearchThunk from '../features/activeResearch/services/thunks/createActiveResearchThunk';
import updateActiveResearchThunk from '../features/activeResearch/services/thunks/updateActiveResearchThunk';
import useActiveResearches from '../features/activeResearch/useActiveResearches';
import { roomsMax, roomsMin } from '../lib/constants';
import { subscriptionActions } from '../store/subscription/actions';
import './styles/activeResearchFormPage.scss';

const ActiveResearchFormPage = () => {
  const { id } = useParams<{ id: string }>();
  const { currentActiveResearch } = useActiveResearches({
    activeResearchId: id,
    loadActiveResearches: true,
  });
  const dispatch = useDispatch();
  const { userIri } = useAuth();
  const history = useHistory();

  const formik = useFormik<ActiveResearchFormValues>({
    initialValues: !isEmpty(currentActiveResearch)
      ? {
          ...currentActiveResearch,
          owner: currentActiveResearch.owner.idIri,
          // remove own user form collaborator for mui autocomplete warning
          collaborators: currentActiveResearch.collaborators.filter(
            (f) => f.idIri !== userIri
          ),
        }
      : {
          ...createInitialValues,
          owner: userIri,
        },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (id && currentActiveResearch) {
        const collab = currentActiveResearch.collaborators.find(
          (f) => f.idIri === userIri
        );

        dispatch(
          updateActiveResearchThunk({
            idIri: currentActiveResearch!.idIri,
            body: {
              ...values,
              // add own user to collaborators fot update entity
              collaborators: values.collaborators.concat(collab),
            },
            history,
          })
        );
      } else {
        dispatch(
          createActiveResearchThunk({
            body: values,
            history,
          })
        );
      }
    },
  });

  useEffect(() => {
    return () => {
      dispatch(activeResearchActions.currentActiveResearchReset());
      dispatch(subscriptionActions.resetSubscriptionCities.reset());
      formik.resetForm();
    };
  }, []);

  const handleNullOrIntValue = (e: any) => {
    const intValue = parseInt(e.target.value, 10);
    const value =
      e.target.value === '' ? null : isNaN(intValue) ? e.target.value : intValue;
    formik.setFieldValue(e.target.name, value);
  };
  const handleCancel = () => {
    history.goBack();
  };

  const { values, errors, touched } = formik;

  const handleChangeCity = (name: string, values: Cities) => {
    formik.setFieldValue(name, values);
    if (values.length !== 1) {
      formik.setFieldValue('radius', null);
      formik.setFieldValue('hasCityRadius', false);
      formik.setFieldValue('cityIdRadiusOrigin', null);
    }
  };

  const handleChangeRadius = (value: any) => {
    if (value) {
      formik.setFieldValue('radius', value);
      formik.setFieldValue('hasCityRadius', true);
      formik.setFieldValue('cityIdRadiusOrigin', values.cities[0].id);
    } else {
      formik.setFieldValue('radius', null);
      formik.setFieldValue('hasCityRadius', false);
      formik.setFieldValue('cityIdRadiusOrigin', null);
    }
  };

  return (
    <main className="active-search-edit">
      <ActionNotification />
      <header>
        <SectionTitle
          mainTitle={`${id ? 'Modifiez' : 'Créez'} une recherche active`}
        />
      </header>
      <section className="active-search-edit-form">
        <form
          noValidate
          className="active-search-form"
          onKeyDown={(e) => {
            if (e.code === 'Enter') e.preventDefault();
          }}
        >
          <div className="active-search-form-group">
            <div className="active-search-form-column">
              <TextInput
                name="name"
                label="Titre de la recherche active"
                value={values.name}
                handleChange={formik.handleChange}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name ? (errors.name as string) : ''}
                required
              />
              <AutocompleteInputCities
                name="cities"
                value={values.cities ?? []}
                handleChange={handleChangeCity}
                error={touched.cities && Boolean(errors.cities)}
                helperText={touched.cities ? (errors.cities as string) : ''}
                multiple
                isRa
              />
              <div className="active-search-form-group">
                <div className="radius-label">
                  Recherche dans un périmètre autours d’une commune
                </div>
                <div className="radius-select">
                  <SelectInput
                    label="Rayon"
                    name="radius"
                    value={values.radius ?? ''}
                    nameKey="text"
                    handleChange={(e) => handleChangeRadius(e.target.value)}
                    error={Boolean(errors.radius)}
                    helperText={errors.radius as string}
                    items={radiusValues}
                    disabled={values.cities.length > 1}
                  />
                </div>
              </div>
              <AutocompleteInputPropertyTypology
                name="propertyTypologies"
                value={values.propertyTypologies ?? []}
                handleChange={formik.setFieldValue}
                error={
                  touched.propertyTypologies && Boolean(errors.propertyTypologies)
                }
                helperText={
                  touched.propertyTypologies && (errors.propertyTypologies as string)
                }
                multiple
              />
              <div className="active-search-form">
                <div className="active-search-form-group">
                  <SelectInput
                    label="Nombre de pièces minimum"
                    name="room.min"
                    value={values.room.min ?? ''}
                    nameKey="text"
                    handleChange={handleNullOrIntValue}
                    error={Boolean(errors.room?.min)}
                    helperText={errors.room?.min as string}
                    items={roomsMin}
                  />
                  <SelectInput
                    label="Nombre de pièces maximum"
                    name="room.max"
                    value={values.room.max ?? ''}
                    nameKey="text"
                    handleChange={handleNullOrIntValue}
                    error={Boolean(errors.room?.max)}
                    helperText={errors.room?.max as string}
                    items={roomsMax}
                  />
                </div>
                <div className="active-search-form-group">
                  <TextInput
                    name="price.min"
                    label="Prix minimum"
                    value={values.price.min}
                    type="number"
                    handleChange={handleNullOrIntValue}
                    error={Boolean(errors.price?.min)}
                    helperText={errors.price?.min as string}
                  />
                  <TextInput
                    name="price.max"
                    label="Prix maximum"
                    value={values.price.max}
                    type="number"
                    handleChange={handleNullOrIntValue}
                    error={Boolean(errors.price?.max)}
                    helperText={errors.price?.max as string}
                  />
                </div>
                <div className="active-search-form-group">
                  <TextInput
                    name="sqmPrice.min"
                    label="Prix/m² minimum"
                    value={values.sqmPrice.min}
                    type="number"
                    handleChange={handleNullOrIntValue}
                    error={Boolean(errors.sqmPrice?.min)}
                    helperText={errors.sqmPrice?.min as string}
                  />
                  <TextInput
                    name="sqmPrice.max"
                    label="Prix/m² maximum"
                    value={values.sqmPrice.max}
                    type="number"
                    handleChange={handleNullOrIntValue}
                    error={Boolean(errors.sqmPrice?.max)}
                    helperText={errors.sqmPrice?.max as string}
                  />
                </div>
                <div className="active-search-form-group">
                  <TextInput
                    name="builtArea.min"
                    label="Surface bâtie minimale"
                    value={values.builtArea.min}
                    type="number"
                    handleChange={handleNullOrIntValue}
                    error={Boolean(errors.builtArea?.min)}
                    helperText={errors.builtArea?.min as string}
                  />
                  <TextInput
                    name="builtArea.max"
                    label="Surface bâtie maximale"
                    value={values.builtArea.max}
                    type="number"
                    handleChange={handleNullOrIntValue}
                    error={Boolean(errors.builtArea?.max)}
                    helperText={errors.builtArea?.max as string}
                  />
                </div>
                <div className="active-search-form-group">
                  <TextInput
                    name="landArea.min"
                    label="Surface terrain minimale"
                    value={values.landArea?.min}
                    type="number"
                    handleChange={handleNullOrIntValue}
                    error={Boolean(errors.landArea?.min)}
                    helperText={errors.landArea?.min as string}
                  />
                  <TextInput
                    name="landArea.max"
                    label="Surface terrain maximale"
                    value={values.landArea.max}
                    type="number"
                    handleChange={handleNullOrIntValue}
                    error={Boolean(errors.landArea?.max)}
                    helperText={errors.landArea?.max as string}
                  />
                </div>
                <div className="active-search-form-group">
                  <SelectInput
                    label="DPE minimum"
                    name="dpe.min"
                    value={values.dpe?.min ?? ''}
                    nameKey="text"
                    handleChange={handleNullOrIntValue}
                    error={Boolean(errors.dpe?.min)}
                    helperText={errors.dpe?.min as string}
                    items={dpeGes('dpe')}
                  />
                  <SelectInput
                    label="DPE maximum"
                    name="dpe.max"
                    value={values.dpe?.max ?? ''}
                    nameKey="text"
                    handleChange={handleNullOrIntValue}
                    error={Boolean(errors.dpe?.max)}
                    helperText={errors.dpe?.max as string}
                    items={dpeGes('dpe')}
                  />
                </div>
                <div className="active-search-form-group">
                  <SelectInput
                    label="GES minimum"
                    name="ges.min"
                    value={values.ges?.min ?? ''}
                    nameKey="text"
                    handleChange={handleNullOrIntValue}
                    error={Boolean(errors.ges?.min)}
                    helperText={errors.ges?.min as string}
                    items={dpeGes('ges')}
                  />
                  <SelectInput
                    label="GES maximum"
                    name="ges.max"
                    value={values.ges?.max ?? ''}
                    nameKey="text"
                    handleChange={handleNullOrIntValue}
                    error={Boolean(errors.ges?.max)}
                    helperText={errors.ges?.max as string}
                    items={dpeGes('ges')}
                  />
                </div>
              </div>
            </div>
            <div className="active-search-form-column">
              <AutocompleteInputCollaborators
                name="collaborators"
                value={values.collaborators ?? []}
                handleChange={formik.setFieldValue}
                multiple
              />

              <AutocompleteInputSellerType
                name="sellerTypes"
                onChange={formik.setFieldValue}
                value={values.sellerTypes}
                multiple
              />
              <DateInput
                name="limitFromDate"
                label="Annonces émises depuis le"
                value={values.limitFromDate}
                handleChange={formik.setFieldValue}
                helperText={errors.limitFromDate as string}
              />

              <SellerNameBloc formik={formik} />

              <KeywordsBloc formik={formik} />

              <div className="active-search-form-checkbox">
                <SwitchButton
                  name="isAlertEnabled"
                  checked={values.isAlertEnabled ?? false}
                  handleChange={formik.handleChange}
                  label="Recevoir des alertes"
                />
                <CheckBox
                  name="isGoodDealsOnly"
                  checked={values.isGoodDealsOnly ?? false}
                  handleChange={formik.handleChange}
                  label="Les bons coups uniquement"
                />
                <CheckBox
                  name="isExclusiveOnly"
                  checked={values.isExclusiveOnly ?? false}
                  handleChange={formik.handleChange}
                  label="Bien en exclu"
                />
              </div>
            </div>
          </div>

          <div className="active-search-form-btn-group">
            <Button
              btnType="button"
              btnContent="ANNULER"
              handleClick={handleCancel}
              textColor="violet"
              bgColor="white"
              borderColor="grey"
            />
            <Button
              btnType="submit"
              btnContent="ENREGISTRER"
              handleClick={formik.handleSubmit}
              primary
            />
          </div>
        </form>
      </section>
    </main>
  );
};

export default ActiveResearchFormPage;
