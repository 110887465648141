import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateEntity } from '../../../../services/axiosInstanceService';
import { appManagerActions } from '../../../../store/appManager/actions';
import { loaderActions } from '../../../../store/loader/actions';
import {
  activeResearchDetailApiToStoreParser,
  activeResearchFormToApi,
} from '../../activeResearchParser';
import { activeSearchesNotifications } from '../../notifications';

const updateActiveResearchThunk = createAsyncThunk(
  'activeResearch/updateActiveResearchThunk',
  async (
    params: {
      body:
        | ActiveResearchFormValues
        | Pick<ActiveResearchFormValues, 'isAlertEnabled'>;
      history: any;
      idIri: string | null;
      isAlertOnly?: boolean;
    },
    { dispatch }
  ) => {
    const { idIri, body, history, isAlertOnly } = params;
    try {
      if (!body) throw new Error('Datas to update not found');
      if (!idIri) throw new Error('IdIri not found');

      dispatch(loaderActions.loaderDisplay.visible());

      const parsedBody = isAlertOnly
        ? { is_alert_enabled: body.isAlertEnabled }
        : activeResearchFormToApi(body as ActiveResearchFormValues);

      const result = await updateEntity({ idIri, body: parsedBody });

      const parsedResult = activeResearchDetailApiToStoreParser(result);

      if (history && !isAlertOnly) {
        history.push(`/recherche-active-resultat/${parsedResult.id}`);
      }

      dispatch(loaderActions.loaderDisplay.hidden());
      dispatch(
        appManagerActions.actionNotificationDisplay.visible(
          activeSearchesNotifications.update(result.name).success()
        )
      );

      return parsedResult;
    } catch (error) {
      dispatch(loaderActions.loaderDisplay.hidden());
      dispatch(
        appManagerActions.actionNotificationDisplay.visible(
          activeSearchesNotifications.update().failure()
        )
      );
      return Promise.reject(error);
    }
  }
);

export default updateActiveResearchThunk;
