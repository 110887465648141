import { isEmpty } from 'lodash';

import { Tooltip } from '@material-ui/core';
import { ReactComponent as MapMarker } from '../../../../images/map-marker.svg';

import './activeResearchCard.scss';

const CardBody = ({
  cities,
  propertyTypologies,
}: Pick<ActiveResearch, 'cities' | 'propertyTypologies'>) => {
  function citiesTooltip() {
    const arr: string[] = [];
    cities.forEach((e) => {
      if (arr.join(', ').length <= 40) {
        arr.push(e.name);
      } else {
        return;
      }
    });
    return arr.join(', ');
  }

  function citiesName() {
    return cities.map((e) => e.name).join(', ');
  }

  return (
    <section className="active-search-card-content">
      <div className="active-search-card-cities">
        <MapMarker width="20px" />
        <div className="active-search-card-cities-list">
          {isEmpty(cities) ? (
            ''
          ) : citiesName().length <= 40 ? (
            citiesName()
          ) : (
            <Tooltip title={citiesName()} placement="top" interactive>
              <span>
                {citiesTooltip()}
                ...
              </span>
            </Tooltip>
          )}
        </div>
      </div>
      <span className="active-search-card-types">
        {propertyTypologies.map((e) => e.name).join(', ')}
      </span>
    </section>
  );
};

export default CardBody;
