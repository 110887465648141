import { companyMapping } from '../auth/parser';
import {
  changeApiKeyForStoreKey,
  changeStoreKeyForApiKey,
  emptyKeyRemove,
  objectKeyGenericMaping,
} from '../common/parserCommon';

const userMapping = {
  '@id': 'idIri',
  id: 'id',
  first_name: 'firstName',
  last_name: 'lastName',
  email: 'email',
  enabled: 'enabled',
  roles: 'roles',
  company: 'company',
  market_research: 'marketResearch',
  phone_number: 'phoneNumber',
  second_phone_number: 'secondPhoneNumber',
  picture_path: 'picturePath',
  password: 'password',
};

export const collaboratorApiToStore = (data) => {
  const parsedData = changeApiKeyForStoreKey(data, userMapping);

  if (!parsedData.enabled) {
    parsedData.enabled = false;
  }
  if (!parsedData.roles) {
    parsedData.roles = [];
  }
  if (parsedData.company) {
    parsedData.company = changeApiKeyForStoreKey(
      parsedData.company,
      objectKeyGenericMaping(companyMapping).base
    );
  }

  parsedData.fullName = `${parsedData.firstName} ${parsedData.lastName}`;

  return parsedData;
};

export const nestedCollaboratorApiToStore = (c) => {
  return collaboratorApiToStore(c.user);
};

export const nestedCollaboratorsApiToStore = (data) =>
  data.map((c) => collaboratorApiToStore(c.user));

export const collaboratorFormToApi = (data) => {
  let parsedData = changeStoreKeyForApiKey(data, userMapping);

  parsedData = emptyKeyRemove(parsedData);

  return parsedData;
};

export const nestedCollaboratorFormToApi = (c) => ({ user: c.idIri });

export const nestedCollaboratorsFormToApi = (data) => {
  return data.map(nestedCollaboratorFormToApi);
};
