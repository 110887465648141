import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../App/redux/store';
import { APIStatus } from '../../services/axiosEnums';
import fetchMarketResearchThunk from './services/fetchMarketResearchThunk';
import updateMarketResearchCitiesThunk from './services/updateMarketResearchCitiesThunk';

const initialState: PieceworkState = {
  marketResearch: {
    idIri: null,
    totalAds: 0,
    totalNewAds: 0,
    cities: [],
  },
  apiStatus: { apiStatus: APIStatus.IDLE },
};

const pieceworkSlice = createSlice({
  name: 'piecework',
  initialState,
  reducers: {
    reset: () => initialState,
    marketSearchSet: (state, action: PayloadAction<MarketResearch>) => {
      state.marketResearch = action.payload;
    },
    setTotalAds: (
      state,
      action: PayloadAction<Omit<MarketResearch, 'idIri' | 'cities'>>
    ) => {
      state.marketResearch.totalAds = action.payload.totalAds;
      state.marketResearch.totalNewAds = action.payload.totalNewAds;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMarketResearchThunk.pending, (state) => {
        state.apiStatus = { apiStatus: APIStatus.PENDING, error: undefined };
      })
      .addCase(
        fetchMarketResearchThunk.fulfilled,
        (state, action: PayloadAction<MarketResearch>) => {
          state.apiStatus = { apiStatus: APIStatus.IDLE, error: undefined };
          state.marketResearch.cities = action.payload.cities;
        }
      )
      .addCase(fetchMarketResearchThunk.rejected, (state, action) => {
        state.apiStatus = { apiStatus: APIStatus.PENDING, error: action.error };
      });
    builder
      .addCase(updateMarketResearchCitiesThunk.pending, (state) => {
        state.apiStatus = { apiStatus: APIStatus.PENDING, error: undefined };
      })
      .addCase(
        updateMarketResearchCitiesThunk.fulfilled,
        (state, action: PayloadAction<Cities>) => {
          state.apiStatus = { apiStatus: APIStatus.IDLE, error: undefined };
          state.marketResearch.cities = action.payload;
        }
      )
      .addCase(updateMarketResearchCitiesThunk.rejected, (state, action) => {
        state.apiStatus = { apiStatus: APIStatus.PENDING, error: action.error };
      });
  },
});

export default pieceworkSlice.reducer;
export const pieceworkActions = pieceworkSlice.actions;
export const getPieceworkState = (state: RootState) => state.piecework;
