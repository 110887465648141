import { useFormik } from 'formik';
import { forwardRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useAdStudies from '../../App/hooks/useAdStudies';

import CheckBox from '../Forms/CheckBox';
import TextInput from '../Forms/TextInput';
import ValidationButton from './customComponants/ValidationButton';

import { adStudiesActions } from '../../store/adStudies/actions';
import { buildingPlotInitialValues, buildingPlotSchema } from './form';

function BuidingPlot(props, ref) {
  const dispatch = useDispatch();
  const {
    activeAdStudy: { buildingPlot, idIri },
  } = useAdStudies({});

  const formik = useFormik({
    initialValues: buildingPlot ?? buildingPlotInitialValues,
    validationSchema: buildingPlotSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(
        adStudiesActions.updateAdStudy.request({
          body: values,
          type: 'buildingPlot',
          idIri,
        })
      );
    },
  });

  const { dirty } = formik;

  useEffect(() => {
    dispatch(adStudiesActions.formTouchStates.set({ buildingPlotForm: dirty }));
  }, [dirty]);

  return (
    <div className="my-folders-ad-creation-content-main-group-form">
      <h4>Construction</h4>
      <div className="my-folders-ad-creation-content-main-group-form-row">
        <CheckBox
          checked={formik.values.isBuildable}
          handleChange={(e) => formik.setFieldValue('isBuildable', e.target.checked)}
          name="isBuildable"
        />
        <p>Constructible</p>
      </div>
      <div className="my-folders-ad-creation-content-main-group-form-row">
        <TextInput
          label="Commentaire constructibilité"
          rows={4}
          name="comment"
          value={formik.values.comment}
          handleChange={formik.handleChange}
        />
      </div>
      <ValidationButton ref={ref} onClick={formik.handleSubmit} />
    </div>
  );
}

export default forwardRef(BuidingPlot);
