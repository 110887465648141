import {
  action,
  createRequestTypes,
  FAILURE,
  MODAL_DISPLAY,
  REQUEST,
  RESET,
  SET,
  SUCCESS,
} from '../common/actions';

export const FETCH_AD_STUDIES = createRequestTypes('FETCH_AD_STUDIES');
export const FETCH_AD_STUDY = createRequestTypes('FETCH_AD_STUDY');
export const CREATE_AD_STUDIES = createRequestTypes('CREATE_AD_STUDIES');
export const UPDATE_AD_STUDY = createRequestTypes('UPDATE_AD_STUDY');
export const DELETE_AD_STUDIES = createRequestTypes('DELETE_AD_STUDIES');
export const RESET_AD_STUDIES = createRequestTypes('RESET_AD_STUDIES');
export const SELECTED_AD_STUDIES = createRequestTypes('SELECTED_AD_STUDIES');
export const SELECTED_AD_STUDY = createRequestTypes('SELECTED_AD_STUDY');
export const FORM_TOUCH_STATES = createRequestTypes('FORM_TOUCH_STATES');
export const MOVE_AD_STUDIES = createRequestTypes('MOVE_AD_STUDIES');
export const PAGINATION_AD_STUDIES = createRequestTypes('PAGINATION_AD_STUDIES');
export const RESET_SELECTED_AD_STUDIES = createRequestTypes(
  'RESET_SELECTED_AD_STUDIES'
);

export const adStudiesActions = {
  fetchAdStudy: {
    request: (data) => action(FETCH_AD_STUDY[REQUEST], { payload: data }),
    success: (data) => action(FETCH_AD_STUDY[SUCCESS], { payload: data }),
    failure: (data) => action(FETCH_AD_STUDY[FAILURE], { payload: data }),
  },
  createAdStudies: {
    modal: () => action(CREATE_AD_STUDIES[MODAL_DISPLAY]),
    request: (data) => action(CREATE_AD_STUDIES[REQUEST], { payload: data }),
    success: (data) => action(CREATE_AD_STUDIES[SUCCESS], { payload: data }),
    failure: (data) => action(CREATE_AD_STUDIES[FAILURE], { payload: data }),
    reset: () => action(CREATE_AD_STUDIES[RESET]),
  },
  updateAdStudy: {
    request: (data) => action(UPDATE_AD_STUDY[REQUEST], { payload: data }),
    success: (data) => action(UPDATE_AD_STUDY[SUCCESS], { payload: data }),
    failure: (data) => action(UPDATE_AD_STUDY[FAILURE], { payload: data }),
  },
  deleteAdStudies: {
    request: (data) => action(DELETE_AD_STUDIES[REQUEST], { payload: data }),
  },
  moveAdStudies: {
    modal: () => action(MOVE_AD_STUDIES[MODAL_DISPLAY]),
    request: (data) => action(MOVE_AD_STUDIES[REQUEST], { payload: data }),
  },
  activeAdStudy: {
    set: (data) => action(SELECTED_AD_STUDY[SET], { payload: data }),
    reset: () => action(SELECTED_AD_STUDY[RESET], { payload: null }),
  },
  formTouchStates: {
    set: (data) => action(FORM_TOUCH_STATES[SET], { payload: data }),
    reset: () => action(FORM_TOUCH_STATES[RESET]),
  },
};
