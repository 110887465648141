import { useState } from 'react';

import LeftBand from './leftBand';
import styles from './sign.module.scss';

import SignActionTitle from './signActionTitle';
import SignChoiceButton from './signChoiceButton';
import SignIn from './signIn';

function Sign() {
  const [hasAccount, setHasAccount] = useState(true);

  const handleButtonClick = (value: boolean) => {
    window.open('https://go.pricehubble.com/prospec-immo-contact-us-page-2');
  };

  return (
    <div className={styles.sign}>
      <LeftBand />

      <main className={styles.content}>
        <SignActionTitle hasAccount={hasAccount} className={styles.actionTitle} />

        <SignChoiceButton
          hasAccount={hasAccount}
          onClick={handleButtonClick}
          className={styles.actionButton}
        />

        <SignIn className={styles.signIn} />
      </main>
    </div>
  );
}

export default Sign;
