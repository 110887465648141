import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { foldersActions } from '../../store/folders/actions';
import { getFoldersState } from '../../store/folders/reducer';

export default function useFolders(folderId, userId) {
  const [selectedFolder, setSelectedFolder] = useState(null);
  const { folders, activeFolder, deleteModal, movingAds } = useSelector(
    getFoldersState
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(folders)) {
      dispatch(foldersActions.fetchFolders.request());
    }
    if (!activeFolder && folderId) {
      dispatch(foldersActions.setActiveFolder.set(`folders/${folderId}`));
    }
  }, []);

  useEffect(() => {
    let folderTemp = null;

    if (!isEmpty(folders)) {
      if (folderId) {
        folderTemp = folders.find((f) => f.id === folderId);
      } else if (activeFolder) {
        folderTemp = folders.find((f) => f.idIri === activeFolder);
      }

      setSelectedFolder(folderTemp);
    }
  }, [folders, activeFolder, folderId, userId]);

  return { folders, selectedFolder, activeFolder, deleteModal, movingAds };
}
