import { useEffect, useState } from 'react';
import Map from '../../../features/adMap/Map';

import SinglePlotMapContainer from '../../../features/singlePlotMap/SinglePlotMapContainer';
import './adDetailsGeo.scss';
import SimilarAreaAdForm from './SimilarAreaAdForm';

interface IGeoContentProps {
  ad: Ad;
}

const GeoContent = ({ ad }: IGeoContentProps) => {
  const { propertyTypology, coordinates, city, price } = ad;
  const [displayedLatLng, setDisplayedLatLng] = useState<DisplayedLatLngState>(null);

  useEffect(() => {
    // if no lat/lng on add, take the city lat/lng
    if (coordinates?.lat && coordinates?.lng) {
      setDisplayedLatLng({
        lat: coordinates.lat,
        lng: coordinates.lng,
        city: false,
      });
    } else if (city.lat && city.lng) {
      setDisplayedLatLng({ lat: city.lat, lng: city.lng, city: true });
    }
  }, [coordinates, city]);

  return (
    <>
      {ad && (
        <div className="ad-details-geo">
          {/* display map for all typos */}
          <Map
            city={city}
            displayedLatLng={displayedLatLng}
            typologyName={ad.propertyTypology.name}
            price={price}
          />

          {/* hide simiraries search if typo is appartpent */}
          {propertyTypology.name !== 'Appartement' && (
            <>
              <SimilarAreaAdForm ad={ad} />

              <SinglePlotMapContainer />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default GeoContent;
