export const roomsMin = [
  { value: '', text: 'Pas de minimum' },
  { value: 1, text: 'T1' },
  { value: 2, text: 'T2' },
  { value: 3, text: 'T3' },
  { value: 4, text: 'T4' },
  { value: 5, text: 'T5' },
  { value: 6, text: 'T6+' },
];

export const roomsMax = [
  { value: '', text: 'Pas de maximum' },
  { value: 1, text: 'T1' },
  { value: 2, text: 'T2' },
  { value: 3, text: 'T3' },
  { value: 4, text: 'T4' },
  { value: 5, text: 'T5' },
  { value: 6, text: 'T6+' },
];

export const adsSource = [
  { idIri: 0, name: 'Tous' },
  { idIri: 1, name: 'Sites immo' },
  { idIri: 2, name: 'Particuliers' },
  { idIri: 3, name: 'Immobilier neuf' },
  { idIri: 4, name: 'Immo pro' },
  { idIri: 5, name: 'Annonces légales' },
  { idIri: 6, name: 'Ventes Etat' },
  { idIri: 7, name: 'Autre' },
];

export const state = [
  {
    value: '',
    text: 'Non renseigné',
  },
  { value: 'new', text: 'Neuf' },
  { value: 'old', text: 'Ancien' },
];

export const countries = [
  { value: '', text: 'Pays...' },
  { value: 'France', text: 'France' },
  { value: 'Espagne', text: 'Espagne' },
  { value: 'Italie', text: 'Italie' },
  { value: 'Angleterre', text: 'Angleterre' },
];
