import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDatas } from '../../../../services/axiosInstanceService';

export type TotalNewAdsThunkResult = {
  id: string;
  totalAds: number;
  totalNewAds: number;
}[];

const fetchActiveResearchesTotalAdsThunk = createAsyncThunk(
  'activeResearch/fetchActiveResearchesTotalAdsThunk',
  async (params: { ids: string[] }) => {
    try {
      if (!params.ids) throw new Error('No ids found');

      const urls = params.ids.map((m) => `${m}/total-ads`);
      const result = await Promise.all(urls.map((m) => fetchDatas(m)));

      const parsed: TotalNewAdsThunkResult = params.ids.map((id, i) => ({
        id,
        totalAds: result[i].total_ads,
        totalNewAds: result[i].total_new_ads,
      }));
      return parsed;
    } catch (error: any) {
      return Promise.reject(error);
    }
  }
);

export default fetchActiveResearchesTotalAdsThunk;
