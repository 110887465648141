import { nanoid } from '@reduxjs/toolkit';
import LogoButton from '../../../../components/Assets/LogoButton';
import usePiecework from '../../usePiecework';

const PigeParametersCitiesList = () => {
  const { cities, handleCityChange } = usePiecework({});

  return (
    <div className="user-pige-elt">
      <h3 className="user-pige-title">Villes affichées</h3>
      <div className="user-pige-list">
        <h4 className="user-pige-list-title">Villes</h4>
        {
          <ul className="user-pige-cities">
            {cities?.map((city) => {
              return (
                <li key={nanoid()}>
                  <LogoButton
                    btnType="button"
                    handleClick={() => handleCityChange([city])}
                    btnLogo="delete-red"
                    tooltip={`Supprimer`}
                  />
                  {city.name}
                </li>
              );
            })}
          </ul>
        }
      </div>
    </div>
  );
};

export default PigeParametersCitiesList;
