import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import EditSubscription from '../components/Account/RegistrationTab/EditSubscription';
import FormCollaborator from '../components/Account/TeamTab/FormCollaborator';
import ComparativeStudyDeleteModal from '../components/ComparativeStudies/ComparativeStudyDeleteModal';
import ComparativeStudyModalContent from '../components/ComparativeStudies/Details/ComparativeStudyModalContent';
import ChooseFolder from '../components/Modals/ChooseFolder';
import Confirmation from '../components/Modals/Confirmation';
import EditFolders from '../components/Modals/EditFolders';
import ErrorPiecework from '../components/Modals/ErrorPiecework';
import Modal from '../components/Modals/Modal';
import SubscriptionSummary from '../components/Modals/SubscriptionSummary';
import MyFoldersDeleteModal from '../components/MyFolders/MyFoldersDeleteModal';
import AdDetails from '../features/ad/components/adDetails/AdDetails';

import { modalsActions } from '../store/modals/actions';
import { getModalState } from '../store/modals/reducer';

import ProcedureEventsModal from '../components/Modals/ProcedureEventsModal';
import NoUrbaneaseAccountModal from '../components/linksToUrbanease/NoUrbaneaseAccountModal';
import AdsDeleteModal from '../features/ad/components/adsDeleteModal/AdsDeleteModal';
import './styles/modalContainer.scss';

const ModalContainer = () => {
  const { pathname } = useLocation();
  const {
    chooseFolder,
    isOverlay,
    modifyFolder,
    deleteFolder,
    subscription,
    subscriptionSummary,
    confirmation,
    deleteComparativeStudy,
    deleteAds,
    comparativeStudy,
    errorPiecework,
    adDetails,
    newCollaborator,
    notificationBell,
    noUrbaneaseAccount,
  } = useSelector(getModalState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(modalsActions.modals.close());
  }, [pathname]);
  return (
    <>
      {isOverlay && (
        <div
          className="overlay"
          onClick={() => dispatch(modalsActions.modals.close())}
        ></div>
      )}
      {deleteComparativeStudy && <ComparativeStudyDeleteModal />}
      {comparativeStudy && <ComparativeStudyModalContent />}
      {chooseFolder && <ChooseFolder />}
      {modifyFolder && <EditFolders />}
      {deleteFolder && <MyFoldersDeleteModal />}
      {deleteAds && <AdsDeleteModal />}
      {errorPiecework && <ErrorPiecework />}
      {notificationBell && <ProcedureEventsModal />}
      {subscription && (
        <Modal>
          <EditSubscription />
        </Modal>
      )}
      {adDetails && (
        <Modal>
          <AdDetails />
        </Modal>
      )}
      {newCollaborator && (
        <Modal>
          <FormCollaborator />
        </Modal>
      )}
      {subscriptionSummary && <SubscriptionSummary />}
      {confirmation && (
        <Confirmation
          message="Vos modifications ne sont pas enregistrés. Souhaitez-vous quitter sans les sauvegarder?"
          cancelBtnText="RETOUR AU FORMULAIRE"
          confirmBtnText="QUITTER"
          redirectPath="details-dossier"
        />
      )}
      {noUrbaneaseAccount.status && (
        <NoUrbaneaseAccountModal errorStatus={noUrbaneaseAccount.error} />
      )}
    </>
  );
};

export default ModalContainer;
