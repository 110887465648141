export const propertyTypologyApiToStoreParser = (data: any): PropertyTypology => {
  const parsed = {
    idIri: data['@id'],
    name: data.name,
  };
  return parsed;
};

export const propertyTypologiesApiToStoreParser = (
  datas: any[]
): PropertyTypologies => datas.map((m: any) => propertyTypologyApiToStoreParser(m));
