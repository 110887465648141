import {
  action,
  createRequestTypes,
  FAILURE,
  REQUEST,
  RESET,
  SET,
  SUCCESS,
} from '../common/actions';

export const LOGIN = createRequestTypes('LOGIN');
export const FETCH_AUTH_USER = createRequestTypes('FETCH_AUTH_USER');
export const UPDATE_AUTH_USER = createRequestTypes('UPDATE_AUTH_USER');
export const UPDATE_AUTH_ADS_COUNT = createRequestTypes('UPDATE_AUTH_ADS_COUNT');
export const REFRESH_TOKEN = createRequestTypes('REFRESH_TOKEN');
export const LOGOUT = createRequestTypes('LOGOUT');
export const PASSWORD_RESET_INIT = createRequestTypes('PASSWORD_RESET_INIT');
export const PASSWORD_RESET = createRequestTypes('PASSWORD_RESET');
export const PASSWORD_CHANGE = createRequestTypes('PASSWORD_CHANGE');
export const FIRST_PASSWORD = createRequestTypes('FIRST_PASSWORD');
export const RESET_NEW_ADS_COUNT = createRequestTypes('RESET_NEW_ADS_COUNT');

export const authActions = {
  login: {
    request: (data) => action(LOGIN[REQUEST], { payload: data }),
    success: (data) => action(LOGIN[SUCCESS], { payload: data }),
    failure: (data) => action(LOGIN[FAILURE], { payload: data }),
  },
  newAdsCount: {
    reset: () => action(RESET_NEW_ADS_COUNT[RESET]),
  },
  fetchAuthUser: {
    request: () => action(FETCH_AUTH_USER[REQUEST]),
    success: (data) => action(FETCH_AUTH_USER[SUCCESS], { payload: data }),
    failure: (data) => action(FETCH_AUTH_USER[FAILURE], { payload: data }),
  },
  updateAuthUser: {
    request: (data) => action(UPDATE_AUTH_USER[REQUEST], { payload: data }),
    success: (data) => action(UPDATE_AUTH_USER[SUCCESS], { payload: data }),
    failure: (data) => action(UPDATE_AUTH_USER[FAILURE], { payload: data }),
  },
  updateAuthAdsCount: {
    set: (data) => action(UPDATE_AUTH_ADS_COUNT[SET], { payload: data }),
  },
  logout: {
    request: (data) => action(LOGOUT[REQUEST], { payload: data }),
    success: (data) => action(LOGOUT[SUCCESS], { payload: data }),
    failure: (data) => action(LOGOUT[FAILURE], { payload: data }),
  },
  refreshToken: {
    request: (data) => action(REFRESH_TOKEN[REQUEST], { payload: data }),
    success: (data) => action(REFRESH_TOKEN[SUCCESS], { payload: data }),
    failure: (data) => action(REFRESH_TOKEN[FAILURE], { payload: data }),
  },
  passwordResetInit: {
    request: (data) => action(PASSWORD_RESET_INIT[REQUEST], { payload: data }),
    success: (data) => action(PASSWORD_RESET_INIT[SUCCESS], { payload: data }),
    failure: (data) => action(PASSWORD_RESET_INIT[FAILURE], { payload: data }),
  },
  passwordReset: {
    request: (data) => action(PASSWORD_RESET[REQUEST], { payload: data }),
    success: (data) => action(PASSWORD_RESET[SUCCESS], { payload: data }),
    failure: (data) => action(PASSWORD_RESET[FAILURE], { payload: data }),
  },
  passwordChange: {
    request: (data) => action(PASSWORD_CHANGE[REQUEST], { payload: data }),
    success: (data) => action(PASSWORD_CHANGE[SUCCESS], { payload: data }),
    failure: (data) => action(PASSWORD_CHANGE[FAILURE], { payload: data }),
  },
  firstPassword: {
    request: (data) => action(FIRST_PASSWORD[REQUEST], { payload: data }),
    success: (data) => action(FIRST_PASSWORD[SUCCESS], { payload: data }),
    failure: (data) => action(FIRST_PASSWORD[FAILURE], { payload: data }),
  },
};
