import { ChangeEvent, useEffect, useState } from 'react';
import useAdStudies from '../../../App/hooks/useAdStudies';
import SelectInput from '../../../components/Forms/SelectInput';
import { actionItems } from '../../../components/MyFoldersContent/constants';

interface IAdStudyActionSelectProps {
  count: number;
}
function AdStudyActionSelect({ count }: IAdStudyActionSelectProps) {
  const [selectedAction, setSelectedAction] = useState<string>('');
  const { deleteAdStudies, moveAdStudies } = useAdStudies({});

  useEffect(() => {
    if (count === 0) {
      setSelectedAction('');
    }
  }, [count]);

  const dynamicActionList = () => {
    return count > 1 ? actionItems.filter((e) => e.value !== 'edit') : actionItems;
  };

  const handleActionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    switch (e.target.value) {
      case 'edit':
        break;
      case 'move':
        moveAdStudies();
        break;
      case 'delete':
        deleteAdStudies();
        break;
      default:
        break;
    }
    setSelectedAction(e.target.value);
  };
  return (
    <SelectInput
      name="action"
      label="Actions"
      nameKey="text"
      value={selectedAction}
      disabled={count === 0}
      handleChange={handleActionChange}
      items={dynamicActionList()}
    />
  );
}

export default AdStudyActionSelect;
