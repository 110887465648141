import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import useMarketStudies from '../../App/hooks/useMarketStudies';
import { dateFormat, twoNumberDigitDisplay } from '../../utils/jsFunctions';

const ResultSummary = () => {
  const { id } = useParams();
  const { selectedMarketStudy } = useMarketStudies(id, id);

  return (
    <>
      {!isEmpty(selectedMarketStudy) && (
        <section className="market-studies-result-summary">
          <h4 className="market-studies-result-section-title">{`Résumé pour "Les ${
            selectedMarketStudy &&
            selectedMarketStudy.propertyTypology.name.toLowerCase()
          }s"`}</h4>
          <span className="market-studies-result-sub-text">{`Générée le ${dateFormat(
            new Date(selectedMarketStudy && selectedMarketStudy.createdAt)
          )} à ${new Date(
            selectedMarketStudy && selectedMarketStudy.createdAt
          ).getUTCHours(
            twoNumberDigitDisplay(
              selectedMarketStudy && selectedMarketStudy.createdAt
            )
          )}h${twoNumberDigitDisplay(
            new Date(
              selectedMarketStudy && selectedMarketStudy.createdAt
            ).getMinutes()
          )}`}</span>
          <span className="market-studies-result-sub-text">{`Ville : ${
            selectedMarketStudy && selectedMarketStudy.city.name
          }`}</span>
        </section>
      )}
    </>
  );
};

ResultSummary.propTypes = {
  result: PropTypes.array,
};
export default ResultSummary;
