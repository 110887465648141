import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useAdStudies from '../../App/hooks/useAdStudies';

import Button from '../Assets/Button';
import AutocompleteInputCities from '../Forms/AutocompleteInputCities';
import AutocompleteInputPropertyTypology from '../Forms/AutocompleteInputPropertyTypology';
import TextInput from '../Forms/TextInput';

import { adStudiesActions } from '../../store/adStudies/actions';
import UrbaneaseButton from '../linksToUrbanease/UrbaneaseButton';
import { localisationInitialValues, localisationSchema } from './form';

function LocalisationForm(props, ref) {
  const {
    activeAdStudy: { localisation, idIri },
  } = useAdStudies({});
  const formik = useFormik({
    initialValues: localisation ?? localisationInitialValues,
    validationSchema: localisationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(
        adStudiesActions.updateAdStudy.request({
          body: values,
          type: 'localisation',
          idIri,
        })
      );
    },
  });

  const dispatch = useDispatch();
  const { values, touched, errors, dirty } = formik;

  useEffect(() => {
    dispatch(adStudiesActions.formTouchStates.set({ localisationForm: dirty }));
  }, [dirty]);

  return (
    <form className="my-folders-ad-creation-content-main-group-form" noValidate>
      <h4>Localisation</h4>
      <div className="my-folders-ad-creation-content-main-group-form-row">
        <AutocompleteInputPropertyTypology
          multiple={false}
          value={values.propertyTypology}
          handleChange={(name, value) =>
            formik.setFieldValue('propertyTypology', value)
          }
        />

        <AutocompleteInputCities
          value={values.city ?? []}
          handleChange={formik.setFieldValue}
        />
      </div>

      <div className="my-folders-ad-creation-content-main-group-form-row">
        <TextInput
          label="Adresse"
          name="address"
          value={values.address}
          handleChange={formik.handleChange}
          error={touched.address && Boolean(errors.address)}
          helperText={touched.address && errors.address}
        />
      </div>
      <div className="my-folders-ad-creation-content-main-group-form-row">
        <TextInput
          label="Surface bâtie"
          name="builtArea"
          value={values.builtArea}
          type="number"
          handleChange={formik.handleChange}
          error={touched.builtArea && Boolean(errors.builtArea)}
          helperText={touched.builtArea && errors.builtArea}
        />
        <TextInput
          label="Surface terrain"
          name="landArea"
          value={values.landArea}
          type="number"
          handleChange={formik.handleChange}
          error={touched.landArea && Boolean(errors.landArea)}
          helperText={touched.landArea && errors.landArea}
        />
        <TextInput
          label="Prix en €"
          name="price"
          value={values.price}
          type="number"
          handleChange={formik.handleChange}
          error={touched.price && Boolean(errors.price)}
          helperText={touched.price && errors.price}
        />
      </div>

      <div
        className={`location-form-buttons ${
          props?.lat && props?.lng ? '' : ' urbanease-btn-hidden'
        }`}
      >
        {props?.lat && props?.lng && (
          <UrbaneaseButton
            department={localisation?.city?.postalCode?.substring(0, 2) ?? null}
            lat={props?.lat ?? null}
            lng={props?.lng ?? null}
          />
        )}
        <Button
          btnType="button"
          btnContent="ENREGISTRER"
          handleClick={formik.handleSubmit}
          textColor="blue"
          bgColor="white"
          borderColor="violet"
        />
      </div>
    </form>
  );
}

LocalisationForm.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
};

export default forwardRef(LocalisationForm);
