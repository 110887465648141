import { isEmpty } from 'lodash';

import useComparativeStudyComparisons from '../../../App/hooks/useComparativeStudyComparisons';
import { ReactComponent as GreenArrow } from '../../../images/arrow-green-down.svg';
import { ReactComponent as RedArrow } from '../../../images/arrow-red-up.svg';
import { spaceNumberFormat } from '../../../utils/jsFunctions';
import Badge from '../../badge';
import { BadgeBackgroundColorEnum, BadgeTextColorEnum } from '../../badge/enums';
import IndividualCircularLoader from '../../Loader/IndividualCircularLoader';

const BadgeContainer = () => {
  const {
    comparisonsResume: { percentagePriceComparison, averagePrice, sqmAveragePrice },
    comparisonsResume,
  } = useComparativeStudyComparisons();

  return (
    <div className="comparative-studies-details-badges">
      {isEmpty(comparisonsResume) ? (
        <IndividualCircularLoader size={50} />
      ) : (
        <>
          <div className="comparative-studies-details-badge comparative-studies-details-badge-market-price">
            <span
              className={`comparative-studies-details-badge-price-amount ${
                percentagePriceComparison > 0 ? 'more' : 'less'
              }`}
            >
              {`${percentagePriceComparison}%`}
              {percentagePriceComparison > 0 ? <RedArrow /> : <GreenArrow />}
            </span>
            <span className="comparative-studies-details-badge-text">
              du prix moyen constaté
            </span>
          </div>
          <div className="comparative-studies-details-badge">
            <span className="comparative-studies-details-badge-text">
              Prix moyen
            </span>
            <Badge
              badgeDynamicContent={spaceNumberFormat({
                value: averagePrice,
                withEuro: true,
              })}
              bgColor={BadgeBackgroundColorEnum.VIOLET}
              textColor={BadgeTextColorEnum.WHITE}
              isMini
            />
          </div>
          <div className="comparative-studies-details-badge">
            <span className="comparative-studies-details-badge-text">
              Prix moyen/m²
            </span>
            <Badge
              badgeDynamicContent={spaceNumberFormat({
                value: sqmAveragePrice,
                withEuro: true,
              })}
              bgColor={BadgeBackgroundColorEnum.VIOLET}
              textColor={BadgeTextColorEnum.WHITE}
              isMini
            />
          </div>
        </>
      )}
    </div>
  );
};

export default BadgeContainer;
