import {
  Document,
  Image,
  Page,
  Path,
  StyleSheet,
  Svg,
  Text,
  View,
} from '@react-pdf/renderer';
import PropTypes from 'prop-types';

import defaultPicture from '../images/logo-prospecimmo.png';
import logo from '../images/logo_pdf.png';
import { getFoldersState } from '../store/folders/reducer';

import store from '../App/redux/store';
import { getImgSrcByServer, spaceNumberWithCommas } from './jsFunctions';

const styles = StyleSheet.create({
  section: {
    padding: 10,
    flexGrow: 1,
  },
  header: {
    width: '100%',
    flexDirection: 'row',
  },
  row: {
    flexDirection: 'row',
  },
  svg: {
    marginLeft: 5,
    marginRight: 5,
  },
  badge: {
    color: '#fff',
    backgroundColor: '#5C2670',
    padding: 5,
    borderRadius: 10,
  },

  headerTitle: {
    color: '#fff',
    backgroundColor: '#5C2670',
    padding: 15,
  },
  headerSubTitle: {
    fontSize: 12,
  },
  headerCity: {
    fontSize: 24,
  },
  headerInfos: {
    margin: 10,
    fontSize: 12,
  },
  headerInfosBold: {
    fontSize: 12,
    marginBottom: 10,
  },
  headerLogo: {
    marginLeft: 130,
    width: 100,
    height: 'auto',
    alignContent: 'center',
    justifyContent: 'center',
  },

  infosGroup: {
    fontSize: 12,
    marginTop: 20,
    marginBottom: 10,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  infos: {
    justifyContent: 'center',
    alignItems: 'center',
    border: 2,
    borderStyle: 'dashed',
    borderColor: '#879EBE',
    padding: 5,
    color: '#5C2670',
    borderRadius: 10,
    minWidth: '25%',
  },
  infosAveragePrice: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    border: 2,
    borderStyle: 'dashed',
    borderColor: '#879EBE',
    padding: 5,
    color: '#5C2670',
    borderRadius: 10,
    minWidth: '25%',
  },
  infosRed: {
    color: '#ED6565',
    fontSize: 15,
  },
  infosGreen: {
    color: '#73CF77',
    fontSize: 15,
  },

  rowImages: {
    justifyContent: 'center',
    flexDirection: 'row',
    width: '100%',
    marginBottom: 10,
  },
  tableTitle: {
    color: '#5C2670',
    marginBottom: 5,
    fontSize: 15,
  },
  table: {
    display: 'table',
    width: 'auto',
  },
  tableRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableCol: {
    width: '10%',
  },
  tableFirstCol: {
    width: 80,
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 10,
    color: '#5C2670',
  },
  tableCellBadge: {
    textAlign: 'center',
    width: 30,
    margin: 'auto',
    marginTop: 5,
    padding: 3,
    fontSize: 10,
    borderRadius: 20,
    color: '#fff',
    backgroundColor: '#5C2670',
  },
  tableImageBadge: {
    position: 'absolute',
    top: 3,
    left: 6,
    textAlign: 'center',
    width: 30,
    margin: 'auto',
    marginTop: 5,
    padding: 3,
    fontSize: 10,
    borderRadius: 20,
    color: '#fff',
    backgroundColor: '#5C2670',
  },
  tableImage: {
    height: 75,
    width: '20%',
    margin: 7,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableCellHeader: {
    margin: 'auto',
    marginBottom: 5,
    fontSize: 10,
    fontWeight: 500,
  },
  tableRowHeader: {
    flexDirection: 'row',
  },
});

const ArrowGreen = () => (
  <Svg
    style={styles.svg}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 13L6 9H14L10 13Z"
      fill="#73CF77"
    />
  </Svg>
);

const ArrowRed = () => (
  <Svg
    style={styles.svg}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path
      d="M10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 -3.91654e-07 10 -8.74228e-07C4.48 -1.3568e-06 1.3568e-06 4.48 8.74228e-07 10C3.91654e-07 15.52 4.48 20 10 20ZM10 7L14 11L6 11L10 7Z"
      fill="#ED6565"
    />
  </Svg>
);

const Header = ({ city, postal, propertyTypology, price, builtArea, landArea }) => (
  <View style={styles.header}>
    <View style={styles.headerTitle}>
      <Text style={styles.headerSubTitle}>Etude comparative</Text>
      <Text style={styles.headerCity}>{city}</Text>
      <Text style={styles.headerSubTitle}>({postal})</Text>
    </View>
    <View style={styles.headerInfos}>
      <View style={styles.headerInfosBold}>
        <Text>{propertyTypology.name}</Text>
        <Text>{price} €</Text>
      </View>
      <View>
        <Text>Surface du bien : {spaceNumberWithCommas(builtArea)} m²</Text>
        <Text>Surface du terrain : {landArea} m²</Text>
      </View>
    </View>
    <View style={styles.headerLogo}>
      <Image src={logo} />
    </View>
  </View>
);

const TableCell = ({ item, header, badge }) => {
  let tableCellStyle = styles.tableCell;
  if (header) tableCellStyle = styles.tableCellHeader;
  if (badge) tableCellStyle = styles.tableCellBadge;

  return (
    <View style={styles.tableCol}>
      <Text style={tableCellStyle}>{item}</Text>
    </View>
  );
};

const TableImage = ({ item, index }) => {
  function returnSrc() {
    try {
      if (item.picture) {
        return getImgSrcByServer(item.picture);
      } else {
        return defaultPicture;
      }
    } catch (error) {
      return defaultPicture;
    }
  }

  return (
    <View style={styles.tableImage}>
      <Image source={returnSrc()} cache={true} />
      <Text style={styles.tableImageBadge}>{index + 1}</Text>
    </View>
  );
};

const TableRowHeader = () => {
  return (
    <View style={styles.tableRowHeader}>
      <View style={styles.tableFirstCol} />
      <TableCell header item={'Typologie'} />
      <TableCell header item={'S. bien'} />
      <TableCell header item={'S. terrain'} />
      <TableCell header item={'Ville'} />
      <TableCell header item={'Origine'} />
      <TableCell header item={'Prix'} />
      <TableCell header item={'Activité'} />
      <TableCell header item={'Dossier'} />
    </View>
  );
};

const Table = ({ ads }) => {
  const { folders } = store.getState(getFoldersState);
  const studiedContentDisplay = (data) => {
    if (!data.isStudied) {
      return ' - ';
    } else {
      const folderIriTab = folders.map((f) => f['@id']);
      let c = 0;
      let sharedFolder = undefined;

      while (c < data.studied.length && !sharedFolder) {
        sharedFolder = data.studied.find((e) => folderIriTab.includes(e));
      }

      return sharedFolder.idIri;
    }
  };

  return (
    <View>
      <View style={styles.section}>
        <Text style={styles.tableTitle}>Biens comparables prix similaires</Text>
        <View style={styles.rowImages}>
          {ads['equal'].map((elt, index) => (
            <TableImage item={elt} index={index} key={elt.idIri} />
          ))}
        </View>
        <View style={styles.table}>
          <TableRowHeader />
          {ads['equal'].map((elt, index) => (
            <View style={styles.tableRow} key={elt.idIri}>
              <TableCell badge item={index + 1} />
              <TableCell item={elt.propertyTypology.name} />
              <TableCell item={`${spaceNumberWithCommas(elt.builtArea)} m²`} />
              <TableCell item={`${spaceNumberWithCommas(elt.landArea)} m²`} />
              <TableCell item={elt.city.name} />
              <TableCell item={elt.source} />
              <TableCell item={`${spaceNumberWithCommas(elt.price)} €`} />
              <TableCell item={elt.status} />
              <TableCell item={studiedContentDisplay(elt.studied)} />
            </View>
          ))}
        </View>
      </View>
      <View style={styles.section}>
        <View style={styles.row}>
          <ArrowGreen />
          <Text style={styles.tableTitle}>Biens comparables prix inférieurs</Text>
        </View>
        <View style={styles.rowImages}>
          {ads['lower'].map((elt, index) => (
            <TableImage item={elt} index={index} key={elt.idIri} />
          ))}
        </View>
        <View style={styles.table}>
          <TableRowHeader />
          {ads['lower'].map((elt, index) => (
            <View style={styles.tableRow} key={elt.idIri}>
              <TableCell badge item={index + 1} />
              <TableCell item={elt.propertyTypology.name} />
              <TableCell item={`${elt.builtArea} m²`} />
              <TableCell item={`${elt.landArea} m²`} />
              <TableCell item={elt.city.name} />
              <TableCell item={elt.source} />
              <TableCell item={`${elt.price} €`} />
              <TableCell item={elt.status} />
              <TableCell item={studiedContentDisplay(elt.studied)} />
            </View>
          ))}
        </View>
      </View>
      <View style={styles.section}>
        <View style={styles.row}>
          <ArrowRed />
          <Text style={styles.tableTitle}>Biens comparables prix supérieurs</Text>
        </View>
        <View style={styles.rowImages}>
          {ads['higher'].map((elt, index) => (
            <TableImage item={elt} index={index} key={elt.idIri} />
          ))}
        </View>
        <View style={styles.table}>
          <TableRowHeader />
          {ads['higher'].map((elt, index) => (
            <View style={styles.tableRow} key={elt.idIri}>
              <TableCell badge item={index + 1} />
              <TableCell item={elt.propertyTypology.name} />
              <TableCell item={`${elt.builtArea} m²`} />
              <TableCell item={`${elt.landArea} m²`} />
              <TableCell item={elt.city.name} />
              <TableCell item={elt.source} />
              <TableCell item={`${elt.price} €`} />
              <TableCell item={elt.status} />
              <TableCell item={studiedContentDisplay(elt.studied)} />
            </View>
          ))}
        </View>
      </View>
    </View>
  );
};
const Infos = ({ comparedPrice, comparedAveragePrice, comparedPriceMeter }) => (
  <View style={styles.infosGroup}>
    <View style={styles.infos}>
      <View style={styles.row}>
        <Text style={comparedPrice <= 0 ? styles.infosGreen : styles.infosRed}>
          {comparedPrice} %
        </Text>
        {comparedPrice <= 0 ? <ArrowGreen /> : <ArrowRed />}
      </View>
      <Text>du prix du marché</Text>
    </View>
    <View style={styles.infosAveragePrice}>
      <Text>{'Prix moyen '}</Text>
      <Text style={styles.badge}>
        {spaceNumberWithCommas(comparedAveragePrice)} €
      </Text>
    </View>
    <View style={styles.infosAveragePrice}>
      <Text>{'Prix moyen/m2 '}</Text>
      <Text style={styles.badge}>{spaceNumberWithCommas(comparedPriceMeter)} €</Text>
    </View>
  </View>
);

const PdfComparativeStudies = ({
  comparativeStudyContent,
  adComparisonsSelected,
  comparisonsResume,
}) => {
  const {
    city,
    price,
    builtArea,
    landArea,
    propertyTypology,
  } = comparativeStudyContent;

  return (
    <Document>
      <Page size="A4">
        <Header
          city={city.name}
          postal={city.postalCode}
          propertyTypology={propertyTypology}
          price={price}
          builtArea={builtArea}
          landArea={landArea}
        />
        <View>
          <Infos
            comparedPrice={comparisonsResume.percentagePriceComparison}
            comparedAveragePrice={comparisonsResume.averagePrice}
            comparedPriceMeter={comparisonsResume.sqmAveragePrice}
          />
          <Table ads={adComparisonsSelected} />
        </View>
      </Page>
    </Document>
  );
};

Header.propTypes = {
  city: PropTypes.string.isRequired,
  postal: PropTypes.string.isRequired,
  propertyTypology: PropTypes.object.isRequired,
  price: PropTypes.number.isRequired,
  builtArea: PropTypes.number.isRequired,
  landArea: PropTypes.number.isRequired,
};

Table.propTypes = {
  ads: PropTypes.object,
};

TableCell.propTypes = {
  item: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  header: PropTypes.bool,
  badge: PropTypes.bool,
};

TableImage.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

Infos.propTypes = {
  comparedPrice: PropTypes.number.isRequired,
  comparedAveragePrice: PropTypes.number.isRequired,
  comparedPriceMeter: PropTypes.number.isRequired,
};

PdfComparativeStudies.propTypes = {
  comparativeStudyContent: PropTypes.object.isRequired,
  adComparisonsSelected: PropTypes.object.isRequired,
  comparisonsResume: PropTypes.object.isRequired,
};

export default PdfComparativeStudies;
