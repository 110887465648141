import { createEntity } from '../../../services/axiosInstanceService';

async function deleteAd(idIri: string) {
  try {
    const result = await createEntity({
      endpoint: '/unwanted-ads',
      body: { ad: idIri },
    });

    if (result?.['@id']) true;

    return false;
  } catch (error) {
    return Promise.reject(error);
  }
}

export default deleteAd;
