import * as yup from 'yup';
import { AREA, CITY, DPE_GES, PRICE, ROOM, TITLE } from '../../utils/validation';

export const radioButtonElts = [
  { value: 0, label: 'Et' },
  { value: 1, label: 'Ou' },
];

export const sellerTypes = [
  { value: 0, name: 'Tous' },
  { value: 1, name: 'Particuliers' },
  { value: 2, name: 'Professionnels' },
  { value: 3, name: 'Enchères' },
  { value: 4, name: 'Etat / Collectivités' },
  { value: 5, name: 'Autre' },
];

export const createInitialValues: ActiveResearchFormValues = {
  builtArea: { min: null, max: null },
  cities: [],
  cityIdRadiusOrigin: null,
  name: '',
  propertyTypologies: [{ idIri: '', name: 'Tous' }],
  room: { min: null, max: null },
  price: { min: null, max: null },
  sqmPrice: { min: null, max: null },
  landArea: { min: null, max: null },
  collaborators: [],
  sellerTypes: [0],
  limitFromDate: null,
  includedKeywordsOperator: 0,
  includedKeywords: [],
  excludedKeywordsOperator: 0,
  excludedKeywords: [],
  includedSellerNameOperator: 0,
  includedSellerName: [],
  excludedSellerNameOperator: 0,
  excludedSellerName: [],
  isGoodDealsOnly: false,
  isAlertEnabled: false,
  isExclusiveOnly: false,
  dpe: { min: null, max: null },
  ges: { min: null, max: null },
  radius: null,
  hasCityRadius: false,
  owner: '',
};

export const activeResearchesActionsValues = [
  { value: '', text: 'Choisir...' },
  { value: 'study', text: 'Etudier' },
  { value: 'delete', text: 'Supprimer' },
];

export const radiusValues = [
  { value: '', text: 'Pas de périmètre alentours' },
  { value: 5, text: '5 km' },
  { value: 10, text: '10 km' },
  { value: 30, text: '30 km' },
];

export const dpeGes = (type: 'dpe' | 'ges') => [
  { value: '', text: type === 'dpe' ? 'Pas de DPE' : 'Pas de GES' },
  { value: 'A', text: 'A' },
  { value: 'B', text: 'B' },
  { value: 'C', text: 'C' },
  { value: 'D', text: 'D' },
  { value: 'E', text: 'E' },
  { value: 'F', text: 'F' },
  { value: 'G', text: 'G' },
];

const yupTest = (value: any, context: yup.TestContext<Record<string, any>>) => {
  const p = context.parent;
  return value && p?.min ? value >= p.min : true;
};
export const schema = yup.object({
  name: yup.string().required(TITLE.required),
  cities: yup.array().min(1, CITY.required).required(),
  propertyTypologies: yup.array(),
  collaborators: yup.array(),
  price: yup.object({
    min: yup.number().typeError(PRICE.type).min(0, PRICE.min).nullable(),
    max: yup
      .number()
      .typeError(PRICE.type)
      .min(0, PRICE.min)
      .nullable()
      .test('priceMaxGreaterThanMin', PRICE.moreThan, (value, context) =>
        yupTest(value, context)
      ),
  }),
  sqmPrice: yup.object({
    min: yup.number().typeError(PRICE.type).min(0, PRICE.min).nullable(),
    max: yup
      .number()
      .typeError(PRICE.type)
      .min(0, PRICE.min)
      .nullable()
      .test('sqmPriceMaxGreaterThanMin', PRICE.moreThan, (value, context) =>
        yupTest(value, context)
      ),
  }),
  room: yup.object({
    min: yup.number().typeError(ROOM.type).min(1, ROOM.min).nullable(),
    max: yup
      .number()
      .typeError(ROOM.type)
      .nullable()
      .test('roomMaxGreaterThanMin', ROOM.moreThanOrEqual, (value, context) =>
        yupTest(value, context)
      ),
  }),
  landArea: yup.object({
    min: yup.number().typeError(AREA.type).min(0, AREA.min).nullable(),
    max: yup
      .number()
      .typeError(AREA.type)
      .min(0, AREA.min)
      .nullable()
      .test('landAreaMaxGreaterThanMin', AREA.moreThan, (value, context) =>
        yupTest(value, context)
      ),
  }),
  builtArea: yup.object({
    min: yup.number().typeError(AREA.type).min(0, AREA.min).nullable(),
    max: yup
      .number()
      .typeError(AREA.type)
      .min(0, AREA.min)
      .nullable()
      .test('builtAreaMaxGreaterThanMin', AREA.moreThan, (value, context) =>
        yupTest(value, context)
      ),
  }),
  dpe: yup.object({
    min: yup.string().nullable(),
    max: yup
      .string()
      .nullable()
      .test('dpeMaxLesserThandpeMin', DPE_GES('DPE').moreThan, (value, context) => {
        const p = context.parent;
        return value && p?.min ? value <= p.min : true;
      }),
  }),
  ges: yup.object({
    min: yup.string().nullable(),
    max: yup
      .string()
      .nullable()
      .test('gesMaxLesserThanGesMin', DPE_GES('GES').moreThan, (value, context) => {
        const p = context.parent;
        return value && p?.min ? value <= p.min : true;
      }),
  }),
});
