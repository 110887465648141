import { createAsyncThunk } from '@reduxjs/toolkit';
import { createEntity } from '../../../../services/axiosInstanceService';
import { appManagerActions } from '../../../../store/appManager/actions';
import { loaderActions } from '../../../../store/loader/actions';
import {
  activeResearchFormToApi,
  activeResearchParser,
} from '../../activeResearchParser';
import { activeSearchesNotifications } from '../../notifications';

const createActiveResearchThunk = createAsyncThunk(
  'activeResearch/createActiveResearchThunk',
  async (params: { body: ActiveResearchFormValues; history: any }, { dispatch }) => {
    try {
      const { body, history } = params;

      if (!body) throw new Error('No form data found');

      dispatch(loaderActions.loaderDisplay.visible());
      const parsedBody = activeResearchFormToApi(body);

      const result = await createEntity({
        endpoint: '/active-researches',
        body: parsedBody,
      });

      const parsedResult = activeResearchParser(result);

      if (history) {
        history.push(`/recherche-active-resultat/${parsedResult.id}`);
      }
      dispatch(loaderActions.loaderDisplay.hidden());
      dispatch(
        appManagerActions.actionNotificationDisplay.visible(
          activeSearchesNotifications.update(result.name).success()
        )
      );
      return parsedResult;
    } catch (error) {
      dispatch(loaderActions.loaderDisplay.hidden());
      dispatch(
        appManagerActions.actionNotificationDisplay.visible(
          activeSearchesNotifications.create().failure()
        )
      );
      return Promise.reject(error);
    }
  }
);

export default createActiveResearchThunk;
