import { fetchDataById } from '../../../services/axiosInstanceService';
import { adApiToStoreParser } from '../adParser';

interface IFetchAdProps {
  idIri: string;
}
async function fetchAd({ idIri }: IFetchAdProps): Promise<Ad> {
  try {
    const result = await fetchDataById(idIri);

    const parsed = adApiToStoreParser(result);

    return parsed;
  } catch (error) {
    return Promise.reject(error);
  }
}

export default fetchAd;
