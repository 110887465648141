import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import useActiveResearches from '../features/activeResearch/useActiveResearches';

import ActionNotification from '../components/Assets/ActionNotification';
import Button from '../components/Assets/Button';
import SectionTitle from '../components/Assets/sectionTitle';

import { activeResearchActions } from '../features/activeResearch/activeResearchSlice';
import ActiveResearchResume from '../features/activeResearch/components/result/ActiveResearchResume';

import ActionButton from '../features/activeResearch/components/result/ActionButton';
import AdCardContainer from '../features/ad/components/adCardContainer/AdCardContainer';

import { isEmpty } from 'lodash';
import { adActions } from '../features/ad/adSlice';
import useAds from '../features/ad/useAds';
import './styles/activeResearchResult.scss';

function ActiveSearchResultContent() {
  const { id } = useParams<{ id: string }>();

  const history = useHistory();
  const dispatch = useDispatch();
  const { currentActiveResearch } = useActiveResearches({
    activeResearchId: id,
    loadActiveResearches: true,
  });
  const {
    ads,
    adPagination,
    addItemToSelectedAd,
    loadActiveAd,
    selectedAds,
    handlePaginationChange,
  } = useAds({});

  useEffect(() => {
    return () => {
      dispatch(activeResearchActions.currentActiveResearchReset());
      dispatch(adActions.reset());
    };
  }, []);

  return (
    <main className="active-search-result">
      <ActionNotification />
      <header className="active-search-result-header">
        <div className="active-search-result-header-elt">
          <div className="active-search-result-ads">
            <span className="active-search-result-ads-nb">
              {adPagination.totalAds ?? 0}
            </span>
            <span>Annonces</span>
          </div>
          <SectionTitle
            mainTitle="Critères de recherche active"
            subTitle="Créez vos propres critères de recherches"
          />
        </div>
        <div className="active-search-result-header-elt">
          <ActionButton disabled={isEmpty(selectedAds)} />
          <Button
            btnType="button"
            btnContent="MODIFIER / AFFINER MES CRITERES"
            primary
            handleClick={() => history.push(`/recherche-active-modification/${id}`)}
          />

          <Button
            btnType="button"
            btnContent="PRÉCÉDENT"
            handleClick={() => history.push(`/recherches-actives`)}
            textColor="violet"
            bgColor="white"
            borderColor="grey"
          />
        </div>
      </header>

      <ActiveResearchResume currentActiveResearch={currentActiveResearch} />

      <AdCardContainer
        type="activeResearches"
        ads={ads}
        checkedIds={selectedAds.map((m) => m.idIri)}
        onPaginationChange={handlePaginationChange}
        onCheckAd={addItemToSelectedAd}
        onCardClick={loadActiveAd}
        adsPagination={adPagination}
      />
    </main>
  );
}

export default ActiveSearchResultContent;
