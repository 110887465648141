import Pagination from '@material-ui/lab/Pagination';
import { nanoid } from '@reduxjs/toolkit';

import IndividualCircularLoader from '../../../../components/Loader/IndividualCircularLoader';
import { APIStatus } from '../../../../services/axiosEnums';
import AdCardDisplayParams from './AdCardDisplayParams';
import AdCard from './adCard/AdCard';
import styles from './adCardContainer.module.scss';
export interface IOnPageChangeProps {
  page: number;
}
interface IAdCardContainerProps {
  ads: IApiState<Ads | null>;
  type: 'ads' | 'activeResearches' | 'adStudies';
  adsPagination: AdPaginationDatas;
  onPaginationChange: (datas: AdPaginationDatas) => void;
  onCheckAd: (ad: Ad) => void;
  onCardClick: (Ad: Ad) => void;
  extendedDisplayParams?: React.ReactNode;
  checkedIds: string[];
}
const AdCardContainer = ({
  ads,
  type,
  onPaginationChange,
  onCheckAd,
  extendedDisplayParams = null,
  checkedIds,
  onCardClick,
  adsPagination,
}: IAdCardContainerProps) => {
  const handlePageChange = (page: number) => {
    onPaginationChange({ ...adsPagination, page });
  };

  const handleDisplayParamsChange = (datas: AdPaginationDatas) => {
    onPaginationChange({ ...adsPagination, ...datas });
  };

  return (
    <div className={styles.adCardContainer}>
      <AdCardDisplayParams
        onDisplayChange={handleDisplayParamsChange}
        displayDatas={adsPagination}
        extendedDisplayParams={extendedDisplayParams}
      />

      {ads.apiStatus === APIStatus.PENDING ? (
        <IndividualCircularLoader size={200} />
      ) : ads.apiStatus === APIStatus.IDLE && ads && ads.result?.length === 0 ? (
        <p className={styles.noContent}>Aucune opportunité Trouvée</p>
      ) : ads.apiStatus === APIStatus.REJECTED ? (
        <div>
          <p className={styles.errorContent}>
            Erreur de chargement des opportunités
            <br />
            Si le problème persiste, merci de nous contacter. <br />
            <br />
            <a
              href="mailto:support@urbanease.io"
              target="_blank"
              rel="nofollow noreferrer"
            >
              Support Prospec-Immo
            </a>
          </p>
        </div>
      ) : (
        <>
          <section className={styles.container}>
            {ads.result?.map((ad) => {
              return (
                <AdCard
                  ad={ad}
                  key={nanoid()}
                  type={type}
                  isNew={false}
                  onCheckAd={() => onCheckAd(ad)}
                  checked={checkedIds.includes(ad.idIri)}
                  onCardClick={() => onCardClick(ad)}
                />
              );
            })}
          </section>
          {adsPagination.totalAds > adsPagination.itemsPerPage && (
            <Pagination
              page={adsPagination.page}
              count={Math.ceil(adsPagination.totalAds / adsPagination.itemsPerPage)}
              onChange={(evt, page) => handlePageChange(page)}
              variant="outlined"
            />
          )}
        </>
      )}
    </div>
  );
};

export default AdCardContainer;
