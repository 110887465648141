import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../App/redux/store';
import { APIStatus } from '../../services/axiosEnums';
import createActiveResearchThunk from './services/thunks/createActiveResearchThunk';
import deleteActiveResearchThunk from './services/thunks/deleteActiveResearchThunk';
import fetchActiveResearchesThunk from './services/thunks/fetchActiveResearchesThunk';
import fetchActiveResearchesTotalAdsThunk, {
  TotalNewAdsThunkResult,
} from './services/thunks/fetchActiveResearchesTotalAdsThunk';
import fetchCurrentActiveResearchThunk from './services/thunks/fetchCurrentActiveResearchThunk';
import updateActiveResearchThunk from './services/thunks/updateActiveResearchThunk';

const initialState: ActiveResearchState = {
  activeResearches: { apiStatus: APIStatus.IDLE, result: null },
  currentActiveResearch: { apiStatus: APIStatus.IDLE, result: null },
};

const activeResearchSlice = createSlice({
  name: 'activeResearch',
  initialState,
  reducers: {
    reset: () => initialState,
    currentActiveResearchReset: (state) => {
      state.currentActiveResearch = {
        apiStatus: APIStatus.IDLE,
        result: null,
        error: null,
      };
    },
  },
  extraReducers(builder) {
    // FETCH ACTIVE RESEARCHES
    builder
      .addCase(fetchActiveResearchesThunk.pending, (state) => {
        state.activeResearches.apiStatus = APIStatus.PENDING;
        state.activeResearches.error = undefined;
      })
      .addCase(
        fetchActiveResearchesThunk.fulfilled,
        (state, action: PayloadAction<ActiveResearches>) => {
          state.activeResearches.apiStatus = APIStatus.IDLE;
          state.activeResearches.result = action.payload;
          state.activeResearches.error = undefined;
        }
      )
      .addCase(fetchActiveResearchesThunk.rejected, (state, action) => {
        state.activeResearches.apiStatus = APIStatus.REJECTED;
        state.activeResearches.error = action.error;
      });
    // FETCH ACTIVE RESEARCHES TOTAL ADS
    builder
      .addCase(fetchActiveResearchesTotalAdsThunk.pending, (state) => {
        state.activeResearches.apiStatus = APIStatus.PENDING;
        state.activeResearches.error = undefined;
      })
      .addCase(
        fetchActiveResearchesTotalAdsThunk.fulfilled,
        (state, action: PayloadAction<TotalNewAdsThunkResult>) => {
          state.activeResearches.apiStatus = APIStatus.IDLE;
          state.activeResearches.error = undefined;
          // add total new ads to ras
          const newRas: ActiveResearches = [];

          state.activeResearches.result?.forEach((e) => {
            const na = action.payload.find((f) => f.id === e.idIri);
            if (na) {
              newRas.push({
                ...e,
                totalAds: na.totalAds,
                totalNewAds: na.totalNewAds,
              });
            }
          });
          state.activeResearches.result = newRas;
        }
      )
      .addCase(fetchActiveResearchesTotalAdsThunk.rejected, (state, action) => {
        state.activeResearches.apiStatus = APIStatus.REJECTED;
        state.activeResearches.error = action.error;
      });
    // FETCH CURRENTACTIVE RESEARCH (details)
    builder
      .addCase(fetchCurrentActiveResearchThunk.pending, (state) => {
        state.currentActiveResearch.apiStatus = APIStatus.PENDING;
        state.currentActiveResearch.error = undefined;
      })
      .addCase(
        fetchCurrentActiveResearchThunk.fulfilled,
        (state, action: PayloadAction<ActiveResearchDetail>) => {
          state.currentActiveResearch.apiStatus = APIStatus.IDLE;
          state.currentActiveResearch.error = undefined;
          state.currentActiveResearch.result = action.payload;
        }
      )
      .addCase(fetchCurrentActiveResearchThunk.rejected, (state, action) => {
        state.currentActiveResearch.apiStatus = APIStatus.REJECTED;
        state.currentActiveResearch.error = action.error;
      });
    // CREATE ACTIVE RESEARCH
    builder
      .addCase(createActiveResearchThunk.pending, (state) => {
        state.activeResearches.apiStatus = APIStatus.PENDING;
        state.activeResearches.error = undefined;
      })
      .addCase(
        createActiveResearchThunk.fulfilled,
        (state, action: PayloadAction<ActiveResearch>) => {
          state.activeResearches.apiStatus = APIStatus.IDLE;
          state.activeResearches.error = undefined;
          state.activeResearches.result = state.activeResearches.result
            ? state.activeResearches.result.concat(action.payload)
            : [action.payload];
        }
      )
      .addCase(createActiveResearchThunk.rejected, (state, action) => {
        state.activeResearches.apiStatus = APIStatus.REJECTED;
        state.activeResearches.error = action.error;
      });
    // UPDATE ACTIVE RESEARCH
    builder
      .addCase(updateActiveResearchThunk.pending, (state) => {
        state.activeResearches.apiStatus = APIStatus.PENDING;
        state.activeResearches.error = undefined;
      })
      .addCase(
        updateActiveResearchThunk.fulfilled,
        (state, action: PayloadAction<ActiveResearch>) => {
          state.activeResearches.apiStatus = APIStatus.IDLE;
          state.activeResearches.error = undefined;
          state.activeResearches.result = state.activeResearches.result
            ? state.activeResearches.result.map((m) =>
                m.idIri === action.payload.idIri ? action.payload : m
              )
            : [action.payload];
        }
      )
      .addCase(updateActiveResearchThunk.rejected, (state, action) => {
        state.activeResearches.apiStatus = APIStatus.REJECTED;
        state.activeResearches.error = action.error;
      });
    // DELETE ACTIVE RESEARCH
    builder
      .addCase(deleteActiveResearchThunk.pending, (state) => {
        state.activeResearches.apiStatus = APIStatus.PENDING;
        state.activeResearches.error = undefined;
      })
      .addCase(
        deleteActiveResearchThunk.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.activeResearches.apiStatus = APIStatus.IDLE;
          state.activeResearches.error = undefined;
          if (state.activeResearches.result) {
            state.activeResearches.result = state.activeResearches.result.filter(
              (f) => f.idIri !== action.payload
            );
          }
        }
      )
      .addCase(deleteActiveResearchThunk.rejected, (state, action) => {
        state.activeResearches.apiStatus = APIStatus.REJECTED;
        state.activeResearches.error = action.error;
      });
  },
});

export default activeResearchSlice.reducer;
export const getActiveResearchState = (state: RootState) => state.activeResearch;
export const activeResearchActions = activeResearchSlice.actions;
