import { call, put, select, take } from '@redux-saga/core/effects';
import {
  createEntity,
  deleteEntity,
  fetchDatas,
  updateEntity,
} from '../../services/axiosInstanceService';
import { appManagerActions } from '../appManager/actions';
import { getAuthState } from '../auth/reducer';
import { REQUEST } from '../common/actions';
import { mapObjectParser } from '../common/parserCommon';
import onErrorLogout from '../common/sagaCommon';
import { loaderActions } from '../loader/actions';
import {
  CREATE_FOLDER,
  DELETE_FOLDER,
  FETCH_FOLDERS,
  foldersActions,
  UPDATE_FOLDER,
} from './actions';
import { folderNotifications } from './notifications';
import { folderApiToStore, folderFormToApi } from './parser';
import { getFoldersState } from './reducer';

// ***************************************************
// *  FETCH FOLDER
// ***************************************************
export function* handleFetchFolders() {
  while (true) {
    try {
      yield take(FETCH_FOLDERS[REQUEST]);
      const { userId } = yield select(getAuthState);

      const response = yield call(fetchDatas, `/users/${userId}/folders`);
      if (response) {
        const parsedResponse = mapObjectParser(
          response['hydra:member'],
          folderApiToStore
        );
        yield put(foldersActions.fetchFolders.success(parsedResponse));
      }
    } catch (error) {
      yield onErrorLogout(error);
      yield put(foldersActions.fetchFolders.failure(error));
    }
  }
}

// ***************************************************
// *  CREATE FOLDER
// ***************************************************
export function* handleCreateFolder() {
  while (true) {
    try {
      const {
        payload: { body, history },
      } = yield take(CREATE_FOLDER[REQUEST]);

      yield put(loaderActions.loaderDisplay.visible());
      const parsedBody = folderFormToApi(body);
      const response = yield call(createEntity, {
        endpoint: '/folders',
        body: parsedBody,
      });
      if (response) {
        const parsedResponse = folderApiToStore(response);

        yield call(history.push, '/mes-dossiers');
        yield put(foldersActions.createFolder.success(parsedResponse));
        yield put(loaderActions.loaderDisplay.hidden());

        yield put(
          appManagerActions.actionNotificationDisplay.visible(
            folderNotifications.create(response.name).success()
          )
        );
      }
    } catch (error) {
      yield onErrorLogout(error);
      yield put(foldersActions.createFolder.failure(error));
      yield put(
        appManagerActions.actionNotificationDisplay.visible(
          folderNotifications.create().failure()
        )
      );
      yield put(loaderActions.loaderDisplay.hidden());
    }
  }
}

// ***************************************************
// *  UPDATE FOLDER
// ***************************************************
export function* handleUpdateFolder() {
  while (true) {
    try {
      const {
        payload: { body, history },
      } = yield take(UPDATE_FOLDER[REQUEST]);
      const parsedBody = folderFormToApi(body);
      yield put(loaderActions.loaderDisplay.visible());
      const response = yield call(updateEntity, {
        idIri: body.idIri,
        body: parsedBody,
      });
      if (response) {
        const parsedResponse = folderApiToStore(response);
        yield put(foldersActions.updateFolder.success(parsedResponse));

        yield call(history.push, '/mes-dossiers');
        yield put(loaderActions.loaderDisplay.hidden());
        yield put(
          appManagerActions.actionNotificationDisplay.visible(
            folderNotifications.update(response.name).success()
          )
        );
      }
    } catch (error) {
      yield onErrorLogout(error);
      yield put(foldersActions.updateFolder.failure(error));
      yield put(
        appManagerActions.actionNotificationDisplay.visible(
          folderNotifications.update().failure()
        )
      );
      yield put(loaderActions.loaderDisplay.hidden());
    }
  }
}

// ***************************************************
// *  DELETE FOLDER
// ***************************************************
export function* handleDeleteFolder() {
  while (true) {
    try {
      const { payload } = yield take(DELETE_FOLDER[REQUEST]);
      const { deleteModal } = yield select(getFoldersState);
      console.log(payload, deleteModal);
      yield put(loaderActions.loaderDisplay.visible());
      const status = yield call(deleteEntity, payload);
      if (status === 204) {
        yield put(
          appManagerActions.actionNotificationDisplay.visible(
            folderNotifications.delete(deleteModal.name).success()
          )
        );
        yield put(foldersActions.deleteFolder.success(payload));
        yield put(loaderActions.loaderDisplay.hidden());
      }
    } catch (error) {
      yield onErrorLogout(error);
      yield put(foldersActions.deleteFolder.failure(error));
      yield put(
        appManagerActions.actionNotificationDisplay.visible(
          folderNotifications.delete().failure()
        )
      );
      yield put(loaderActions.loaderDisplay.hidden());
    }
  }
}
