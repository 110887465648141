import { projectCollaboratorsInitialValues } from '../../components/AdStudy/form';
import { adApiToStoreParser } from '../../features/ad/adParser';
import { procedureEventApiToStore } from '../../features/procedureEvent/parser';
import { cityApiToStore } from '../../utils/cityParser';
import {
  changeApiKeyForStoreKey,
  changeStoreKeyForApiKey,
  emptyKeyRemove,
  objectKeyGenericMaping,
} from '../common/parserCommon';
import { propertyTypologyApiToStoreParser } from '../propertyTypologies/parser';

const adStudyShortMapping = {
  '@id': 'idIri',
  ad: 'ad',
  status: 'status',
};

const adStudyMapping = {
  '@id': 'idIri',
  ad: 'ad',
  address: 'address',
  building_plot: 'buildingPlot',
  built_area: 'builtArea',
  city: 'city',
  comment: 'comment',
  folder: 'folder',
  land_area: 'landArea',
  lup: 'plu',
  participants: 'projectCollaborators',
  price: 'price',
  property_typology: 'propertyTypology',
  sales_agent_contact: 'salesAgentContact',
  seller_contact: 'sellerContact',
  status: 'status',
  procedure_events: 'demarches',
};

const sellerContactMapping = {
  full_name: 'sellerName',
  phone_number: 'sellerPhone',
  email: 'sellerMail',
  infos: 'sellerInfo',
};

const salesAgentContactMapping = {
  full_name: 'salesAgentName',
  phone_number: 'salesAgentPhone',
  email: 'salesAgentMail',
  infos: 'salesAgentInfo',
};

const pluMapping = {
  zone: 'pluZone',
  cos: 'pluCos',
  ces: 'pluCes',
  max_height: 'pluHightMax',
  social_quota: 'pluSocialQuota',
  parking: 'pluParking',
  parcel_ref: 'parcel',
  floor: 'areaFloor',
};

const buildingPlotMapping = {
  is_buildable: 'isBuildable',
  comment: 'comment',
};

const parseParticipantsFormToApi = (data) => {
  return [
    {
      full_name: data.projectArchitect,
      email: data.architectEmail,
      phone_number: data.architectPhone,
      type: 'architect',
    },
    {
      full_name: data.projectNotary,
      email: data.notaryEmail,
      phone_number: data.notaryPhone,
      type: 'notary',
    },
    {
      full_name: data.projectSurveyor,
      email: data.surveyorEmail,
      phone_number: data.surveyorPhone,
      type: 'surveyor',
    },
  ];
};

const parseParticipantsApiToStore = (collaborators) => {
  const order = ['architect', 'notary', 'surveyor'];
  let parsedCollaborators = { ...projectCollaboratorsInitialValues };

  const switchCollaborator = (p) => {
    switch (p.type) {
      case 'architect':
        parsedCollaborators.projectArchitect = p.full_name;
        parsedCollaborators.architectPhone = p.phone_number;
        parsedCollaborators.architectEmail = p.email;
        break;
      case 'notary':
        parsedCollaborators.projectNotary = p.full_name;
        parsedCollaborators.notaryPhone = p.phone_number;
        parsedCollaborators.notaryEmail = p.email;
        break;
      case 'surveyor':
        parsedCollaborators.projectSurveyor = p.full_name;
        parsedCollaborators.surveyorPhone = p.phone_number;
        parsedCollaborators.surveyorEmail = p.email;
        break;
      default:
        break;
    }
  };

  order.forEach((elmt) => {
    const participant = collaborators.find((p) => p.type === elmt);

    if (participant) {
      switchCollaborator(participant, parsedCollaborators);
    }
  });

  return parsedCollaborators;
};

// ******************************************************
//    API to Store ad-studies general parser
// ******************************************************
export const adStudyShortApiToStore = (data) => {
  const parsedData = changeApiKeyForStoreKey(data, adStudyShortMapping);
  parsedData.ad = adApiToStoreParser(data.ad);
  parsedData.ad.adStudyIdIri = parsedData.idIri;

  return parsedData;
};
// ******************************************************
//    API to Store Detail general parser
// ******************************************************
export const adStudyApiToStore = (data) => {
  // const { base } = objectKeyGenericMaping(adStudyMapping);
  const parsedData = changeApiKeyForStoreKey(data, adStudyMapping);
  parsedData.ad = adApiToStoreParser(parsedData.ad);
  parsedData.id = parsedData.idIri.split('/')[2];

  // localisation parser
  parsedData.localisation = {
    address: parsedData.address ?? parsedData.address ?? '',
    propertyTypology: propertyTypologyApiToStoreParser(parsedData.propertyTypology),
    city: cityApiToStore(parsedData.city),
    builtArea: parsedData.builtArea ?? parsedData.ad.builtArea,
    landArea: parsedData.landArea ?? parsedData.ad.landArea,
    price: parsedData.price ?? parsedData.ad.price,
  };

  delete parsedData.address;
  delete parsedData.propertyTypology;
  delete parsedData.city;
  delete parsedData.landArea;
  delete parsedData.builtArea;
  delete parsedData.price;

  // sellerContact parser
  parsedData.sellerContact = data.seller_contact
    ? changeApiKeyForStoreKey(data.seller_contact, sellerContactMapping)
    : null;

  // salesAgentContact parser
  parsedData.salesAgentContact = data.sales_agent_contact
    ? changeApiKeyForStoreKey(data.sales_agent_contact, salesAgentContactMapping)
    : null;

  // projectCollaborators parser
  parsedData.projectCollaborators = data.participants
    ? parseParticipantsApiToStore(data.participants)
    : [];

  // plu parser
  parsedData.plu = data.lup ? changeApiKeyForStoreKey(data.lup, pluMapping) : null;

  // buildingPlot parser
  parsedData.buildingPlot = data.building_plot
    ? changeApiKeyForStoreKey(data.building_plot, buildingPlotMapping)
    : null;

  parsedData.demarches = data.procedure_events
    ? data.procedure_events.map((elmt) => procedureEventApiToStore(elmt))
    : [];

  // comment parser
  parsedData.comment = data.comment ?? '';

  return parsedData;
};

// ******************************************************
//    Form to API Detail general parser
// ******************************************************
export const adStudyFormToApi = (data, type) => {
  const { base } = objectKeyGenericMaping(adStudyMapping);
  let tempData = emptyKeyRemove(data);

  switch (type) {
    case 'localisation':
      tempData = {
        ...tempData,
        city: data.city.idIri,
        propertyTypology: data.propertyTypology.idIri,
      };
      break;
    case 'sellerContact':
      tempData = {
        sellerContact: {
          ...changeStoreKeyForApiKey(tempData, sellerContactMapping),
        },
      };
      break;
    case 'salesAgentContact':
      tempData = {
        salesAgentContact: {
          ...changeStoreKeyForApiKey(tempData, salesAgentContactMapping),
        },
      };
      break;
    case 'projectCollaborators':
      tempData = {
        projectCollaborators: parseParticipantsFormToApi(tempData),
      };
      break;
    case 'plu':
      tempData = {
        plu: {
          ...changeStoreKeyForApiKey(tempData, pluMapping),
        },
      };
      break;
    case 'buildingPlot':
      tempData = {
        buildingPlot: {
          ...changeStoreKeyForApiKey(tempData, buildingPlotMapping),
        },
      };
      break;
    default:
      break;
  }

  return changeStoreKeyForApiKey(tempData, base);
};

// ******************************************************
//    MOVE Ad STUDIES PARSER
// ******************************************************
export const moveAdStudiesBodyParser = (data) => {
  const body = { 'ad-studies': [] };

  data.forEach((elmt) => {
    body['ad-studies'].push(elmt);
  });

  return body;
};
