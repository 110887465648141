import { createAsyncThunk } from '@reduxjs/toolkit';
import fetchAd from '../fetchAd';

const fetchAdThunk = createAsyncThunk(
  'ad/fetchAdThunk',
  async (params: { idIri: string }) => {
    try {
      const result = await fetchAd({ idIri: params.idIri });

      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export default fetchAdThunk;
