import { memo } from 'react';
import { SourceTypeEnum } from '../../App/contexts/marketStudyContext';
import { GoodTypeEnum } from '../../pages/MarketStudiesResultPage';
import GoodGraph from './GoodGraph';

import GoodStat from './GoodStat';
import MarketStudyResultComment from './MarketStudyResultComment';

interface IGoodProps {
  goodType: GoodTypeEnum;
  partialLabel: string;
  id: string;
}
const Good = ({ goodType, partialLabel, id }: IGoodProps) => {
  return (
    <div className="market-studies-result-prices-type">
      <div className="market-studies-result-prices-data">
        <div className="market-studies-result-prices-data">
          <GoodStat type={goodType} partialLabel={partialLabel} id={id} />
        </div>
      </div>
      <div className="market-studies-result-charts">
        <GoodGraph
          type={goodType}
          roomGround={partialLabel}
          valueType={SourceTypeEnum.AVERAGE}
          id={id}
        >
          Prix moyen
        </GoodGraph>

        <GoodGraph
          type={goodType}
          roomGround={partialLabel}
          valueType={SourceTypeEnum.SQUARE_METER}
          id={id}
        >
          Prix moyen/m²
        </GoodGraph>

        <MarketStudyResultComment name={`comment_${partialLabel}`} />
      </div>
    </div>
  );
};

export default memo(Good);
