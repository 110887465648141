import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ActionNotification from '../components/Assets/ActionNotification';
import SectionTitle from '../components/Assets/sectionTitle';

import AdCardContainer from '../features/ad/components/adCardContainer/AdCardContainer';
import useAds from '../features/ad/useAds';

import { adActions } from '../features/ad/adSlice';
import InfoBox from '../features/piecework/components/pieceworkPage/InfoBox';
import { authActions } from '../store/auth/actions';
import './styles/pieceworkPage.scss';

function Piecework() {
  const {
    ads,
    selectedAds,
    adPagination,
    addItemToSelectedAd,
    loadActiveAd,
    handlePaginationChange,
  } = useAds({});
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(authActions.newAdsCount.reset());
      dispatch(adActions.reset());
    };
  }, []);

  return (
    <main className="piecework">
      <ActionNotification />
      <header className="piecework-header">
        <SectionTitle
          mainTitle="Pige classique"
          subTitle="Visualisez et sélectionnez vos annonces"
        />
        <InfoBox />
      </header>

      <AdCardContainer
        ads={ads}
        type="ads"
        onPaginationChange={handlePaginationChange}
        onCheckAd={addItemToSelectedAd}
        onCardClick={loadActiveAd}
        checkedIds={selectedAds.map((m) => m.idIri)}
        adsPagination={adPagination}
      />
    </main>
  );
}

export default Piecework;
