import { useSelector } from 'react-redux';
import { accountTabsArray } from '../components/Account/AccountTabs/accountTabsArray';
import DetailsTab from '../components/Account/DetailsTab';
import PasswordTab from '../components/Account/PasswordTab';
import RegistrationTab from '../components/Account/RegistrationTab';
import StatisitcTab from '../components/Account/StatisticTab';
import TeamTab from '../components/Account/TeamTab';
import ActionNotification from '../components/Assets/ActionNotification';
import SectionTitle from '../components/Assets/sectionTitle';
import TabContainer from '../components/Assets/TabContainer';
import PigeParametersTab from '../features/piecework/components/pieceworkParamsAccountTab/PigeParametersTab';
import { getSelectedAccountTabState } from '../store/appManager/reducer';
import './styles/MyAccount.scss';

const AccountPAge = () => {
  const selectedAccountTab = useSelector(getSelectedAccountTabState);

  return (
    <>
      <SectionTitle
        mainTitle={`Mon compte`}
        subTitle="Mettez à jour les informations de votre profil"
      />
      <ActionNotification />
      <div className="my-account">
        <TabContainer tabArray={accountTabsArray} type="account" />
        {selectedAccountTab === 0 && <DetailsTab />}
        {selectedAccountTab === 1 && <PasswordTab />}
        {selectedAccountTab === 2 && <PigeParametersTab />}
        {selectedAccountTab === 3 && <TeamTab />}
        {selectedAccountTab === 4 && <RegistrationTab />}
        {selectedAccountTab === 5 && <StatisitcTab />}
      </div>
    </>
  );
};

export default AccountPAge;
