import { RESET, SET, SUCCESS } from '../common/actions';
import { FETCH_AD_STUDY, FORM_TOUCH_STATES, SELECTED_AD_STUDY } from './actions';

const initialState = {
  activeAdStudy: {},
  formTouchStates: {
    localisationForm: false,
    sellerContactForm: false,
    salesAgentContactForm: false,
    projectCollaboratorsForm: false,
    PLUForm: false,
    buildingPlotForm: false,
    commentForm: false,
    demarcheForm: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_AD_STUDY[SUCCESS]:
      state.activeAdStudy = { ...payload };
      delete state.activeAdStudy.demarches;
      return { ...state };
    case SELECTED_AD_STUDY[SET]:
      return { ...state, activeAdStudy: payload };
    case SELECTED_AD_STUDY[RESET]:
      return { ...state, activeAdStudy: null };
    case FORM_TOUCH_STATES[SET]:
      return {
        ...state,
        formTouchStates: { ...state.formTouchStates, ...payload },
      };
    default:
      return state;
  }
};

export const getAdStudiesState = (state) => state.adStudies;
