import IndividualCircularLoader from '../../Loader/IndividualCircularLoader';
import ComparisonsContent from './ComparisonsContent';
import ComparisonsTab from './ComparisonsTab';

import { nanoid } from '@reduxjs/toolkit';
import useComparativeStudyComparisons from '../../../App/hooks/useComparativeStudyComparisons';
import { priceComparisonsTabs } from '../init';

const ComparisonsComponent = () => {
  const { comparisonsResume, comparisonsActiveTab } = useComparativeStudyComparisons(
    null,
    true
  );
  return (
    <>
      {!comparisonsResume ? (
        <IndividualCircularLoader size={100} />
      ) : (
        <>
          <p className="comparative-studies-details-section-message">
            Sélectionnez un maximum de 3 annonces par catégorie de prix
          </p>
          <div className="comparative-studies-details-tabs">
            {priceComparisonsTabs.map((tab) => (
              <ComparisonsTab
                key={nanoid()}
                property={tab.qtyKey}
                label={tab.label}
                comparisonsResume={comparisonsResume}
                comparisonsActiveTab={comparisonsActiveTab}
              />
            ))}
          </div>
          <div className="comparative-studies-details-table-wrapper">
            <ComparisonsContent count={comparisonsResume[comparisonsActiveTab]} />
          </div>
        </>
      )}
    </>
  );
};

export default ComparisonsComponent;
