import * as L from 'leaflet';
import { Marker, Popup } from 'react-leaflet';
import { euroFormat } from '../../utils/jsFunctions';

const adIcon = L.icon({ iconUrl: '/images/marker-icon-red.png' });

interface IAdMarker {
  lat: number;
  lng: number;
  typologyName: string;
  totalRoom?: number;
  price: number | null;
}

const AdMarker = ({ lat, lng, totalRoom, typologyName, price }: IAdMarker) => {
  return (
    <>
      {lat && lng && (
        <Marker position={[lat, lng]} icon={adIcon}>
          <Popup>
            <div>
              <h3>{`${typologyName} ${totalRoom ? 'F' + totalRoom : ''}`}</h3>
              {price ? `${euroFormat(price)} €` : 'Indéfini'}
            </div>
          </Popup>
        </Marker>
      )}
    </>
  );
};

export default AdMarker;
