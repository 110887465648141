import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import useAuth from '../App/hooks/useAuth';
import routes from './routes';

const AuthRoutes = () => {
  const { isLogged } = useAuth();
  const match = useRouteMatch();
  const { pathname } = useLocation();

  if (!isLogged) {
    return <Redirect to={{ pathname: '/inscription' }} />;
  }

  if (isLogged && match.isExact) {
    return <Redirect to={{ pathname: '/dashboard' }} />;
  }

  if (!routes.find((elmt) => elmt.path.split('/')[1] === pathname.split('/')[1])) {
    return <Redirect to={{ pathname: '/erreur' }} />;
  }

  return (
    <Switch>
      {routes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          exact={route.exact}
          component={route.Component}
        />
      ))}
    </Switch>
  );
};

export default AuthRoutes;
