import { ReactComponent as CheckViolet } from '../../../../../images/check-violet.svg';

import Badge from '../../../../../components/badge';
import {
  BadgeBackgroundColorEnum,
  BadgeContentEnum,
  BadgeTextColorEnum,
} from '../../../../../components/badge/enums';

import './adCard.scss';

enum SellerTypeEnum {
  TOUS,
  PARTICULIER,
  PRO,
  ENCHERES,
  ETAT,
  AUTRES,
}

const sellerTypeDisplay = (sellerType: SellerTypeEnum) => {
  let badgeContent = BadgeContentEnum.AUTRE;
  let bgColor = BadgeBackgroundColorEnum.VIOLET;

  switch (sellerType) {
    case SellerTypeEnum.TOUS:
      badgeContent = BadgeContentEnum.TOUS;
      bgColor = BadgeBackgroundColorEnum.BLUE;
      break;
    case SellerTypeEnum.PARTICULIER:
      badgeContent = BadgeContentEnum.PARTICULIER;
      bgColor = BadgeBackgroundColorEnum.BLUE;
      break;
    case SellerTypeEnum.PRO:
      badgeContent = BadgeContentEnum.PRO;
      break;
    case SellerTypeEnum.ENCHERES:
      badgeContent = BadgeContentEnum.ENCHERES;
      break;
    case SellerTypeEnum.ETAT:
      badgeContent = BadgeContentEnum.ETAT;
      break;
    default:
  }

  return (
    <Badge
      badgeContent={badgeContent}
      bgColor={bgColor}
      textColor={BadgeTextColorEnum.WHITE}
    />
  );
};

interface IThumbHeaderPRops {
  sellerType: SellerTypeEnum;
  status: number | null;
  ad: Ad;
  isNew?: boolean;
  onCheckAd: () => void;
  checked: boolean;
}
const AdCardHeader = ({
  sellerType,
  status,
  ad,
  isNew,
  onCheckAd,
  checked = false,
}: IThumbHeaderPRops) => {
  return (
    <header className="ad-thumb-header">
      <div className="ad-thumb-header-elt">
        <div className="ad-thumb-check" onClick={onCheckAd}>
          {checked && <CheckViolet />}
        </div>
        {isNew && (
          <Badge
            badgeContent={BadgeContentEnum.NOUVEAU}
            bgColor={BadgeBackgroundColorEnum.RED}
            textColor={BadgeTextColorEnum.WHITE}
          />
        )}
        {status !== null && (
          <div className="ad-thumb-top-status">
            {status === 1 ? (
              <>
                <div className="ad-thumb-top-status-on" />
                <span className="ad-thumb-top-status-on-text">En ligne</span>
              </>
            ) : (
              <>
                <div className="ad-thumb-top-status-off" />
                <span className="ad-thumb-top-status-off-text">Hors ligne</span>
              </>
            )}
          </div>
        )}
        {sellerTypeDisplay(sellerType)}
      </div>
    </header>
  );
};

export default AdCardHeader;
