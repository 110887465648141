import axios from 'axios';
import store, { RootState } from '../../App/redux/store';
const API_DNS = process.env.REACT_APP_API_URL;

export const fetchUrbaAuthenticationUrl = async (
  lng?: number | null,
  lat?: number | null,
  department?: string | null
) => {
  try {
    const state = store.getState() as RootState;
    const token = state.auth.token;

    const result: any = await axios.get(
      API_DNS + '/get_a_login_url_from_urbanease',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { latitude: lat, longitude: lng, departement_code: department },
      }
    );
    return result.data;
  } catch (error: any) {
    if (error.response) {
      return Promise.reject({
        status: error.response.status,
        message: error.response.data,
      });
    }
  }
};
