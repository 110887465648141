import PropTypes from 'prop-types';

import useComparativeStudyComparisons from '../../../App/hooks/useComparativeStudyComparisons';
import PdfComparativeStudies from '../../../utils/pdfComparativeStudies';
import PDFDownload from '../../Assets/PDFDownload';

function ComparativeStudyPdf({ adComparisonsSelected, comparativeStudyContent }) {
  const { comparisonsResume } = useComparativeStudyComparisons();

  return (
    <PDFDownload
      pdf={
        <PdfComparativeStudies
          comparativeStudyContent={comparativeStudyContent}
          adComparisonsSelected={adComparisonsSelected}
          comparisonsResume={comparisonsResume}
        />
      }
      pdfName="etudes-comparatives.pdf"
    />
  );
}

ComparativeStudyPdf.propTypes = {
  adComparisonsSelected: PropTypes.object.isRequired,
  comparativeStudyContent: PropTypes.object.isRequired,
};

export default ComparativeStudyPdf;
