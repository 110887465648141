import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { foldersActions } from '../../store/folders/actions';
import { modalsActions } from '../../store/modals/actions';
import MyFolderFormComponent from '../MyFolders/MyFolderFormComponent';

import { useHistory } from 'react-router-dom';
import './styles/EditFolders.scss';

function EditFolders() {
  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(foldersActions.setActiveFolder.reset());
    };
  }, []);

  const handleCancel = () => {
    dispatch(modalsActions.modifyFolders.close());
    history.push('/mes-dossiers');
  };

  return (
    <div className="edit-folders">
      <div className="edit-folders-modal">
        <h3 className="edit-folders-title">Modifier le dossier</h3>
        <MyFolderFormComponent handleCancel={handleCancel} isModal={true} />
      </div>
    </div>
  );
}

export default EditFolders;
