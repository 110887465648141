import { isEmpty } from 'lodash';
import getIdFromIdIri from '../../shared/parsers/getIdFromIdIri';
import { propertyTypologyApiToStoreParser } from '../../store/propertyTypologies/parser';
import { cityApiToStoreForMap } from '../../utils/cityParser';

/**
 * single source api store parser
 * @param {any} data - api data response
 * @returns {Ad} - parsed source
 */
const adSourcesApiToStore = (data: any) => {
  const parsedData = {
    idIri: data['@id'] ?? null,
    name: data.name ?? 'Indéfini',
    label: data.label ?? '',
    url: data.url ?? '',
  };

  return parsedData;
};
/**
 * single ad api store parser
 * @param {any} data - api data response
 * @param {boolean} isAdStudy - true if data is adStudy api entity
 * @returns {Ad} - parsed Ad
 */
export function adApiToStoreParser(d: any, isAdStudy?: boolean): Ad {
  // check if is it a study or an ad to parse
  const data = isAdStudy ? d.ad : d;

  // parse ad city
  const city = cityApiToStoreForMap(data.city);

  // parse property typology
  const propertyTypology = propertyTypologyApiToStoreParser(data.property_typology);

  // parse lat lng to ad coordinates or null
  const coordinates: AdCoordinates | null =
    data.latitude && data.longitude
      ? { lat: data.latitude, lng: data.longitude }
      : null;

  // parse ad studies id related to this ad
  const studied =
    data.customized_data?.studied_by?.map((m: any) => {
      if (typeof m === 'string') {
        return { studyIdIri: m, adIdIri: null, id: getIdFromIdIri(m), status: null };
      } else {
        return {
          studyIdIri: m['@id'],
          adIdIri: m.ad,
          id: getIdFromIdIri(m['@id']),
          status: m.status,
        };
      }
    }) ?? [];

  // parse ad source
  const source = data.source ? adSourcesApiToStore(data.source) : null;

  // parse similaries
  const children = isEmpty(data.children) ? [] : adsApiToStoreParser(data.children);

  // if ad study, parse study informations here
  const adStudy = isAdStudy ? { idIri: d['@id'], id: d.id, status: d.status } : null;

  // parse Ad with data and parsed elements
  const parsedData: Ad = {
    idIri: data['@id'],
    adStudy,
    activeResearchName: data.active_research_name ?? null,
    children,
    department: data.departement_code ?? null,
    url: data.url ?? '',
    price: data.price ?? null,
    sqmPrice: data.sqm_price,
    seller: {
      type: data.seller_type,
      name: data.seller_name ?? null,
      phone: data.seller_phone ?? null,
    },
    builtArea: data.built_area,
    landArea: data.land_area,
    totalRoom: data.total_room,
    picture: data.picture ?? null,
    coordinates,
    description: data.description,
    city,
    source,
    propertyTypology,
    publishedAt: data.published_at,
    status: data.status,
    ges: data.ghg_rank ?? null,
    dpe: data.energy_rank ?? null,
    isExclusive: data.is_exclusive,
    isBargain: data.is_bargain,
    isGeolocatable: data.is_geolocatable,
    studied,
    title: data.title ?? 'Indéfini',
    oldPrices: data.old_prices ?? null,
  };

  // return parsed data
  return parsedData;
}

/**
 * multi ad api store parser
 * @param {any[]} datas - api data response (array)
 * @param {boolean} isAdStudies - true if datas are adStudies api entities
 * @returns {Ads} - parsed Ads
 */
export function adsApiToStoreParser(datas: any[], isAdStudies?: boolean): Ads {
  return datas.map((m) => adApiToStoreParser(m, isAdStudies));
}

/**
 * single map data for similary plot to submit parser
 * @param {any} datas - api data response
 * @returns {AdPlot} - parsed adPlot
 */
export function adPlotApiToStoreParser(data: any): AdPlot {
  const parsed = {
    area: data.area,
    build: data.build,
    address: data.address,
    coordinates: { lat: data.lat, lng: data.lng },
  };

  return parsed;
}

/**
 * multi map data for similary plot to submit parser
 * @param {any[]} datas - api data response
 * @returns {AdPlot} - parsed adPlots
 */
export function adPlotsApiToStoreParser(datas: any[]): AdPlots {
  return datas.map((m) => adPlotApiToStoreParser(m));
}
