import { call, put, take } from 'redux-saga/effects';
import { REQUEST } from '../common/actions';
import onErrorLogout from '../common/sagaCommon';
import { loaderActions } from '../loader/actions';
import { FETCH_PROPERTY_TYPOLOGY, propertyTypologyActions } from './actions';
import { getPropertyTypologies } from './api';
import { propertyTypologiesApiToStoreParser } from './parser';

export function* handleFetchPropertyTypologies() {
  while (true) {
    try {
      yield take(FETCH_PROPERTY_TYPOLOGY[REQUEST]);
      yield put(loaderActions.loaderDisplay.visible());

      const response = yield call(getPropertyTypologies);
      if (response) {
        const parsedResponse = propertyTypologiesApiToStoreParser(
          response['hydra:member']
        );

        yield put(
          propertyTypologyActions.fetchPropertyTypologies.success(parsedResponse)
        );
        yield put(loaderActions.loaderDisplay.hidden());
      }
    } catch (error) {
      yield onErrorLogout(error);
      propertyTypologyActions.fetchPropertyTypologies.failure(error);
      yield put(loaderActions.loaderDisplay.hidden());
    }
  }
}
