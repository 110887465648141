import { nanoid } from '@reduxjs/toolkit';
import PlotMarker from './PlotMarker';

interface PlotMarkerContainerProps {
  adPlots: AdPlots | null;
}
const PlotMarkerContainer = ({ adPlots }: PlotMarkerContainerProps) => {
  return (
    <>
      {adPlots?.map((plot) => (
        <PlotMarker
          key={nanoid()}
          lat={plot.coordinates.lat}
          lng={plot.coordinates.lng}
          address={plot.address}
        />
      ))}
    </>
  );
};

export default PlotMarkerContainer;
