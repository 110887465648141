import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../App/redux/store';
import { updateEntity } from '../../../services/axiosInstanceService';
import { appManagerActions } from '../../../store/appManager/actions';
import { citiesApiToStoreParser } from '../../../utils/cityParser';
import { pigeParamsNotifications } from '../notifications';

const updateMarketResearchCitiesThunk = createAsyncThunk(
  'piecework/updateMarketResearchThunk',
  async (params: { idIrisProps: string[] }, { getState, dispatch }) => {
    try {
      // store datas
      const state = getState() as RootState;
      const { marketResearch } = state.piecework;

      // check values before request
      if (!params.idIrisProps) throw new Error('No city idIri found');
      if (!marketResearch.idIri) throw new Error('No market research idIri found');

      // create idIri array of existing city
      let cityIdIris = marketResearch.cities?.map((m) => m.idIri) ?? [];

      // variable for good notification
      let action = '';

      // create body for request
      params.idIrisProps.forEach((e) => {
        if (cityIdIris.includes(e)) {
          // if delete city
          cityIdIris = cityIdIris.filter((f) => f !== e);
          action = 'delete';
        } else {
          // if add city
          cityIdIris.push(e);
          action = 'update';
        }
      });

      // marketResearch request
      const response = await updateEntity({
        idIri: marketResearch.idIri,
        body: { cities: cityIdIris },
      });

      if (response.cities) {
        // if datas parse resposne
        const parsedResponse = citiesApiToStoreParser(response.cities);

        // display update or delete notification
        if (action === 'update')
          dispatch(
            appManagerActions.actionNotificationDisplay.visible(
              pigeParamsNotifications.update('ville(s)').success()
            )
          );
        if (action === 'delete')
          dispatch(
            appManagerActions.actionNotificationDisplay.visible(
              pigeParamsNotifications.delete('ville(s)').success()
            )
          );

        // return response
        return parsedResponse;
      }

      throw new Error('no cities array returned');
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export default updateMarketResearchCitiesThunk;
