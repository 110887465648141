import { CREATE_AD_STUDIES, MOVE_AD_STUDIES } from '../adStudies/actions';
import { LOGOUT } from '../auth/actions';
import { CLOSE, MODAL_DISPLAY, OPEN, RESET, SET, SUCCESS } from '../common/actions';
import {
  DELETE_COMPARATIVE_STUDY,
  SET_ACTIVE_COMPARATIVE_STUDY,
} from '../comparativeStudies/actions';
import { DELETE_FOLDER, UPDATE_FOLDER } from '../folders/actions';
import { DELETE_MARKET_STUDY } from '../marketStudies/actions';
import { ASK_FOR_SUBSCRIPTION_UPDATE } from '../subscription/actions';
import {
  DELETE_ADS,
  MODAL_AD_DETAILS,
  MODAL_CHOOSE_FOLDER,
  MODAL_COMPARATIVE_STUDIES,
  MODAL_CONFIRMATION,
  MODAL_ERROR_PIECEWORK,
  MODAL_MOBILE_NAV,
  MODAL_MODIFY_FOLDERS,
  MODAL_NEW_COLLABORATOR,
  MODAL_PROFILE_MENU,
  MODAL_SUBSCRIPTION,
  MODAL_SUBSCRIPTION_SUMMARY,
  MODAL_USER_MOBILE_TABS,
  MODALS,
  NO_URBANEASE_ACCOUNT,
  NOTIFICATION_BELL,
} from './actions';

const initialState = {
  adDetails: false,
  modifyFolder: false,
  newCollaborator: false,
  subscription: false,
  subscriptionSummary: false,
  confirmation: false,
  mobileNav: false,
  profileMenu: false,
  chooseFolder: false,
  userMobileTabs: false,
  isOverlay: false,
  deleteFolder: false,
  deleteMarketStudy: false,
  comparativeStudy: false,
  deleteComparativeStudy: false,
  deleteAds: false,
  errorPiecework: false,
  isLateralOpen: false,
  notificationBell: false,
  noUrbaneaseAccount: { status: false, error: null },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case MODAL_AD_DETAILS[OPEN]:
      return { ...state, adDetails: true, isLateralOpen: true };
    case MODAL_AD_DETAILS[CLOSE]:
      return { ...state, adDetails: false, isLateralOpen: false };
    case MODAL_MOBILE_NAV[OPEN]:
      return { ...state, mobileNav: true };
    case MODAL_MOBILE_NAV[CLOSE]:
      return { ...state, mobileNav: false };
    case MODAL_PROFILE_MENU[SET]:
      return { ...state, profileMenu: payload };
    case MODAL_CHOOSE_FOLDER[OPEN]:
      return { ...state, chooseFolder: true, isOverlay: true };
    case MODAL_CHOOSE_FOLDER[CLOSE]:
      return { ...state, chooseFolder: false, isOverlay: false };

    case MODAL_MODIFY_FOLDERS[OPEN]:
      return { ...state, modifyFolder: true };
    case UPDATE_FOLDER[SUCCESS]:
    case MODAL_MODIFY_FOLDERS[CLOSE]:
      return { ...state, modifyFolder: false };
    case MODAL_NEW_COLLABORATOR[OPEN]:
      return { ...state, newCollaborator: true };
    case MODAL_NEW_COLLABORATOR[CLOSE]:
      return { ...state, newCollaborator: false };

    case NOTIFICATION_BELL[OPEN]:
      return { ...state, notificationBell: true, isOverlay: true };
    case NOTIFICATION_BELL[CLOSE]:
      return { ...state, notificationBell: false, isOverlay: false };

    case SET_ACTIVE_COMPARATIVE_STUDY[SET]:
      return { ...state, comparativeStudy: true, isLateralOpen: true };
    case MODAL_COMPARATIVE_STUDIES[CLOSE]:
      return { ...state, comparativeStudy: false, isLateralOpen: false };

    case MODAL_SUBSCRIPTION[OPEN]:
      return { ...state, subscription: true };
    case MODAL_SUBSCRIPTION[CLOSE]:
      return { ...state, subscription: false };
    case ASK_FOR_SUBSCRIPTION_UPDATE[SUCCESS]:
      return {
        ...state,
        subscription: false,
        subscriptionSummary: true,
        isOverlay: true,
      };
    case MODAL_SUBSCRIPTION_SUMMARY[CLOSE]:
    case ASK_FOR_SUBSCRIPTION_UPDATE[RESET]:
      return { ...state, subscriptionSummary: false, isOverlay: false };

    case MODAL_CONFIRMATION[OPEN]:
      return { ...state, confirmation: true };
    case MODAL_CONFIRMATION[CLOSE]:
      return { ...state, confirmation: false };
    case MODAL_USER_MOBILE_TABS[OPEN]:
      return { ...state, userMobileTabs: true };
    case MODAL_USER_MOBILE_TABS[CLOSE]:
      return { ...state, userMobileTabs: false };

    case MOVE_AD_STUDIES[MODAL_DISPLAY]:
    case CREATE_AD_STUDIES[MODAL_DISPLAY]:
      return { ...state, chooseFolder: true, isOverlay: true };
    case CREATE_AD_STUDIES[RESET]:
    case CREATE_AD_STUDIES[SUCCESS]:
    case MOVE_AD_STUDIES[SUCCESS]:
      return { ...state, chooseFolder: false, isOverlay: false, adDetails: false };

    case DELETE_FOLDER[MODAL_DISPLAY]:
      return { ...state, deleteFolder: true };
    case DELETE_FOLDER[SUCCESS]:
    case DELETE_FOLDER[RESET]:
      return { ...state, deleteFolder: false };

    case DELETE_MARKET_STUDY[MODAL_DISPLAY]:
      return { ...state, deleteMarketStudy: true };
    case DELETE_MARKET_STUDY[SUCCESS]:
    case DELETE_MARKET_STUDY[RESET]:
      return { ...state, deleteMarketStudy: false };

    case DELETE_COMPARATIVE_STUDY[MODAL_DISPLAY]:
      return { ...state, deleteComparativeStudy: true };
    case DELETE_COMPARATIVE_STUDY[SUCCESS]:
    case DELETE_COMPARATIVE_STUDY[RESET]:
      return { ...state, deleteComparativeStudy: false };

    case DELETE_ADS[OPEN]:
      return { ...state, deleteAds: true };
    case DELETE_ADS[CLOSE]:
      return {
        ...state,
        deleteAds: false,
        adDetails: false,
      };

    case MODAL_ERROR_PIECEWORK[OPEN]:
      return { ...state, errorPiecework: true };
    case MODAL_ERROR_PIECEWORK[CLOSE]:
      return { ...state, errorPiecework: false };

    case NO_URBANEASE_ACCOUNT[OPEN]:
      return {
        ...state,
        noUrbaneaseAccount: { status: true, error: payload },
      };
    case NO_URBANEASE_ACCOUNT[CLOSE]:
      return { ...state, noUrbaneaseAccount: false };

    case MODALS[CLOSE]:
      return { ...initialState };
    case LOGOUT[SUCCESS]:
      return { ...initialState };
    default:
      return state;
  }
};

export const getModalState = (state) => state.modals;
