import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteEntity } from '../../../../services/axiosInstanceService';
import { appManagerActions } from '../../../../store/appManager/actions';
import { loaderActions } from '../../../../store/loader/actions';
import { activeSearchesNotifications } from '../../notifications';

const deleteActiveResearchThunk = createAsyncThunk(
  'activeResearch/deleteActiveResearchThunk',
  async (params: { idIri: string }, { dispatch }) => {
    try {
      const { idIri } = params;
      dispatch(loaderActions.loaderDisplay.visible());
      const result = await deleteEntity(idIri);

      if (result === 204) {
        dispatch(loaderActions.loaderDisplay.hidden());
        dispatch(
          appManagerActions.actionNotificationDisplay.visible(
            activeSearchesNotifications.delete().success()
          )
        );
        return idIri;
      } else {
        throw new Error('Bad request response status');
      }
    } catch (error) {
      dispatch(loaderActions.loaderDisplay.hidden());
      dispatch(
        appManagerActions.actionNotificationDisplay.visible(
          activeSearchesNotifications.delete().failure()
        )
      );
      return Promise.reject(error);
    }
  }
);

export default deleteActiveResearchThunk;
