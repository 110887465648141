import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { adStudiesActions } from '../../store/adStudies/actions';
import { getAdStudiesState } from '../../store/adStudies/reducer';

interface IUseAdStudiesProps {
  folderId?: string;
  id?: string;
}
export default function useAdStudies({ folderId, id }: IUseAdStudiesProps) {
  const { activeAdStudy, formTouchStates, localisationFormValues } = useSelector(
    getAdStudiesState
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(
        adStudiesActions.fetchAdStudy.request({ idIri: `/ad-studies/${id}` })
      );
    }
  }, [id]);
  const deleteAdStudies = () => {
    dispatch(adStudiesActions.deleteAdStudies.request());
  };
  const moveAdStudies = () => {
    dispatch(adStudiesActions.moveAdStudies.modal());
  };
  return {
    activeAdStudy,
    adFull: isEmpty(activeAdStudy) ? null : activeAdStudy.ad,
    formTouchStates,
    localisationFormValues,
    deleteAdStudies,
    moveAdStudies,
  };
}
