import { RadioGroup } from '@material-ui/core';
import { nanoid } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../App/redux/store';
import { adActions, getAdState } from '../ad/adSlice';
import SingleParcelMap from './SinglePlotMap';

const SinglePlotMapContainer = () => {
  const { adPlots, adGeoAddress, activeAd } = useAppSelector(getAdState);

  const dispatch = useDispatch();

  return (
    <>
      {!isEmpty(adPlots.result) && (
        <section className="ad-details-search-found">
          <h3 className="ad-details-section-title">{`Bien(s) identifié(s) possédant les mêmes carastéristiques à ${activeAd.result?.city?.name}`}</h3>
          <div className="ad-details-search-found-cards">
            <div className="ad-details-geo-single-parcel-container">
              <RadioGroup
                value={adGeoAddress}
                onChange={(e) => dispatch(adActions.adGeoAddressSet(e.target.value))}
              >
                {(adPlots.result as AdPlots).map((elmt, index: number) => (
                  <SingleParcelMap
                    key={nanoid()}
                    marker={elmt}
                    radioKey={`radio-${index + 1}`}
                    department={
                      activeAd.result?.city?.postalCode?.substring(0, 2) ?? null
                    }
                  />
                ))}
              </RadioGroup>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default SinglePlotMapContainer;
