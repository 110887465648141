import genericNotifications, {
  notificationAction,
  notificationTitles,
} from '../../utils/genericNotifications';

export const pigeParamsNotifications = {
  create: (name: string) =>
    genericNotifications(
      notificationTitles.pigeParams,
      name,
      notificationAction.create
    ),
  update: (name: string) =>
    genericNotifications(
      notificationTitles.pigeParams,
      name,
      notificationAction.update
    ),
  delete: (name: string) =>
    genericNotifications(
      notificationTitles.pigeParams,
      name,
      notificationAction.delete
    ),
};
