import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../App/redux/store';
import { getPieceworkState } from './pieceworkSlice';
import fetchMarketResearchThunk from './services/fetchMarketResearchThunk';
import updateMarketResearchCitiesThunk from './services/updateMarketResearchCitiesThunk';

interface IUsePieceworkProps {
  loadMarketResearch?: boolean;
}
function usePiecework({ loadMarketResearch }: IUsePieceworkProps) {
  const { marketResearch } = useAppSelector(getPieceworkState);
  const dispatch = useAppDispatch();

  const loadMarketResearchDatas = () => dispatch(fetchMarketResearchThunk());
  const handleCityChange = (cities: Cities) => {
    const idIris = cities.map((m) => m.idIri);
    dispatch(updateMarketResearchCitiesThunk({ idIrisProps: idIris }));
  };
  useEffect(() => {
    if (loadMarketResearch && isEmpty(marketResearch.cities)) {
      loadMarketResearchDatas();
    }
  }, [loadMarketResearch, marketResearch.cities]);

  return {
    marketResearchIdIri: marketResearch.idIri,
    totalAds: marketResearch.totalAds,
    totalNewAds: marketResearch.totalNewAds,
    cities: marketResearch.cities,
    handleCityChange,
  };
}

export default usePiecework;
