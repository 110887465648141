import { isString } from 'formik';
import {
  changeApiKeyForStoreKey,
  changeStoreKeyForApiKey,
  emptyKeyRemove,
  objectKeyGenericMaping,
} from '../../store/common/parserCommon';

export const procedureEventTypes = [
  { value: 0, logo: '', text: 'Indéfini' },
  { value: 1, logo: 'mail', text: 'Envoi courrier / mail' },
  { value: 2, logo: 'phone', text: 'Appel Téléphonique' },
  { value: 3, logo: 'marker', text: 'Rendez-vous' },
  { value: 4, logo: 'euro', text: "Remise d'offre" },
  { value: 5, logo: 'case', text: 'Signature de documents' },
];

export const procedureEventsMapping = {
  '@id': 'idIri',
  event_type: 'type',
  ad_study: 'adStudyIdIri',
  scheduled_date: 'date',
  comment: 'comment',
  owner: 'owner',
  representative: 'contact',
  place: 'place',
};

const procedureEventOwnerMapping = {
  '@id': 'idIri',
  first_name: 'firstName',
  last_name: 'lastName',
};

export const procedureEventApiToStore = (data: any) => {
  const parsed = changeApiKeyForStoreKey<ProcedureEvent>(
    data,
    procedureEventsMapping
  );

  if (data.ad_study && !isString(data.ad_study)) {
    parsed.adStudyIdIri = data.ad_study['@id'];
    parsed.adStudyId = data.ad_study['@id'].split('/')[2];
    parsed.adStudyFolderIdIri = data.ad_study.folder['@id'];
    parsed.adStudyFolderId = data.ad_study.folder['@id'].split('/')[2];
  }

  parsed.type =
    procedureEventTypes.find((f) => f.value === data.event_type) ??
    procedureEventTypes[0];

  parsed.owner = changeApiKeyForStoreKey(
    parsed.owner,
    objectKeyGenericMaping(procedureEventOwnerMapping).base
  );

  return parsed;
};

export const procedureEventFormToApi = (data: any) => {
  const { base } = objectKeyGenericMaping(procedureEventsMapping);
  const tempData = emptyKeyRemove(data);

  return changeStoreKeyForApiKey(tempData, base);
};
