import * as yup from 'yup';

import { AREA, CITY, PRICE, PROPERTY_TYPOLOGY, ROOM } from '../../utils/validation';

export const createInitialValues = {
  city: null,
  price: '',
  builtArea: '',
  landArea: '',
  propertyTypology: null,
  condition: '',
  roomMin: 1,
};

export const schema = (name) => {
  return yup.object({
    city: yup.object().required(CITY.required).typeError(CITY.type),
    price: yup
      .number()
      .typeError(PRICE.type)
      .required(PRICE.required)
      .min(0, PRICE.min),
    builtArea:
      name === 'Terrain'
        ? yup.number().nullable()
        : yup.number().typeError(AREA.type).min(0, AREA.min),
    landArea:
      name !== 'Appartement'
        ? yup.number().typeError(AREA.type).min(0, AREA.min)
        : yup.number().nullable(),
    roomMin:
      name !== 'Terrain'
        ? yup.number().typeError(ROOM.type).nullable()
        : yup.number().nullable(),
    propertyTypology: yup
      .object()
      .required(PROPERTY_TYPOLOGY.required)
      .typeError(PROPERTY_TYPOLOGY.required),
    condition: yup.string().nullable(),
  });
};

export const priceComparisonsTabs = [
  { qtyKey: 'lower', label: ' - CHERS' },
  { qtyKey: 'equal', label: ' - IDENTIQUES' },
  { qtyKey: 'higher', label: ' + CHERS' },
];

export const priceComparisonsTableHeader = [
  '',
  '',
  'Typologie',
  'S.bien',
  'S.terrain',
  'Ville',
  'Origine',
  'Prix',
  'Activité',
  'Dossier',
];

export const comparativeStudiesRooms = [
  { value: 1, text: 'T1' },
  { value: 2, text: 'T2' },
  { value: 3, text: 'T3' },
  { value: 4, text: 'T4' },
  { value: 5, text: 'T5' },
  { value: 6, text: 'T6+' },
];
