import PropTypes from 'prop-types';
import ActiveResearchWordFilter from './ActiveResearchWordFilter';

const SellerNameBloc = ({ formik }) => {
  return (
    <div className="seller-name-filter-group">
      <ActiveResearchWordFilter
        formik={formik}
        name="includedSellerName"
        label="Vendeurs à inclure"
        placeholder="Vendeurs à inclure"
      />
      <ActiveResearchWordFilter
        formik={formik}
        name="excludedSellerName"
        label="Vendeurs à exclure"
        placeholder="Vendeurs à exclure"
      />
    </div>
  );
};

SellerNameBloc.propTypes = { formik: PropTypes.object };

export default SellerNameBloc;
