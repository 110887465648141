import { useState } from 'react';
import Button from '../../../../components/Assets/Button';
import AutocompleteInputCities from '../../../../components/Forms/AutocompleteInputCities';
import usePiecework from '../../usePiecework';
import PigeParametersCitiesList from './PigeParametersCitiesList';
import './pigeParametersTab.scss';

const PigeParametersTab = () => {
  const [selectedCities, setSelectedCities] = useState<Cities>([]);
  const { handleCityChange, cities } = usePiecework({ loadMarketResearch: true });

  const handleAddClick = () => {
    handleCityChange(selectedCities);
    setSelectedCities([]);
  };

  return (
    <section className="my-account-content user-pige ">
      <h2>Paramètres Pige</h2>
      <div className="user-pige-wrapper">
        <div className="user-pige-section">
          <div className="user-pige-form-elt">
            <AutocompleteInputCities
              name="cities"
              value={selectedCities ?? []}
              handleChange={(name: string, value: Cities) => {
                setSelectedCities(value);
              }}
              multiple
              unwantedCities={cities?.map((m) => m.idIri)}
            />
          </div>
          <Button
            btnType="button"
            btnContent="RAJOUTER"
            primary
            handleClick={handleAddClick}
          />
        </div>
        <div className="user-pige-section">
          <PigeParametersCitiesList />
        </div>
      </div>
    </section>
  );
};

export default PigeParametersTab;
