import { cityApiToStore } from '../../utils/cityParser';

function marketResearchApiToStoreParser(datas: any) {
  try {
    const cities = datas.cities?.map((m: any) => cityApiToStore(m));

    const parsed: MarketResearch = {
      idIri: datas['@id'],
      totalAds: datas.total_ads,
      totalNewAds: datas.total_ads_today,
      cities,
    };

    return parsed;
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export { marketResearchApiToStoreParser };
