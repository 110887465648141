import { dateFormat, spaceNumberFormat } from '../../utils/jsFunctions';
import Badge from '../badge';
import {
  BadgeBackgroundColorEnum,
  BadgeContentEnum,
  BadgeTextColorEnum,
} from '../badge/enums';
import {
  FIELD_GOODS_SALE,
  FIELD_GOODS_SOLD,
  NEW_GOODS_SALE,
  NEW_GOODS_SOLD,
  OLD_GOODS_SALE,
  OLD_GOODS_SOLD,
} from './constant';
interface IGoodStatRowProps {
  data?: any;
}
const infoDisplay = (type: string, state: string, period: any) => {
  const soldedDisplay = (data: string) =>
    `${data} ${dateFormat(period?.start)} - ${dateFormat(period?.end)}`;
  switch (type) {
    case 'new_property':
      return state === 'sale' ? NEW_GOODS_SALE : soldedDisplay(NEW_GOODS_SOLD);
    case 'old_property':
      return state === 'sale' ? OLD_GOODS_SALE : soldedDisplay(OLD_GOODS_SOLD);
    default:
      return state === 'sale' ? FIELD_GOODS_SALE : soldedDisplay(FIELD_GOODS_SOLD);
  }
};
const GoodStatRow = ({ data }: IGoodStatRowProps) => {
  const getBadge = (value: string) => {
    return (
      <Badge
        badgeDynamicContent={value}
        bgColor={BadgeBackgroundColorEnum.VIOLET}
        textColor={BadgeTextColorEnum.WHITE}
        isMini
      />
    );
  };

  return (
    <div className="market-studies-result-prices-section">
      <span className="market-studies-result-prices-sub-title">
        {infoDisplay(data?.propertyCategory, data?.statCategory, data?.period)}
      </span>
      {data?.stocks && data?.stocks.total > 0 ? (
        <>
          <div className="market-studies-result-prices-line">
            <em>Stock</em>
            {getBadge(
              `${data?.stocks.total} bien(s), dont ${data?.stocks.exploitable} bien(s) exploitable(s) pour la statistique`
            )}
          </div>
          <div className="market-studies-result-prices-line">
            <em>Prix moyen</em>
            <Badge
              badgeDynamicContent={spaceNumberFormat({
                value: data?.prices.avgPrice,
                withEuro: true,
              })}
              bgColor={BadgeBackgroundColorEnum.VIOLET}
              textColor={BadgeTextColorEnum.WHITE}
              isMini
            />
          </div>
          <div className="market-studies-result-prices-line">
            <em>Prix minimum</em>
            {getBadge(
              spaceNumberFormat({ value: data.prices.lowest, withEuro: true })
            )}
          </div>
          <div className="market-studies-result-prices-line">
            <em>Prix maximum</em>
            {getBadge(
              spaceNumberFormat({ value: data.prices.highest, withEuro: true })
            )}
          </div>
          <div className="market-studies-result-prices-line">
            <em>Prix moyen m²</em>
            {getBadge(
              spaceNumberFormat({ value: data.prices.sqmAvgPrice, withEuro: true })
            )}
          </div>
          <div className="market-studies-result-prices-line">
            <em>Surface moyenne</em>
            {getBadge(
              `${spaceNumberFormat({ value: data.averageArea, decimal: true })} m²`
            )}
          </div>
        </>
      ) : (
        <div className="market-studies-result-prices-line">
          <em>Résultat</em>
          <Badge
            badgeContent={BadgeContentEnum.NO_DATAS}
            bgColor={BadgeBackgroundColorEnum.VIOLET}
            textColor={BadgeTextColorEnum.WHITE}
            isMini
          />
        </div>
      )}
    </div>
  );
};
export default GoodStatRow;
