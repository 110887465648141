import { call, put, select, take } from '@redux-saga/core/effects';
import {
  createEntity,
  deleteEntity,
  fetchDatas,
  updateEntity,
} from '../../services/axiosInstanceService';
import { NotificationStatus } from '../../utils/enums';
import { appManagerActions } from '../appManager/actions';
import { getAuthState } from '../auth/reducer';
import { REQUEST } from '../common/actions';
import { mapObjectParser } from '../common/parserCommon';
import onErrorLogout from '../common/sagaCommon';
import { loaderActions } from '../loader/actions';
import {
  CREATE_MARKET_STUDY,
  DELETE_MARKET_STUDY,
  FETCH_MARKET_STUDIES,
  FETCH_MARKET_STUDY_RESULT,
  marketStudiesActions,
  UPDATE_MARKET_STUDY,
  UPDATE_MARKET_STUDY_RESULT_COMMENT,
} from './actions';
import {
  marketStudiesNotifications,
  marketStudyResultNotifications,
} from './notifications';
import {
  marketStudiesApiToStore,
  marketStudyApiToStore,
  marketStudyFormToApi,
  marketStudyResultApiToStore,
  pdfDataParser,
} from './parser';
import { getMarketStudiesState } from './reducer';

export function* handleFetchMarketStudies() {
  while (true) {
    try {
      yield take(FETCH_MARKET_STUDIES[REQUEST]);

      const { userId } = yield select(getAuthState);
      const response = yield call(fetchDatas, `/users/${userId}/market-studies`);

      if (response) {
        const parsedResponse = marketStudiesApiToStore(response['hydra:member']);

        yield put(marketStudiesActions.fetchMarketStudies.success(parsedResponse));
      }
    } catch (error) {
      yield onErrorLogout(error);
      yield put(marketStudiesActions.fetchMarketStudies.failure(error));
    }
  }
}

export function* handleCreateMarketStudy() {
  while (true) {
    try {
      const {
        payload: { body, history },
      } = yield take(CREATE_MARKET_STUDY[REQUEST]);

      const parsedBody = marketStudyFormToApi(body);

      yield put(loaderActions.loaderDisplay.visible());

      const response = yield call(createEntity, {
        endpoint: '/market-studies',
        body: parsedBody,
      });

      if (response) {
        const parsedResponse = marketStudyApiToStore(response);
        yield put(marketStudiesActions.createMarketStudy.success(parsedResponse));
        yield call(history.push, `/etude-de-marche-resultats/${parsedResponse.id}`);
        yield put(loaderActions.loaderDisplay.hidden());
        yield put(
          appManagerActions.actionNotificationDisplay.visible(
            marketStudiesNotifications.create(response.name).success()
          )
        );
      }
    } catch (error) {
      yield onErrorLogout(error);
      yield put(
        appManagerActions.actionNotificationDisplay.visible(
          marketStudiesNotifications.create().failure()
        )
      );
      yield put(marketStudiesActions.createMarketStudy.failure(error));
      yield put(loaderActions.loaderDisplay.hidden());
    }
  }
}

export function* handleUpdateMarketStudy() {
  while (true) {
    try {
      const {
        payload: { body, history },
      } = yield take(UPDATE_MARKET_STUDY[REQUEST]);

      const parsedBody = marketStudyFormToApi(body);

      yield put(loaderActions.loaderDisplay.visible());
      const response = yield call(updateEntity, {
        idIri: body.idIri,
        body: parsedBody,
      });
      if (response) {
        const parsedResponse = marketStudyApiToStore(response);
        if (history) {
          yield call(history.push, '/etudes-de-marche');
        }
        yield put(marketStudiesActions.updateMarketStudy.success(parsedResponse));
        yield put(loaderActions.loaderDisplay.hidden());
        yield put(
          appManagerActions.actionNotificationDisplay.visible(
            marketStudiesNotifications.update(response.name).success()
          )
        );
      }
    } catch (error) {
      yield onErrorLogout(error);
      yield put(
        appManagerActions.actionNotificationDisplay.visible(
          marketStudiesNotifications.update().failure()
        )
      );
      yield put(marketStudiesActions.updateMarketStudy.failure(error));
      yield put(loaderActions.loaderDisplay.hidden());
    }
  }
}

export function* handleDeleteMarketStudies() {
  while (true) {
    try {
      const { payload } = yield take(DELETE_MARKET_STUDY[REQUEST]);
      const { marketStudies } = yield select(getMarketStudiesState);
      const marketStudy = marketStudies.find((ms) => ms.idIri === payload);

      yield put(loaderActions.loaderDisplay.visible());
      const status = yield call(deleteEntity, payload);
      if (status === 204) {
        yield put(marketStudiesActions.deleteMarketStudy.success(payload));
        yield put(loaderActions.loaderDisplay.hidden());
        yield put(
          appManagerActions.actionNotificationDisplay.visible(
            marketStudiesNotifications.delete(marketStudy.name).success(marketStudy)
          )
        );
      }
    } catch (error) {
      yield onErrorLogout(error);
      yield put(
        appManagerActions.actionNotificationDisplay.visible(
          marketStudiesNotifications.delete().failure()
        )
      );
      yield put(marketStudiesActions.deleteMarketStudy.failure(error));
      yield put(loaderActions.loaderDisplay.hidden());
    }
  }
}

export function* handleFetchMarketStudyResult() {
  while (true) {
    try {
      const { payload } = yield take(FETCH_MARKET_STUDY_RESULT[REQUEST]);

      const response = yield call(fetchDatas, `/market-studies/${payload}/result`);

      if (response) {
        const parsedData = mapObjectParser(
          response['hydra:member'],
          marketStudyResultApiToStore
        );

        const pdfDatas = pdfDataParser(parsedData);
        yield put(
          marketStudiesActions.fetchMarketStudyResult.success({
            result: parsedData,
            pdfDatas,
          })
        );
      }
    } catch (error) {
      yield onErrorLogout(error);
      yield put(
        appManagerActions.actionNotificationDisplay.visible({
          title: 'Etude de marché',
          content: `Le résultat de l'étude de marché n'a pas pu être chargé`,
          status: NotificationStatus.FAILURE,
        })
      );
      yield put(marketStudiesActions.fetchMarketStudyResult.failure(error));
    }
  }
}

export function* handleUpdateMarketStudyResultComment() {
  while (true) {
    try {
      const {
        payload: { id, text },
      } = yield take(UPDATE_MARKET_STUDY_RESULT_COMMENT[REQUEST]);

      yield put(loaderActions.loaderDisplay.visible());

      const response = { comment: text };
      if (response) {
        yield put(loaderActions.loaderDisplay.hidden());
        yield put(
          appManagerActions.actionNotificationDisplay.visible(
            marketStudyResultNotifications.update().success()
          )
        );
        yield put(
          marketStudiesActions.updateMarketStudyResultComment.success(response)
        );
      }
    } catch (error) {
      yield onErrorLogout(error);
      yield put(
        appManagerActions.actionNotificationDisplay.visible(
          marketStudyResultNotifications.update().failure()
        )
      );
      yield put(marketStudiesActions.updateMarketStudyResultComment.failure(error));
      yield put(loaderActions.loaderDisplay.hidden());
    }
  }
}
