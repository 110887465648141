import { call, put, select, take, takeEvery } from '@redux-saga/core/effects';
import { isEmpty } from 'lodash';
import { adActions, getAdState } from '../../features/ad/adSlice';
import fetchAdsThunk from '../../features/ad/services/thunks/fetchAdsThunk';
import { APIStatus } from '../../services/axiosEnums';
import {
  createEntity,
  deleteEntity,
  fetchDataById,
  updateEntity,
} from '../../services/axiosInstanceService';
import { appManagerActions } from '../appManager/actions';
import { REQUEST } from '../common/actions';
import onErrorLogout from '../common/sagaCommon';
import { foldersActions } from '../folders/actions';
import { folderNotifications } from '../folders/notifications';
import { getFoldersState } from '../folders/reducer';
import { loaderActions } from '../loader/actions';
import { modalsActions } from '../modals/actions';
import {
  adStudiesActions,
  CREATE_AD_STUDIES,
  DELETE_AD_STUDIES,
  FETCH_AD_STUDY,
  MOVE_AD_STUDIES,
  UPDATE_AD_STUDY,
} from './actions';
import { moveAdStudies } from './api';
import { adStudiesNotifications } from './notifications';
import { adStudyApiToStore, adStudyFormToApi } from './parser';

export function* handleFetchAdStudy() {
  while (true) {
    try {
      const {
        payload: { idIri },
      } = yield take(FETCH_AD_STUDY[REQUEST]);

      yield put(loaderActions.loaderDisplay.visible());
      const response = yield call(fetchDataById, idIri);

      if (response) {
        const parsedResponse = adStudyApiToStore(response);

        yield put(adStudiesActions.fetchAdStudy.success(parsedResponse));
        yield put(loaderActions.loaderDisplay.hidden());
      }
    } catch (error) {
      yield put(loaderActions.loaderDisplay.hidden());
      yield onErrorLogout(error);
      yield put(adStudiesActions.fetchAdStudy.failure(error));
    }
  }
}

function* createAdStudyRequest(body, folderIdIri) {
  const response = yield call(createEntity, body);
  if (response) {
    yield put(
      adStudiesActions.createAdStudies.success({
        adIdIri: body.body.ad,
        folderIdIri: folderIdIri,
      })
    );
  }
}

export function* handleCreateAdStudies() {
  while (true) {
    try {
      const {
        payload: { idIri },
      } = yield take(CREATE_AD_STUDIES[REQUEST]);
      const { selectedAds, adGeoAddress, activeAd, pagination } = yield select(
        getAdState
      );
      const ad = activeAd.result;
      yield put(loaderActions.loaderDisplay.visible());

      const body = {
        endpoint: '/ad-studies',
        body: {
          folder: idIri,
        },
      };

      if (!isEmpty(ad)) {
        body.body.ad = ad.idIri;
        body.body.address = adGeoAddress;
        body.body.seller_contact = {
          full_name: ad.seller?.name ?? '',
          phone_number: ad.seller?.phone ?? '',
        };

        yield createAdStudyRequest(body, idIri);
      } else {
        let index = 0;
        // create loop
        while (index < selectedAds.length) {
          body.body.ad = selectedAds[index].idIri;
          body.body.seller_contact = {
            full_name: selectedAds[index].seller?.name ?? '',
            phone_number: selectedAds[index].seller?.phone ?? '',
          };

          yield createAdStudyRequest(body, idIri);

          index += 1;
        }
        yield put(adActions.selectedAdsReset());
      }

      yield put(adActions.adGeoAddressSet(null));
      yield put(fetchAdsThunk(pagination));
      yield put(modalsActions.chooseFolder.close());
      yield put(loaderActions.loaderDisplay.hidden());
      yield put(
        appManagerActions.actionNotificationDisplay.visible(
          adStudiesNotifications.create().success()
        )
      );
    } catch (error) {
      yield put(loaderActions.loaderDisplay.hidden());
      yield onErrorLogout(error);
      yield put(adStudiesActions.createAdStudies.failure(error));
      yield put(
        appManagerActions.actionNotificationDisplay.visible(
          adStudiesNotifications.create().failure()
        )
      );
    }
  }
}

export function* handleUpdateMultipleAdStudyForm() {
  yield takeEvery(UPDATE_AD_STUDY[REQUEST], handleUpdateAdStudy);
}

function* handleUpdateAdStudy({ payload }) {
  const { body, type, idIri, id, history } = payload;
  try {
    yield put(loaderActions.loaderDisplay.visible());

    const parsedBody = adStudyFormToApi(body, type);

    const response = yield call(updateEntity, { idIri, body: parsedBody });

    if (response) {
      yield put(
        appManagerActions.actionNotificationDisplay.visible(
          adStudiesNotifications.update().success()
        )
      );
      if (type === 'folder') {
        yield put(foldersActions.fetchFolders.request());
        yield call(
          history.push,
          `/creation-annonce-dossier/${body.folder.split('/')[2]}/${id}`
        );
      }
      yield put(adStudiesActions.formTouchStates.set({ [type + 'Form']: false }));
    }
    yield put(loaderActions.loaderDisplay.hidden());
  } catch (error) {
    yield put(loaderActions.loaderDisplay.hidden());
    yield onErrorLogout(error);
    yield put(adStudiesActions.updateAdStudy.failure(error));
  }
}

export function* handleDeleteAdStudies() {
  while (true) {
    try {
      yield take(DELETE_AD_STUDIES[REQUEST]);

      const { selectedAds, pagination } = yield select(getAdState);
      const { activeFolder } = yield select(getFoldersState);

      yield put(adActions.adsApiSet({ apiStatus: APIStatus.PENDING, error: null }));

      let status = 204;
      let index = 0;

      do {
        status = yield call(deleteEntity, selectedAds[index].adStudy.idIri);
        index += 1;
      } while (index < selectedAds.length && status === 204);

      if (status === 204) {
        // the loader is hidden by the incoming fetchAdsThunk result or error
        yield put(adActions.selectedAdsReset());
        yield put(fetchAdsThunk({ ...pagination, folderId: activeFolder }));
        yield put(
          appManagerActions.actionNotificationDisplay.visible(
            adStudiesNotifications.delete().success()
          )
        );
      }
    } catch (error) {
      yield onErrorLogout(error);
      yield put(adActions.adsApiSet({ apiStatus: APIStatus.REJECTED, error }));
      yield put(
        appManagerActions.actionNotificationDisplay.visible(
          adStudiesNotifications.delete().failure()
        )
      );
    }
  }
}

// ***************************************************
// *  MOVE AD-STUDY(IES)
// ***************************************************
export function* handleMoveAdStudies() {
  while (true) {
    try {
      const {
        payload: { idIri },
      } = yield take(MOVE_AD_STUDIES[REQUEST]);

      const { selectedAds, pagination } = yield select(getAdState);
      const { folders, activeFolder } = yield select(getFoldersState);
      const selectedFolder = folders.find((e) => e.idIri === idIri);

      yield put(adActions.adsApiSet({ apiStatus: APIStatus.PENDING, error: null }));

      const body = { 'ad-studies': selectedAds.map((m) => m.adStudy.idIri) };

      const status = yield call(moveAdStudies, {
        idIri: `${idIri}/move-ad-studies`,
        body,
      });

      if (status === 204) {
        // the loader is hidden by the incoming fetchAdsThunk result or error
        yield put(foldersActions.fetchFolders.request());
        yield put(adActions.selectedAdsReset());
        yield put(fetchAdsThunk({ ...pagination, folderId: activeFolder }));
        yield put(
          appManagerActions.actionNotificationDisplay.visible(
            adStudiesNotifications.moveAdStudies.success(selectedFolder.name)
          )
        );
      }
    } catch (error) {
      yield onErrorLogout(error);

      yield put(adActions.adsApiSet({ apiStatus: APIStatus.REJECTED, error }));
      yield put(
        appManagerActions.actionNotificationDisplay.visible(
          folderNotifications.changefolder.failure()
        )
      );
    }
  }
}
