export const citiesApiToStoreParser = (datas: any[]) => {
  return datas.map((m) => cityApiToStore(m));
};

export const cityApiToStore = (data: any): City => {
  try {
    const parsedData: City = {
      idIri: data['@id'],
      id: parseInt(data['@id'].split('/')[2], 10),
      name: data.name,
      postalCode: data.zip_code,
      nameAndDepNumber: `${data.name} (${data.zip_code})`,
    };

    return parsedData;
  } catch (error) {
    throw new Error('city parser error');
  }
};

export const cityApiToStoreForMap = (data: any) => {
  const cityBase = cityApiToStore(data);

  const city: CityForMap = {
    ...cityBase,
    lat: data.latitude,
    lng: data.longitude,
    inseeCode: data.insee_code,
  };

  return city;
};
