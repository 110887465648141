import CardBody from './CardBody';
import CardHeader from './CardHeader';
import CardFooter from './cardFooter';

interface IActiveResearchCardProps {
  activeResearch: ActiveResearch;
}
function ActiveResearchCard({ activeResearch }: IActiveResearchCardProps) {
  return (
    <div className="active-search-card">
      <CardHeader {...activeResearch} />
      <CardBody {...activeResearch} />
      <CardFooter {...activeResearch} />
    </div>
  );
}

export default ActiveResearchCard;
