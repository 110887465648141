import { ChangeEvent, useEffect, useState } from 'react';
import SelectInput from '../../../../components/Forms/SelectInput';
import useAds from '../../../ad/useAds';
import { activeResearchesActionsValues } from '../../constant';

type ActionType = '' | 'study' | 'delete';

interface IActionButtonProps {
  disabled: boolean;
}
function ActionButton({ disabled }: IActionButtonProps) {
  const [action, setAction] = useState<ActionType>('');
  const { ads, deleteAds, createStudyFromAd, selectedAds } = useAds({
    noLoadAds: true,
  });

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as ActionType;
    setAction(value);
    switch (value) {
      case 'study':
        createStudyFromAd();
        break;
      case 'delete':
        deleteAds();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (disabled) {
      setAction('');
    }
  }, [disabled]);

  return (
    <div className="active-search-result-header-action">
      <SelectInput
        colorDisplay="reverse"
        name="action"
        value={action}
        nameKey="text"
        label="Action"
        handleChange={handleChange}
        disabled={disabled}
        items={activeResearchesActionsValues}
      />
      <p>{`${selectedAds.length ?? 0} annonces selectionnées`}</p>
    </div>
  );
}

export default ActionButton;
