import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../App/redux/store';
import { APIStatus } from '../../services/axiosEnums';

import { initialPagination } from './constants';
import deleteAdsThunk from './services/thunks/deleteAdsThunk';
import fetchAdPlotLocationsThunk from './services/thunks/fetchAdPlotLocationsThunk';
import fetchAdsThunk from './services/thunks/fetchAdsThunk';
import fetchAdThunk from './services/thunks/fetchAdThunk';

const initialState: AdState = {
  pagination: initialPagination,
  ads: { apiStatus: APIStatus.IDLE, result: null },
  activeAd: { apiStatus: APIStatus.IDLE, result: null },
  selectedAds: [],
  adPlots: { apiStatus: APIStatus.IDLE, result: null },
  adGeoAddress: null,
  deleteErrorIds: [],
};

const adSlice = createSlice({
  name: 'ad',
  initialState,
  reducers: {
    reset: () => initialState,
    adsApiSet: (
      state,
      action: PayloadAction<Omit<IApiState<Ads | null>, 'result'>>
    ) => {
      state.ads.apiStatus = action.payload.apiStatus;
      state.ads.error = action.payload.error;
    },
    adGeoAddressSet: (state, action: PayloadAction<string | null>) => {
      state.adGeoAddress = action.payload;
    },
    paginationSet: (state, action: PayloadAction<AdPaginationDatas>) => {
      state.pagination = action.payload;
    },
    plotReset: (state) => {
      state.adPlots = { apiStatus: APIStatus.IDLE, result: null, error: null };
    },
    geoAddressReset: (state) => {
      state.adGeoAddress = null;
    },
    selectedAdsAdd: (state, action: PayloadAction<Ad>) => {
      const ap = action.payload;
      if (state.selectedAds.map((m) => m.idIri).includes(ap.idIri)) {
        state.selectedAds = state.selectedAds.filter(
          (elt) => elt.idIri !== ap.idIri
        );
      } else {
        state.selectedAds = state.selectedAds.concat(ap);
      }
    },
    selectedAdsReset: (state) => {
      state.selectedAds = [];
    },
  },
  extraReducers(builder) {
    // FETCH ADS
    builder
      .addCase(fetchAdsThunk.pending, (state) => {
        state.ads.apiStatus = APIStatus.PENDING;
        state.ads.error = undefined;
      })
      .addCase(
        fetchAdsThunk.fulfilled,
        (
          state,
          action: PayloadAction<{ ads: Ads; pagination: AdPaginationDatas }>
        ) => {
          state.ads.apiStatus = APIStatus.IDLE;
          state.ads.result = action.payload.ads;
          state.pagination = action.payload.pagination;
          state.ads.error = undefined;
        }
      )
      .addCase(fetchAdsThunk.rejected, (state, action) => {
        state.ads.apiStatus = APIStatus.REJECTED;
        state.ads.error = action.error;
      });
    // FETCH ACTIVE AD
    builder
      .addCase(fetchAdThunk.pending, (state) => {
        state.activeAd.apiStatus = APIStatus.PENDING;
        state.activeAd.error = undefined;
      })
      .addCase(fetchAdThunk.fulfilled, (state, action: PayloadAction<Ad>) => {
        state.activeAd.apiStatus = APIStatus.IDLE;
        state.activeAd.result = action.payload;
        state.activeAd.error = undefined;
      })
      .addCase(fetchAdThunk.rejected, (state, action) => {
        state.activeAd.apiStatus = APIStatus.REJECTED;
        state.activeAd.error = action.error;
      });
    // FETCH ACTIVE AD PLOT LOCATION
    builder
      .addCase(fetchAdPlotLocationsThunk.pending, (state) => {
        state.adPlots.apiStatus = APIStatus.PENDING;
        state.adPlots.error = undefined;
      })
      .addCase(
        fetchAdPlotLocationsThunk.fulfilled,
        (state, action: PayloadAction<AdPlots>) => {
          state.adPlots.apiStatus = APIStatus.IDLE;
          state.adPlots.result = action.payload;
          state.adPlots.error = undefined;
        }
      )
      .addCase(fetchAdPlotLocationsThunk.rejected, (state, action) => {
        state.adPlots.apiStatus = APIStatus.REJECTED;
        state.adPlots.error = action.error;
      });
    // DELETE ADS
    builder
      .addCase(deleteAdsThunk.pending, (state) => {
        state.ads.apiStatus = APIStatus.PENDING;
        state.ads.error = undefined;
      })
      .addCase(deleteAdsThunk.fulfilled, (state) => {
        state.ads.apiStatus = APIStatus.IDLE;
        state.selectedAds = [];
        state.ads.error = undefined;
      })
      .addCase(deleteAdsThunk.rejected, (state, action) => {
        state.ads.apiStatus = APIStatus.REJECTED;
        state.ads.error = action.error;
      });
  },
});

export default adSlice.reducer;
export const adActions = adSlice.actions;
export const getAdState = (state: RootState) => state.ad;
