import { useFormik } from 'formik';
import { forwardRef } from 'react';
import { useDispatch } from 'react-redux';

import Button from '../Assets/Button';
import DateInput from '../Forms/DateInput';
import SelectInput from '../Forms/SelectInput';
import TextInput from '../Forms/TextInput';

import useAdStudies from '../../App/hooks/useAdStudies';
import { procedureEventsActions } from '../../features/procedureEvent/actions';
import { procedureEventTypes } from '../../features/procedureEvent/parser';
import { procedureEventsInitialValues, procedureEventsSchema } from './form';

function AdStudyDemarcheForm(props, ref) {
  const { activeAdStudy } = useAdStudies({});
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: procedureEventsInitialValues,
    validationSchema: procedureEventsSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      values.adStudyIdIri = activeAdStudy.idIri;
      dispatch(procedureEventsActions.createProcedureEvents.request(values));
      resetForm(procedureEventsInitialValues);
    },
  });

  const { values, touched, errors } = formik;

  return (
    <form className="my-folders-ad-creation-content-side-business">
      <h4>Démarches entreprises</h4>
      <TextInput
        label="Interlocuteur"
        name="contact"
        value={values.contact}
        handleChange={formik.handleChange}
        error={touched.contact && Boolean(errors.contact)}
        helperText={touched.contact && errors.contact}
        required
      />
      <div className="my-folders-ad-creation-select">
        <SelectInput
          name="type"
          value={values.type}
          valueKey="value"
          nameKey="text"
          hasNoValue={true}
          noValueText="Selectionnez un événement"
          handleChange={formik.handleChange}
          label="Evénement"
          items={procedureEventTypes}
          error={touched.type && Boolean(errors.type)}
          helperText={touched.type && errors.type}
        />
      </div>
      <DateInput
        name="date"
        label="Date"
        value={values.date}
        handleChange={(name, value) => formik.setFieldValue(name, value)}
        helperText={touched.date && errors.date}
      />
      <TextInput
        label="Commentaires"
        rows={4}
        name="comment"
        value={values.comment}
        handleChange={formik.handleChange}
        error={touched.comment && Boolean(errors.comment)}
        helperText={touched.comment && errors.comment}
      />
      <Button
        ref={ref}
        btnType="button"
        btnContent="VALIDER LA DEMARCHE"
        handleClick={formik.handleSubmit}
        primary
      />
    </form>
  );
}

export default forwardRef(AdStudyDemarcheForm);
