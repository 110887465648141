import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../App/redux/store';
import { fetchDatas } from '../../../services/axiosInstanceService';
import { marketResearchApiToStoreParser } from '../parser';

const fetchMarketResearchThunk = createAsyncThunk(
  'piecework/fetchMarketResearchThunk',
  async (params: void, { getState }) => {
    try {
      const state = getState() as RootState;
      const { marketResearch } = state.piecework;

      if (!marketResearch.idIri) throw new Error('No marketResearch id found');

      const response = await fetchDatas(marketResearch.idIri);

      const parsed = marketResearchApiToStoreParser(response);

      return parsed;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export default fetchMarketResearchThunk;
