import { LOGOUT } from '../auth/actions';
import { SUCCESS } from '../common/actions';
import { FETCH_COMPANY, FETCH_COMPANY_STATS, UPDATE_COMPANY } from './actions';

const initialState = {
  company: {},
  companyStats: [],
  collaborators: [],
  pigeParams: null,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_COMPANY[SUCCESS]:
      return {
        ...state,
        company: payload.response,
        collaborators: payload.users,
      };
    case FETCH_COMPANY_STATS[SUCCESS]:
      return { ...state, companyStats: payload };
    case UPDATE_COMPANY[SUCCESS]:
      return {
        ...state,
        company: {
          ...state.company,
          ...payload,
        },
      };
    case LOGOUT[SUCCESS]:
      return { ...initialState };
    default:
      return state;
  }
}

export const getAccountState = (state) => state.account;
export const getCompanyState = (state) => state.account.company;
