import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDataById } from '../../../../services/axiosInstanceService';
import { activeResearchDetailApiToStoreParser } from '../../activeResearchParser';

const fetchCurrentActiveResearchThunk = createAsyncThunk(
  'activeResearch/fetchCurrentActiveResearchThunk',
  async (params: { id: string | null }) => {
    try {
      if (!params.id) throw new Error('Active research id not found');

      const url = `/active-researches/${params.id}`;
      const result = await Promise.all([
        fetchDataById(url),
        fetchDataById(`${url}/total-ads`),
      ]);

      const parsed = activeResearchDetailApiToStoreParser(result[0]);
      parsed.totalAds = result[1].total_ads;
      parsed.totalNewAds = result[1].total_new_ads;

      return parsed;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export default fetchCurrentActiveResearchThunk;
