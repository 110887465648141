import { cityApiToStore } from '../../utils/cityParser';
import {
  changeApiKeyForStoreKey,
  changeStoreKeyForApiKey,
} from '../common/parserCommon';
import { propertyTypologyApiToStoreParser } from '../propertyTypologies/parser';

const comparativeStudyMapping = {
  '@id': 'idIri',
  id: 'id',
  built_area: 'builtArea',
  land_area: 'landArea',
  price: 'price',
  property_typology: 'propertyTypology',
  city: 'city',
  address: 'address',
  condition: 'condition',
  price_comparisons: 'priceComparisons',
  room_min: 'roomMin',
};

const priceComparisonMapping = {
  cheaper: 'lower',
  equal: 'equal',
  more_expensive: 'higher',
  average_price: 'averagePrice',
  sqm_average_price: 'sqmAveragePrice',
  percentage_price_comparison: 'percentagePriceComparison',
};

export const comparativeStudyApiToStore = (data) => {
  const parsedData = changeApiKeyForStoreKey(data, comparativeStudyMapping);

  parsedData.id = parsedData.idIri.split('/')[2];
  parsedData.city = cityApiToStore(data.city);

  parsedData.propertyTypology = propertyTypologyApiToStoreParser(
    data.property_typology
  );

  parsedData.priceComparisons = changeApiKeyForStoreKey(
    data.price_comparisons,
    priceComparisonMapping
  );

  return parsedData;
};
export const comparativeStudiesApiToStore = (datas) =>
  datas.map((m) => comparativeStudyApiToStore(m));

export const comparativeStudyFormToApi = (data) => {
  let parsedData = changeStoreKeyForApiKey(data, comparativeStudyMapping);

  parsedData.city = data.city.idIri;
  parsedData.property_typology = data.propertyTypology.idIri;
  console.log(parsedData);
  parsedData.room_min = parsedData.room_min === '' ? null : parsedData.room_min;
  parsedData.land_area =
    parsedData.land_area === '' || parsedData.land_area === undefined
      ? 0
      : parsedData.land_area;
  parsedData.built_area =
    parsedData.built_area === '' || parsedData.built_area === undefined
      ? 0
      : parsedData.built_area;
  parsedData.condition =
    parsedData.condition === '' || parsedData.condition === undefined
      ? null
      : parsedData.condition;

  delete parsedData.price_comparisons;
  delete parsedData.id;
  delete parsedData['@id'];

  return parsedData;
};
