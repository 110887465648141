import { action, CLOSE, createRequestTypes, OPEN, SET } from '../common/actions';

export const MODAL_AD_DETAILS = createRequestTypes('MODAL_AD_DETAILS');
export const MODAL_MOBILE_NAV = createRequestTypes('MODAL_MOBILE_NAV');
export const MODAL_PROFILE_MENU = createRequestTypes('MODAL_PROFILE_MENU');
export const MODAL_CHOOSE_FOLDER = createRequestTypes('MODAL_CHOOSE_FOLDER');
export const MODAL_MODIFY_FOLDERS = createRequestTypes('MODAL_MODIFY_FOLDERS');
export const MODAL_NEW_COLLABORATOR = createRequestTypes('MODAL_NEW_COLLABORATOR');
export const MODAL_COMPARATIVE_STUDIES = createRequestTypes(
  'MODAL_COMPARATIVE_STUDIES'
);
export const MODAL_SUBSCRIPTION = createRequestTypes('MODAL_SUBSCRIPTION');
export const MODAL_SUBSCRIPTION_SUMMARY = createRequestTypes(
  'MODAL_SUBSCRIPTION_SUMMARY'
);
export const MODAL_CONFIRMATION = createRequestTypes('MODAL_CONFIRMATION');
export const NOTIFICATION_BELL = createRequestTypes('NOTIFICATION_BELL');
export const MODAL_USER_MOBILE_TABS = createRequestTypes('MODAL_USER_MOBILE_TABS');
export const MODAL_ERROR_PIECEWORK = createRequestTypes('MODAL_ERROR_PIECEWORK');
export const NO_URBANEASE_ACCOUNT = createRequestTypes('NO_URBANEASE_ACCOUNT');
export const MODALS = createRequestTypes('MODALS');
export const DELETE_ADS = createRequestTypes('DELETE_ADS');
export const modalsActions = {
  modals: {
    close: () => action(MODALS[CLOSE]),
  },
  modifyFolders: {
    open: () => action(MODAL_MODIFY_FOLDERS[OPEN]),
    close: () => action(MODAL_MODIFY_FOLDERS[CLOSE]),
  },
  adDetails: {
    open: () => action(MODAL_AD_DETAILS[OPEN]),
    close: () => action(MODAL_AD_DETAILS[CLOSE]),
  },
  mobileNav: {
    open: () => action(MODAL_MOBILE_NAV[OPEN]),
    close: () => action(MODAL_MOBILE_NAV[CLOSE]),
  },
  profileMenu: {
    open: () => action(MODAL_PROFILE_MENU[SET], { payload: true }),
    close: () => action(MODAL_PROFILE_MENU[SET], { payload: false }),
  },
  chooseFolder: {
    open: () => action(MODAL_CHOOSE_FOLDER[OPEN]),
    close: () => action(MODAL_CHOOSE_FOLDER[CLOSE]),
  },
  deleteAds: {
    open: () => action(DELETE_ADS[OPEN]),
    close: () => action(DELETE_ADS[CLOSE]),
  },
  newCollaborator: {
    open: () => action(MODAL_NEW_COLLABORATOR[OPEN]),
    close: () => action(MODAL_NEW_COLLABORATOR[CLOSE]),
  },
  comparativeStudies: {
    open: () => action(MODAL_COMPARATIVE_STUDIES[OPEN]),
    close: () => action(MODAL_COMPARATIVE_STUDIES[CLOSE]),
  },
  subscription: {
    open: () => action(MODAL_SUBSCRIPTION[OPEN]),
    close: () => action(MODAL_SUBSCRIPTION[CLOSE]),
  },
  subscriptionSummary: {
    open: () => action(MODAL_SUBSCRIPTION_SUMMARY[OPEN]),
    close: () => action(MODAL_SUBSCRIPTION_SUMMARY[CLOSE]),
  },
  confirmation: {
    open: () => action(MODAL_CONFIRMATION[OPEN]),
    close: () => action(MODAL_CONFIRMATION[CLOSE]),
  },
  notificationBell: {
    open: () => action(NOTIFICATION_BELL[OPEN]),
    close: () => action(NOTIFICATION_BELL[CLOSE]),
  },
  userMobileTabs: {
    open: () => action(MODAL_USER_MOBILE_TABS[OPEN]),
    close: () => action(MODAL_USER_MOBILE_TABS[CLOSE]),
  },
  errorPiecework: {
    open: () => action(MODAL_ERROR_PIECEWORK[OPEN]),
    close: () => action(MODAL_ERROR_PIECEWORK[CLOSE]),
  },
  noUrbaneaseAccount: {
    open: (errorStatus) =>
      action(NO_URBANEASE_ACCOUNT[OPEN], { payload: errorStatus }),
    close: () => action(NO_URBANEASE_ACCOUNT[CLOSE]),
  },
};
