import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { isEmpty, isNumber } from 'lodash-es';
import PropTypes from 'prop-types';
import { sellerTypes } from '../../features/activeResearch/constant';

const AutocompleteInputSellerType = ({
  onChange,
  value,
  name,
  multiple,
  required,
  error,
  helperText,
  placeholder,
}) => {
  const handleChange = (name, value) => {
    const newValue = value.map((m) => (isNumber(m) ? m : m.value));

    let parsedValue;
    if (multiple) {
      if (isEmpty(newValue)) {
        parsedValue = [0];
      } else if (newValue.length > 1) {
        parsedValue = newValue.filter((e) => e !== 0);
      } else {
        parsedValue = newValue;
      }
    } else {
      parsedValue = newValue;
    }

    onChange(name, parsedValue);
  };

  return (
    <Autocomplete
      multiple={multiple}
      filterSelectedOptions
      options={sellerTypes}
      getOptionLabel={(option) => {
        if (option === null) {
          return sellerTypes.find((e) => 0 === e.value).name;
        }
        return isNumber(option)
          ? sellerTypes.find((e) => option === e.value).name
          : sellerTypes.find((e) => option.value === e.value).name;
      }}
      getOptionSelected={(option, value) => {
        return option.value === value;
      }}
      name={name}
      value={!isEmpty(value) ? value : multiple ? [] : {}}
      onChange={(evt, value) => handleChange(name, value)}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          variant="outlined"
          label="Profil de l'annonceur"
          placeholder={placeholder}
          name={name}
          InputLabelProps={{
            shrink: true,
          }}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};

AutocompleteInputSellerType.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  name: PropTypes.string,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
};
export default AutocompleteInputSellerType;
