import Chart from 'chart.js';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { spaceNumberFormat } from '../../utils/jsFunctions';
import { useViewport } from '../../utils/viewport';

function MultiLineGraph({ labels, datasets, type, handleGraph }) {
  const chartRef = useRef(null);
  const colors = ['#1CD4FD', '#73cf77', '#879EBE', '#ED6565', '#e0b45d', '#bc4ee2'];
  const { mobileView } = useViewport();

  const newDatasets = datasets.map((elt, i) => {
    const newElt = Object.assign({}, elt);
    newElt.borderColor = colors[i % colors.length];
    newElt.pointBackgroundColor = colors[i % colors.length];
    return newElt;
  });

  useEffect(() => {
    const myChartRef = chartRef.current.getContext('2d');

    let chart = new Chart(myChartRef, {
      type: 'line',
      data: {
        labels: labels,
        datasets: newDatasets,
      },

      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                fontStyle: 'bold',
                beginAtZero: true,
                callback: function (value) {
                  return type === 'stocks'
                    ? value
                    : spaceNumberFormat({ value, decimal: true }) + ' €/m²';
                },
              },
              gridLines: {
                color: '#f0f4f8',
                lineWidth: 0.5,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                color: '#f0f4f8',
                lineWidth: 0.5,
              },
            },
          ],
        },
        tooltips: {
          intersect: true,
          titleFontSize: 16,
          bodyFontSize: 14,
          xPadding: 10,
          yPadding: 10,
          backgroundColor: '#888',
          callbacks: {
            label: (tooltipItem, data) => {
              const di = tooltipItem.datasetIndex;
              const d = data.datasets[di];
              return d.label;
            },
            title: (tooltipItem) => {
              return `${tooltipItem[0].value} ${type === 'stocks' ? 'biens' : '€'}`;
            },
            labelColor: function (tooltipItem, chart) {
              const di = tooltipItem.datasetIndex;
              const c = chart.tooltip._data.datasets[di];
              return {
                borderColor: c.pointBorderColor,
                backgroundColor: c.pointBackgroundColor,
              };
            },
          },
        },
        legend: {
          position: mobileView ? 'bottom' : 'top',
          display: true,
          labels: {
            usePointStyle: true,
            boxWidth: mobileView ? 5 : 8,
            fontStyle: 'bold',
            fontSize: mobileView ? 13 : 14,
          },
        },
        elements: {
          line: {
            borderWidth: 1,
            fill: false,
            tension: 0,
          },
          point: {
            radius: mobileView ? 1 : 5,
          },
        },
        animation: false,
      },
    });

    handleGraph(chart.toBase64Image());
  }, [mobileView]);

  return (
    <div className="graph-container-line">
      <canvas id="myChart" ref={chartRef} />
    </div>
  );
}

MultiLineGraph.propTypes = {
  labels: PropTypes.array.isRequired,
  datasets: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  handleGraph: PropTypes.func.isRequired,
};

export default MultiLineGraph;
