export const distinctStringValues = (tab: any[]) =>
  tab.filter((elmt, i, array) => array.indexOf(elmt) === i);

export const CollaboratorsArrayParse = (value: any[]) => {
  if (!value) return [];

  return value.map((elt) => {
    const user = elt.user ?? elt;

    // if already parsed
    if (user.full_name) return elt;

    return { '@id': user.idIri, full_name: `${user.first_name} ${user.last_name}` };
  });
};

export const dateFormat = (date: string) => {
  return !date ? '' : new Intl.DateTimeFormat('fr-FR').format(new Date(date));
};

export const euroFormat = (value: number, decimal?: boolean): string => {
  return !value
    ? '-'
    : new Intl.NumberFormat('fr-FR', {
        style: decimal ? 'decimal' : 'currency',
        currency: 'EUR',
      }).format(value);
};

interface ISpaceNumberFormatProps {
  value: number;
  decimal?: boolean;
  withEuro?: boolean;
  maximumFractionDigits?: number;
}
export const spaceNumberFormat = ({
  value,
  decimal = false,
  withEuro = false,
  maximumFractionDigits = 0,
}: ISpaceNumberFormatProps): string => {
  return new Intl.NumberFormat('fr-FR', {
    style: decimal ? 'decimal' : 'currency',
    currency: withEuro ? 'EUR' : undefined,
    maximumFractionDigits,
  }).format(value);
};

export function spaceNumberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export const twoNumberDigitDisplay = (data: any) =>
  data.toLocaleString('fr-FR', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

export const getImgSrcByServer = (data: string | null) => {
  if (!data) {
    return '/images/logo-prospecimmo.svg';
  }
  return `https://img.kadastre.fr/index.old.php?url=${Buffer.from(data).toString(
    'base64'
  )}`;
};
