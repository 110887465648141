import * as yup from 'yup';

import { CITY, PROPERTY_TYPOLOGY, TITLE } from '../../utils/validation';

export const initialCreateValues = {
  name: '',
  collaborators: [],
  propertyTypology: null,
  city: null,
};

export const schema = yup.object({
  name: yup.string().required(TITLE.required),
  propertyTypology: yup
    .object()
    .required(PROPERTY_TYPOLOGY.required)
    .typeError(PROPERTY_TYPOLOGY.required),
  city: yup.object().required(CITY.required).typeError(CITY.type),
});
