import PropTypes from 'prop-types';
import { memo } from 'react';

import { formatString } from '../../utils/tools';
import './styles/AdBasicDetails.scss';

import { ReactComponent as PhoneViolet } from '../../images/phone-violet.svg';
import { ReactComponent as PictoEuro } from '../../images/picto-euro.svg';
import { ReactComponent as PictoPlace } from '../../images/picto-place.svg';
import { ReactComponent as PictoSurface } from '../../images/picto-surface.svg';
import { ReactComponent as PictoType } from '../../images/picto-type.svg';
import { ReactComponent as PictoWeb } from '../../images/picto-web.svg';

const AdBasicDetails = ({ data }) => {
  const handleDeadUrl = (e) => {
    e.target.src = '/images/logo-prospecimmo.svg';
  };
  return (
    <article className="ad-basic-details">
      <img
        src={data.picture ?? '/images/logo-prospecimmo.svg'}
        onError={handleDeadUrl}
        alt={`${data.propertyTypology.name} F${data.totalRoom}`}
        className="ad-basic-details-view"
      />
      <div className="ad-basic-details-description">
        <ul className="ad-basic-details-description-elt">
          <li className="ad-basic-details-description-elt-item">
            <h3>Description du bien</h3>
            <p>{data.description}</p>
          </li>
          {(data.sellerName || data.sellerPhone) && (
            <li className="ad-basic-details-description-elt-item">
              <h3>Vendeur</h3>
              <div className="ad-basic-details-seller">
                <PhoneViolet />
                <div>
                  <span>
                    {data.sellerName ?? 'Nom du vendeur non indiqué non indiqué'}
                  </span>
                  <span>{data.sellerPhone ?? 'Téléphone non indiqué'}</span>
                </div>
              </div>
            </li>
          )}
        </ul>
        <ul className="ad-basic-details-description-data">
          <li className="ad-basic-details-description-data-item">
            <PictoPlace />
            <span>
              <strong>Lieux : </strong>
              {`${formatString(data.city.name)} (${data.city.postalCode})`}
            </span>
          </li>
          <li className="ad-basic-details-description-data-item">
            <PictoType />
            <span>
              <strong>Type : </strong>
              {`${data.propertyTypology.name}`}
            </span>
          </li>
          <li className="ad-basic-details-description-data-item">
            <PictoSurface />
            <span>
              <strong>Surface du bien : </strong>
              {`${data.builtArea} m²`}
            </span>
          </li>
          <li className="ad-basic-details-description-data-item">
            <PictoSurface />
            <span>
              <strong>Surface du terrain : </strong>
              {`${data.landArea} m²`}
            </span>
          </li>
          <li className="ad-basic-details-description-data-item">
            <PictoEuro />
            <span>
              <strong>Prix au m² : </strong>
              {`${data.sqmPrice} €`}
            </span>
          </li>
          <li className="ad-basic-details-description-data-item">
            <PictoWeb />
            <span>
              <strong>Origine : </strong>
              <a
                href={data.url}
                target="_blank"
                title={data.source?.label ?? 'Indéfini'}
                className="ad-basic-details-link"
                rel="noreferrer"
              >
                {data.source?.label ?? 'Indéfini'}
              </a>
            </span>
          </li>
        </ul>
      </div>
    </article>
  );
};

AdBasicDetails.propTypes = {
  data: PropTypes.object.isRequired,
};

export default memo(AdBasicDetails);
