import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import useAuth from '../App/hooks/useAuth';

import Badge from '../components/badge';

import UserAccountTab from '../components/Modals/UserAccountTabs';
import { modalsActions } from '../store/modals/actions';
import { getModalState } from '../store/modals/reducer';

import {
  BadgeBackgroundColorEnum,
  BadgeTextColorEnum,
} from '../components/badge/enums';
import { ReactComponent as DownArrowGrey } from '../images/down-arrow-grey.svg';
import { ReactComponent as Hamburger } from '../images/hamburger.svg';
import { ReactComponent as LogoDark } from '../images/logo-dark.svg';
import { ReactComponent as MapMarker } from '../images/map-marker.svg';
import NotificationBell from './header/NotificationBell';
import './styles/Header.scss';

function Header() {
  const { userFullNameWithInitial, userInitials, role, companyName } = useAuth();

  const { profileMenu } = useSelector(getModalState);

  const dispatch = useDispatch();

  const onVisibleMenuClick = () => {
    profileMenu
      ? dispatch(modalsActions.profileMenu.close())
      : dispatch(modalsActions.profileMenu.open());
  };

  return (
    <>
      <header className="header">
        <Link to="/dashboard">
          <LogoDark className="header-logo" />
        </Link>

        <div className="header-elts">
          <h2 className="header-title">
            <MapMarker className="header-map" />
            {companyName}
          </h2>
          <NotificationBell />
          <button
            type="button"
            className="header-mobile-icon"
            onClick={() => dispatch(modalsActions.mobileNav.open())}
          >
            <Hamburger />
          </button>
          {
            <>
              <div className="header-user">
                <span className="header-user-elt">{userFullNameWithInitial}</span>
                <Badge
                  badgeDynamicContent={role}
                  bgColor={BadgeBackgroundColorEnum.GREY}
                  textColor={BadgeTextColorEnum.DARK_GREY}
                  isMini
                />
              </div>

              <div>
                <div className="header-profile">
                  <div className="header-profile-logo">{userInitials}</div>
                  <div
                    onClick={onVisibleMenuClick}
                    className="header-profile-open-menu"
                  >
                    <DownArrowGrey />
                  </div>
                </div>
              </div>
            </>
          }
        </div>
      </header>
      <div
        className={
          profileMenu
            ? 'header-menu header-menu-open'
            : 'header-menu header-menu-close'
        }
      >
        <UserAccountTab />
      </div>
    </>
  );
}

export default Header;
