import { isEmpty } from 'lodash';

export const objectKeyGenericMaping = (data: any) => {
  return {
    base: data,
    apiKeys: Object.keys(data),
    storeKeys: Object.values(data),
  };
};

export const emptyKeyRemove = (data: any) => {
  const tempData: any = {};

  for (const [key, value] of Object.entries(data)) {
    if (value !== '' && value !== null) {
      tempData[key] = value;
    }
  }

  return tempData;
};

function baseChangeKey<T>(data: any, base: IMapping, type: string): T {
  const tempData: any = {};

  for (const [key, value] of Object.entries(base)) {
    if (type === 'api') {
      if (key in data) {
        tempData[value] = data[key];
      }
    } else {
      if (value in data) {
        tempData[key] = data[value];
      }
    }
  }

  return tempData;
}

export function mapObjectParser<T>(tab: any[], func: (t: any) => T): T[] {
  try {
    return isEmpty(tab) ? [] : tab.map((t) => func(t));
  } catch (error) {
    return [];
  }
}

export function changeStoreKeyForApiKey<T>(data: any, base: IMapping) {
  return baseChangeKey<T>(data, base, 'store');
}

export function changeApiKeyForStoreKey<T>(data: any, base: IMapping): T {
  return baseChangeKey<T>(data, base, 'api');
}
