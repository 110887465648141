import SelectInput from '../../../../components/Forms/SelectInput';
import { nbDisplay, sortValues } from '../../constants';

interface IAdCardDisplayParamsProps {
  onDisplayChange: (datas: AdPaginationDatas) => void;
  displayDatas: AdPaginationDatas;
  extendedDisplayParams: React.ReactNode | null;
}

function AdCardDisplayParams({
  onDisplayChange,
  displayDatas,
  extendedDisplayParams,
}: IAdCardDisplayParamsProps) {
  const handleDisplayChange = (type: 'sort' | 'display', value: string | number) => {
    switch (type) {
      case 'sort':
        onDisplayChange({ ...displayDatas, sort: value as string });
        break;
      case 'display':
        onDisplayChange({ ...displayDatas, itemsPerPage: value as number });
        break;
      default:
        break;
    }
  };

  return (
    <div className="piecework-options">
      <SelectInput
        name="sort"
        value={displayDatas.sort}
        label="Trier par"
        nameKey="text"
        handleChange={(e) => handleDisplayChange('sort', e.target.value)}
        items={sortValues}
        colorDisplay="reverse"
      />

      <SelectInput
        name="display"
        value={displayDatas.itemsPerPage}
        label="Affichage par page"
        handleChange={(e) => handleDisplayChange('display', e.target.value)}
        items={nbDisplay}
        colorDisplay="reverse"
      />
      {extendedDisplayParams}
    </div>
  );
}

export default AdCardDisplayParams;
