import { useLocation } from 'react-router';
import AdDetails from '../features/ad/components/adDetails/AdDetails';

import './styles/adBlankPage.scss';

const AdBlankPage = () => {
  const { pathname } = useLocation();
  return (
    <div className="body-content">
      <AdDetails adId={pathname.split('/')[2]} />
    </div>
  );
};

export default AdBlankPage;
