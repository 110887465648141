import { isEmpty } from 'lodash';
import getIdFromIdIri from '../../shared/parsers/getIdFromIdIri';
import {
  nestedCollaboratorsApiToStore,
  nestedCollaboratorsFormToApi,
} from '../../store/collaborators/parser';

import { propertyTypologiesApiToStoreParser } from '../../store/propertyTypologies/parser';
import { citiesApiToStoreParser } from '../../utils/cityParser';

const checkZeroValue = (value: number | null) => (value === 0 ? null : value);
// ***********************
// ** FROM API
// ***********************

// common parser
export const activeResearchParser = (data: any): ActiveResearch => {
  const owner: ActiveResearchOwner = {
    idIri: data.owner['@id'],
    firstName: data.owner.first_name,
    lastName: data.owner.last_name,
  };
  const propertyTypologies: PropertyTypologies = isEmpty(data.property_typologies)
    ? [{ idIri: '', name: 'Tous' }]
    : propertyTypologiesApiToStoreParser(data.property_typologies);

  const parsed: ActiveResearch = {
    idIri: data['@id'],
    id: getIdFromIdIri(data['@id']),
    cities: citiesApiToStoreParser(data.cities),
    cityIdRadiusOrigin: data.city_id_radius_origin,
    createdAt: data.created_at,
    updatedAt: data.updated_at,
    dpe: { min: data.energy_rank_min, max: data.energy_range_max },
    ges: { min: data.energy_rank_min, max: data.energy_range_max },
    isAlertEnabled: data.is_alert_enabled,
    name: data.name,
    isExclusiveOnly: data.is_exclusivity_only,
    hasCityRadius: data.has_city_radius,
    owner,
    radius: data.radius,
    propertyTypologies,
    totalAds: 0,
    totalNewAds: 0,
  };

  return parsed;
};
// for cards
export const activeResearchesApiToStoreParser = (datas: any[]): ActiveResearches => {
  const parsedData = datas.map((m) => activeResearchParser(m));

  return parsedData;
};
export const activeResearchDetailApiToStoreParser = (
  data: any
): ActiveResearchDetail => {
  const base = activeResearchParser(data);

  const collaborators = !isEmpty(data.collaborators)
    ? nestedCollaboratorsApiToStore(data.collaborators)
    : [];

  const sellerTypes =
    isEmpty(data.seller_types) ||
    (data.seller_types && data.seller_types[0] === null)
      ? [0]
      : data.seller_types;

  const parsedData: ActiveResearchDetail = {
    ...base,
    collaborators,
    limitFromDate: data.limit_from_date,
    includedKeywords: data.included_keywords_description,
    includedKeywordsOperator: data.included_keywords_description_operator,
    includedSellerName: data.included_keywords_seller_name,
    includedSellerNameOperator: data.included_keywords_seller_name_operator,
    excludedKeywords: data.excluded_keywords_description,
    excludedKeywordsOperator: data.excluded_keywords_description_operator,
    excludedSellerName: data.excluded_keywords_seller_name,
    excludedSellerNameOperator: data.excluded_keywords_seller_name_operator,
    isGoodDealsOnly: data.is_good_deals_only,
    sellerTypes,
    dpe: { max: data.energy_rank_max, min: data.energy_rank_min },
    ges: { max: data.ghg_rank_max, min: data.ghg_rank_min },
    builtArea: {
      max: checkZeroValue(data.built_area_max),
      min: checkZeroValue(data.built_area_min),
    },
    landArea: {
      max: checkZeroValue(data.land_area_max),
      min: checkZeroValue(data.land_area_min),
    },
    price: {
      max: checkZeroValue(data.price_max),
      min: checkZeroValue(data.price_min),
    },
    room: {
      max: checkZeroValue(data.room_max),
      min: checkZeroValue(data.room_min),
    },
    sqmPrice: {
      max: checkZeroValue(data.sqm_price_max),
      min: checkZeroValue(data.sqm_price_min),
    },
  };

  if (parsedData.cityIdRadiusOrigin) {
    parsedData.cities = parsedData.cities.filter(
      (f) => f.id === parsedData.cityIdRadiusOrigin
    );
  }

  return parsedData;
};

// ***********************
// ** FROM FORM
// ***********************
export const activeResearchFormToApi = (data: ActiveResearchFormValues) => {
  const collaborators = !isEmpty(data.collaborators)
    ? nestedCollaboratorsFormToApi(data.collaborators)
    : [];

  const propertyTypologies = data.propertyTypologies
    ? isEmpty(data.propertyTypologies) ||
      (data.propertyTypologies.length === 1 &&
        data.propertyTypologies[0].idIri === '')
      ? []
      : data.propertyTypologies.map((elt) => elt.idIri)
    : null;

  const owner = data.owner
    ? typeof data.owner === 'string'
      ? data.owner
      : (data.owner as ActiveResearchOwner).idIri
    : null;

  const parsedData: any = {
    built_area_max: data.builtArea.max ? checkZeroValue(data.builtArea.max) : null,
    built_area_min: data.builtArea.min ? checkZeroValue(data.builtArea.min) : null,
    cities: data.cities ? data.cities.map((c) => c.idIri) : null,
    city_id_radius_origin: data.cityIdRadiusOrigin,
    collaborators,
    energy_rank_max: data.dpe.max,
    energy_rank_min: data.dpe.min,
    excluded_keywords_description: data.excludedKeywords,
    excluded_keywords_description_operator: data.excludedKeywordsOperator,
    excluded_keywords_seller_name: data.excludedSellerName,
    excluded_keywords_seller_name_operator: data.excludedSellerNameOperator,
    ghg_rank_max: data.ges.max,
    ghg_rank_min: data.ges.min,
    has_city_radius: data.hasCityRadius,
    included_keywords_description: data.includedKeywords,
    included_keywords_description_operator: data.includedKeywordsOperator,
    included_keywords_seller_name: data.includedSellerName,
    included_keywords_seller_name_operator: data.includedSellerNameOperator,
    is_alert_enabled: data.isAlertEnabled,
    is_exclusive_only: data.isExclusiveOnly,
    is_good_deals_only: data.isGoodDealsOnly,
    land_area_max: data.landArea.max ? checkZeroValue(data.landArea.max) : null,
    land_area_min: data.landArea.min ? checkZeroValue(data.landArea.min) : null,
    limit_from_date: data.limitFromDate,
    name: data.name,
    owner,
    price_max: data.price.max ? checkZeroValue(data.price.max) : null,
    price_min: data.price.min ? checkZeroValue(data.price.min) : null,
    property_typologies: propertyTypologies,
    radius: data.radius,
    room_max: data.room.max,
    room_min: data.room.min,
    seller_types: data.sellerTypes,
    sqm_price_max: checkZeroValue(data.sqmPrice.max),
    sqm_price_min: checkZeroValue(data.sqmPrice.min),
  };

  return parsedData;
};
