import useMarketStudies from '../../App/hooks/useMarketStudies';

import { memo } from 'react';
import { SourceTypeEnum } from '../../App/contexts/marketStudyContext';
import { GoodTypeEnum } from '../../pages/MarketStudiesResultPage';
import BarGraph from '../Graphs/BarGraph';
import {
  averagePriceEvolutionAreaLabels,
  averagePriceEvolutionRoomLabels,
} from './constant';

interface IGoodGraphProps {
  type: GoodTypeEnum;
  roomGround: string;
  valueType: SourceTypeEnum;
  children: React.ReactNode;
  id: string;
}

const GoodGraph = ({
  type,
  roomGround,
  valueType,
  children,
  id,
}: IGoodGraphProps) => {
  const { statsForAvgGraph } = useMarketStudies(null, id);
  return (
    <>
      <h4 className="market-studies-result-section-title">{children}</h4>
      <div className="market-studies-result-charts-elt">
        <BarGraph
          valueType={valueType as any}
          labels={
            type === GoodTypeEnum.CONSTRUCTION
              ? averagePriceEvolutionRoomLabels
              : averagePriceEvolutionAreaLabels
          }
          datasets={{
            label:
              valueType === SourceTypeEnum.AVERAGE
                ? 'Prix moyen'
                : 'Prix moyen / m²',
            data: statsForAvgGraph(type, roomGround, valueType),
          }}
        />
      </div>
    </>
  );
};

export default memo(GoodGraph);
