import * as yup from 'yup';
import {
  EMAIL,
  first_name,
  last_name,
  PHONE_NUMBER,
} from '../../../utils/validation';

export const createSchema = yup.object({
  lastName: yup.string().required(last_name.required),
  firstName: yup.string().required(first_name.required),
  email: yup.string().email(EMAIL.type).required(EMAIL.required),
  phoneNumber: yup.number().typeError(PHONE_NUMBER.type),
});

export const updateSchema = yup.object({
  lastName: yup.string().required(last_name.required),
  firstName: yup.string().required(first_name.required),
  email: yup.string().email(EMAIL.type).required(EMAIL.required),
  phoneNumber: yup.number().typeError(PHONE_NUMBER.type),
});

export const initForm = (selectedCollaborator) => {
  return {
    lastName: selectedCollaborator.lastName ?? '',
    firstName: selectedCollaborator.firstName ?? '',
    email: selectedCollaborator.email ?? '',
    phoneNumber: selectedCollaborator.phone_number ?? '',
  };
};
