import { nanoid } from '@reduxjs/toolkit';
import useMarketStudies from '../../App/hooks/useMarketStudies';
import { GoodTypeEnum } from '../../pages/MarketStudiesResultPage';
import { fieldAreaDisplay } from './constant';
import GoodStatRow from './GoodStatRow';

interface IGoodStatProps {
  type: GoodTypeEnum;
  partialLabel: string;
  id: string;
}

function GoodStat({ type, partialLabel, id }: IGoodStatProps) {
  const { statsPerQueries, selectedMarketStudy } = useMarketStudies(id, id);

  const partialLabelToInt = parseInt(partialLabel, 10);

  return (
    <>
      <h4 className="market-studies-result-section-title">{`Type "${
        selectedMarketStudy?.propertyTypologies
          ? selectedMarketStudy?.propertyTypologies.name.toLowerCase()
          : ''
      } ${
        type === GoodTypeEnum.CONSTRUCTION
          ? 'F' + partialLabel
          : fieldAreaDisplay(partialLabel)
      }"`}</h4>

      {type === GoodTypeEnum.CONSTRUCTION && (
        <>
          <div>
            <GoodStatRow
              key={nanoid()}
              data={statsPerQueries(partialLabelToInt, 'old_property', 'sale')}
            />
          </div>
          <div>
            <GoodStatRow
              key={nanoid()}
              data={statsPerQueries(partialLabelToInt, 'old_property', 'sold')}
            />
          </div>
          <div>
            <GoodStatRow
              key={nanoid()}
              data={statsPerQueries(partialLabelToInt, 'new_property', 'sale')}
            />
          </div>
          <div>
            <GoodStatRow
              key={nanoid()}
              data={statsPerQueries(partialLabelToInt, 'new_property', 'sold')}
            />
          </div>
        </>
      )}

      {type === GoodTypeEnum.TERRAIN && (
        <>
          <div>
            <GoodStatRow
              key={nanoid()}
              data={statsPerQueries(null, partialLabel, 'sale')}
            />
          </div>
          <div>
            <GoodStatRow
              key={nanoid()}
              data={statsPerQueries(null, partialLabel, 'sold')}
            />
          </div>
        </>
      )}
    </>
  );
}

export default GoodStat;
