import { useFormik } from 'formik';
import { forwardRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useAdStudies from '../../App/hooks/useAdStudies';

import TextInput from '../Forms/TextInput';
import ValidationButton from './customComponants/ValidationButton';

import { isEmpty } from 'lodash';
import { adStudiesActions } from '../../store/adStudies/actions';
import { pluInitialValues, pluSchema } from './form';

function PLUForm(props, ref) {
  const {
    activeAdStudy: { plu, idIri },
  } = useAdStudies({});

  const formik = useFormik({
    initialValues: isEmpty(plu) ? pluInitialValues : plu,
    validationSchema: pluSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(
        adStudiesActions.updateAdStudy.request({
          body: values,
          type: 'plu',
          idIri,
        })
      );
    },
  });

  const dispatch = useDispatch();

  const { values, touched, errors, dirty } = formik;
  useEffect(() => {
    dispatch(adStudiesActions.formTouchStates.set({ PLUForm: dirty }));
  }, [dirty]);

  return (
    <form className="my-folders-ad-creation-content-main-group-form" noValidate>
      <h4>PLU</h4>
      <div className="my-folders-ad-creation-content-main-group-form-row">
        <TextInput
          label="PLU : Zone"
          name="pluZone"
          value={values.pluZone}
          handleChange={formik.handleChange}
          error={touched.pluZone && Boolean(errors.pluZone)}
          helperText={touched.pluZone && errors.pluZone}
        />
        <TextInput
          label="PLU : COS"
          name="pluCos"
          value={values.pluCos}
          // type="number"
          handleChange={formik.handleChange}
          error={touched.pluCos && Boolean(errors.pluCos)}
          helperText={touched.pluCos && errors.pluCos}
        />
      </div>
      <div className="my-folders-ad-creation-content-main-group-form-row">
        <TextInput
          label="PLU : CES"
          name="pluCes"
          value={values.pluCes}
          // type="number"
          handleChange={formik.handleChange}
          error={touched.pluCes && Boolean(errors.pluCes)}
          helperText={touched.pluCes && errors.pluCes}
        />
        <TextInput
          label="PLU : hauteur max (cms)"
          name="pluHightMax"
          value={values.pluHightMax}
          // type="number"
          handleChange={formik.handleChange}
          error={touched.pluHightMax && Boolean(errors.pluHightMax)}
          helperText={touched.pluHightMax && errors.pluHightMax}
        />
      </div>
      <div className="my-folders-ad-creation-content-main-group-form-row">
        <TextInput
          label="PLU : quota social"
          name="pluSocialQuota"
          value={values.pluSocialQuota}
          // type="number"
          handleChange={formik.handleChange}
          error={touched.pluSocialQuota && Boolean(errors.pluSocialQuota)}
          helperText={touched.pluSocialQuota && errors.pluSocialQuota}
        />
        <TextInput
          label="PLU : parking"
          name="pluParking"
          value={values.pluParking}
          // type="number"
          handleChange={formik.handleChange}
          error={touched.pluParking && Boolean(errors.pluParking)}
          helperText={touched.pluParking && errors.pluParking}
        />
      </div>
      <div className="my-folders-ad-creation-content-main-group-form-row">
        <TextInput
          label="Parcelle"
          name="parcel"
          value={values.parcel}
          handleChange={formik.handleChange}
          error={touched.parcel && Boolean(errors.parcel)}
          helperText={touched.parcel && errors.parcel}
        />
        <TextInput
          label="Surface plancher (CES)"
          name="areaFloor"
          value={values.areaFloor}
          // type="number"
          handleChange={formik.handleChange}
          error={touched.areaFloor && Boolean(errors.areaFloor)}
          helperText={touched.areaFloor && errors.areaFloor}
        />
      </div>
      <ValidationButton ref={ref} onClick={formik.handleSubmit} />
    </form>
  );
}

export default forwardRef(PLUForm);
