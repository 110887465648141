import { call, put, select, take } from '@redux-saga/core/effects';
import {
  notifSignupContent,
  notifSignupTitle,
} from '../../components/SignPage/constants';
import { fetchDatas } from '../../services/axiosInstanceService';
import { NotificationStatus } from '../../utils/enums';
import { appManagerActions } from '../appManager/actions';
import { authActions } from '../auth/actions';
import { getAuthState } from '../auth/reducer';
import { REQUEST } from '../common/actions';
import onErrorLogout from '../common/sagaCommon';
import { loaderActions } from '../loader/actions';
import { subscriptionActions } from '../subscription/actions';
import { subscriptionApiToStore } from '../subscription/parser';
import {
  accountActions,
  FETCH_COMPANY,
  FETCH_COMPANY_STATS,
  REGISTER,
  UPDATE_COMPANY,
} from './actions';
import { getProspec, updateCompany } from './api';
import { companyStatsMapApiToStore, registerFormToApi } from './parser';

// ********************************
// ** REGISTER
// ********************************
export function* handleRegister() {
  try {
    const { payload } = yield take(REGISTER[REQUEST]);
    const parsedBody = registerFormToApi(payload);

    const response = yield call(getProspec, {
      endpoint: '/prospects',
      body: parsedBody,
    });

    if (response) {
      yield put(
        appManagerActions.actionNotificationDisplay.visible({
          title: notifSignupTitle,
          content: notifSignupContent,
          status: NotificationStatus.SUCCESS,
        })
      );
    }
  } catch (error) {
    yield put(authActions.register.failure(error));
  }
}
// ********************************
// ** COMPANY
// ********************************
export function* handleFetchCompany() {
  while (true) {
    try {
      yield take(FETCH_COMPANY[REQUEST]);

      const { companyId } = yield select(getAuthState);
      const response = yield call(fetchDatas, `/companies/${companyId}`);

      if (response) {
        const subscription = subscriptionApiToStore(response.subscription);
        const users = { ...response.users };

        delete response.users;
        delete response.subscription;

        yield put(accountActions.fetchCompany.success({ response, users }));
        yield put(subscriptionActions.fetchSubscription.success(subscription));
      }
    } catch (error) {
      yield onErrorLogout(error);
      yield put(accountActions.fetchCompany.failure(error));
    }
  }
}

export function* handleFetchCompanyStats() {
  while (true) {
    try {
      const { payload } = yield take(FETCH_COMPANY_STATS[REQUEST]);
      yield put(loaderActions.loaderDisplay.visible());
      const { companyId } = yield select(getAuthState);

      const response = yield call(
        fetchDatas,
        `/companies/${companyId}/stats`,
        payload
      );

      if (response) {
        const parsedResponse = companyStatsMapApiToStore(response['hydra:member']);

        yield put(accountActions.fetchCompanyStats.success(parsedResponse));
        yield put(loaderActions.loaderDisplay.hidden());
      }
    } catch (error) {
      yield put(loaderActions.loaderDisplay.hidden());
      yield onErrorLogout(error);
      yield put(accountActions.fetchCompanyStats.failure(error));
    }
  }
}

export function* handleUpdateCompany() {
  while (true) {
    try {
      const { payload } = yield take(UPDATE_COMPANY[REQUEST]);

      yield put(loaderActions.loaderDisplay.visible());
      const response = yield call(updateCompany, payload);
      if (response) {
        yield put(accountActions.updateCompany.success(response));
        yield put(
          appManagerActions.actionNotificationDisplay.visible({
            title: 'Coordonnées',
            content: `Les coordonnées ont été modifiées avec succès`,
            status: NotificationStatus.SUCCESS,
          })
        );
        yield put(loaderActions.loaderDisplay.hidden());
      }
    } catch (error) {
      yield onErrorLogout(error);
      yield put(loaderActions.loaderDisplay.hidden());
      yield put(
        appManagerActions.actionNotificationDisplay.visible({
          title: 'Coordonnées',
          content: `Les coordonnées n'ont pas été enregistrées`,
          status: NotificationStatus.FAILURE,
        })
      );
      yield put(accountActions.updateCompany.failure(error));
    }
  }
}
