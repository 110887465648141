import { LOGOUT } from '../auth/actions';
import { RESET, SET, SUCCESS } from '../common/actions';
import {
  CREATE_COLLABORATOR,
  ENABLE_COLLABORATOR,
  FETCH_COLLABORATORS,
  SELECTED_COLLABORATOR,
  UPDATE_COLLABORATOR,
} from './actions';

const initialState = {
  collaborators: [],
  selectedCollaboratorId: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_COLLABORATORS[SUCCESS]:
      return { ...state, collaborators: payload };
    case CREATE_COLLABORATOR[SUCCESS]:
      return { ...state, collaborators: state.collaborators.concat(payload) };
    case ENABLE_COLLABORATOR[SUCCESS]:
    case UPDATE_COLLABORATOR[SUCCESS]:
      return {
        ...state,
        collaborators: state.collaborators.map((elmt) =>
          elmt.idIri === payload.idIri ? payload : elmt
        ),
      };
    case SELECTED_COLLABORATOR[SET]:
      return { ...state, selectedCollaboratorId: payload };
    case SELECTED_COLLABORATOR[RESET]:
      return { ...state, selectedCollaboratorId: null };
    case LOGOUT[SUCCESS]:
      return { ...initialState };
    default:
      return state;
  }
};

export const getCollaboratorsState = (store) => store.collaborators;
