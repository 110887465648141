import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory, useLocation, useParams } from 'react-router-dom';
import getIdFromIdIri from '../../shared/parsers/getIdFromIdIri';
import { adStudiesActions } from '../../store/adStudies/actions';
import { modalsActions } from '../../store/modals/actions';
import { getActiveResearchState } from '../activeResearch/activeResearchSlice';
import usePiecework from '../piecework/usePiecework';
import { adActions, getAdState } from './adSlice';
import fetchAd from './services/fetchAd';
import fetchAdsThunk from './services/thunks/fetchAdsThunk';
import fetchAdThunk from './services/thunks/fetchAdThunk';

export type PageType =
  | 'recherche-active-resultat'
  | 'pige-classique'
  | 'details-dossier';
interface ILoadAdsProps {
  paginationProp: AdPaginationDatas;
  force?: boolean;
}
interface IUseAds {
  adId?: string;
  noLoadAds?: boolean;
}
function useAds({ adId, noLoadAds }: IUseAds) {
  const { folderId }: { folderId: string } = useParams();
  const { pathname } = useLocation();
  const history = useHistory();

  const [similaryAd, setSimilaryAd] = useState<Ad | null>(null);
  const { marketResearchIdIri } = usePiecework({});
  const { currentActiveResearch } = useSelector(getActiveResearchState);
  const { ads, activeAd, selectedAds, adPlots, pagination } = useSelector(
    getAdState
  );
  const dispatch = useDispatch();

  // get ads source : piecework or ra result
  const baseUrl: PageType | null = (pathname?.split('/')?.[1] as PageType) ?? null;

  const loadActiveAd = (ad: Ad) => {
    if (!adId && pathname !== baseUrl) {
      dispatch(modalsActions.adDetails.open());
      dispatch(fetchAdThunk({ idIri: ad.idIri }));
    }
    if (baseUrl === 'details-dossier' && folderId && ad.adStudy?.idIri) {
      dispatch(adStudiesActions.activeAdStudy.set(ad));
      history.push(
        `/creation-annonce-dossier/${folderId}/${getIdFromIdIri(ad.adStudy.idIri)}`
      );
    }
  };
  const createStudyFromAd = () => dispatch(adStudiesActions.createAdStudies.modal());
  const deleteAds = () => dispatch(modalsActions.deleteAds.open());
  const loadAds = ({ paginationProp, force }: ILoadAdsProps) => {
    if (!isEqual(paginationProp, pagination) || force) {
      dispatch(
        fetchAdsThunk({
          page: paginationProp.page,
          itemsPerPage: paginationProp.itemsPerPage,
          sort: paginationProp.sort,
          pageType: baseUrl as PageType,
          totalAds: paginationProp.totalAds,
          folderId: folderId ? `folders/${folderId}` : null,
        })
      );
    }
  };
  const addItemToSelectedAd = (ad: Ad) => {
    dispatch(adActions.selectedAdsAdd(ad));
  };
  const handlePaginationChange = (pagination: AdPaginationDatas) =>
    loadAds({ paginationProp: pagination });

  useEffect(() => {
    if (adId && !similaryAd) {
      fetchAd({ idIri: `ads/${adId}` }).then(
        (res) => {
          setSimilaryAd(res);
        },
        (err) => {}
      );
    }
  }, [adId]);

  useEffect(() => {
    if (
      !ads.result &&
      !noLoadAds &&
      !adId &&
      ((baseUrl === 'recherche-active-resultat' && currentActiveResearch.result) ||
        (baseUrl === 'pige-classique' && marketResearchIdIri) ||
        baseUrl === 'details-dossier')
    ) {
      loadAds({ paginationProp: pagination, force: true });
    }
  }, [baseUrl, marketResearchIdIri, currentActiveResearch.result, ads.result]);

  return {
    ads,
    activeAd,
    loadActiveAd,
    selectedAds,
    adPlots,
    loadAds,
    adPagination: pagination,
    handlePaginationChange,
    createStudyFromAd,
    deleteAds,
    addItemToSelectedAd,
    pageOrigin: baseUrl,
    similaryAd,
  };
}

export default useAds;
